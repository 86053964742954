<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">İşlemler Raporu</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{path:'/panel/reports'}">Raprolar</router-link>
              </li>
              <li class="breadcrumb-item active">İşlemler Raporu</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">     
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 col-md-7 col-lg-8">
                    <div class="float-left">
                        <v-date-picker v-model="rangeDate" mode="date" is-range>
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="input-group input-group-sm">      
                                <input
                                  :value="inputValue.start"
                                  v-on="inputEvents.start"
                                  :disabled="isDataLoading"
                                  class="form-control form-control-sm" 
                                  style="width:80px"
                                />
                                <input
                                  :value="inputValue.end"
                                  v-on="inputEvents.end"
                                  :disabled="isDataLoading"
                                  class="form-control form-control-sm"
                                  style="width:80px"
                                />
                                <select class="form-control form-control-sm" v-model="selectedUser" :disabled="isDataLoading" style="width: 250px;">
                                  <option v-for="t in userList" :key="t" v-bind:value="t.value">{{t.label}}</option>
                                </select>
                                <span class="input-group-append">
                                  <button type="button" class="btn btn-sm btn-default btn-flat" @click="GetReport()" :disabled="isDataLoading">Listele</button>
                                </span>
                            </div>    
                          </template>
                        </v-date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{{ totalForm }}</h3>
                    <p>Toplam İşlem Adedi</p>
                  </div>
                  <a href="javascript:;" @click="SetReportFilter(1)" class="small-box-footer" style="z-index: auto;">Listele <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>

              <!-- <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{{totalStudent}}</h3>
                    <p>Öğrenci Olan</p>
                  </div>
                  <a href="javascript:;" @click="SetReportFilter(2)" class="small-box-footer" style="z-index: auto;">Listele <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div> -->

              <div class="col-lg-4 col-6">

                <div class="small-box bg-warning">
                  <div class="inner">
                    <h3>{{prepareForm}}</h3>
                    <p>İleri Tarihe Atılan</p>
                  </div>
                  <a href="javascript:;" @click="SetReportFilter(3)" class="small-box-footer" style="z-index: auto;">Listele <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>

              <div class="col-lg-4 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{{cancelForm}}</h3>
                    <p>İptal Edilen</p>
                  </div>
                  <a href="javascript:;" @click="SetReportFilter(4)" class="small-box-footer" style="z-index: auto;">Listele <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>
              </div>

            <div class="card">
              <div class="card-body table-responsive p-0 overflow-scroll" style="height:500px;">
                <table class="table">
                  <thead>
                  <tr>
                    <th class="text-center" style="width: 150px;">Tarih</th>
                    <th style="width: 200px;">Kullanıcı</th>
                    <th style="width: 250px;">Form Bilgileri</th>
                    <th>Açıklama</th>
                  </tr>
                  </thead>
                  <tbody v-if="reportFilter.length>0">
                    <tr v-for="m in reportFilter" :key="m">
                      <td class="text-center">{{ ReportViewDateFormat(m.transactionDate) }}</td>
                      <td>{{ m.saleName }} {{ m.saleSurname }}</td>
                      <td>
                        <div class="badge" :class="{'badge-danger':m.status==3,'badge-warning':m.status==2,'badge-success':m.status==5}">{{ConvertStatus(m.status)}}</div>
                        <div><a href="javascript:;" @click="OpenCloseMemberProfileModal(true,m.memberId)" class="h5">{{m.name }} {{m.surname }}</a></div>
                      </td>
                      <td><div><small><i>{{ m.constContent }}</i></small></div>{{ m.description }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="isDataLoading">
                    <tr>
                      <td colspan="7" class="text-center">Lütfen bekleyin.</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="text-center">Kayıt bulunamadı</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->

    <MemberProfileModal :Visible="memberProfileModalVisible" :MemberId="memberProfileModalMemberId" @CloseMemberProfileModal="OpenCloseMemberProfileModal($event,null)"></MemberProfileModal>
</template>

<script>
import {mapActions,mapGetters} from "vuex";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import {GetFormStatus} from "../../../helpers/enum-convert-helper";
import {DateFormat,CalculateDateTime} from "../../../helpers/date-convert-helper";
import MemberProfileModal from '../../../components/MemberProfile/MemberProfileModal.vue';
export default {
  name: "TransactionReport",
  components: {
    MemberProfileModal
  },
  data(){
    return{
      rangeDate:{
        start: new Date(),
        end: new Date()
      },
      isDataLoadingForUser:false,
      isDataLoading:false,
      userList:[],
      selectedUser:"00000000-0000-0000-0000-000000000000",
      reportList:[],
      reportFilter:[],
      memberProfileModalVisible:false,
      memberProfileModalMemberId:null,
    }
  },
  computed:{
    ...mapGetters(["IS_ADMIN","IS_OPERATOR"]),
    isAdmin(){
      return this.IS_ADMIN;
    },
    isOperator(){
      return this.IS_OPERATOR;
    },
    cancelForm:function(){
      let findData = this.reportList.filter(x=>x.status==3); 
      if(findData){
        const unique = [...new Set(findData.map(item => item.memberId))];
        return unique.length;
      }
      return 0;
    },
    prepareForm:function(){
      let findData = this.reportList.filter(x=>x.status==2); 
      if(findData){
        const unique = [...new Set(findData.map(item => item.memberId))];
        return unique.length;
      }
      return 0;
    },
    totalForm:function(){
      const unique = [...new Set(this.reportList.map(item => item.memberId))];
      return unique.length;
    },
  },
  watch:{
  },
  methods: {
    ...mapActions(["USER_ACTIVELIST","USERTRANSACTIONREPORT"]),
    
    ConvertStatus(value){
      return GetFormStatus(value);
    },
    OpenCloseMemberProfileModal(value,memberId){
      this.memberProfileModalVisible=value;
      this.memberProfileModalMemberId=memberId;
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    ReportDateFormat(value){
      return DateFormat(value,'yyyy-mm-dd');
    },
    ReportViewDateFormat(value){
      return DateFormat(CalculateDateTime(value),'dd.mm.yyyy hh:dd:ss');
    },
    SetReportFilter(value){
      let findData;
      switch (value) {
        case 1:
          findData = this.reportList; 
          break;
        case 3:
          findData = this.reportList.filter(x=>x.status==2); 
          break;
        case 4:
          findData = this.reportList.filter(x=>x.status==3); 
          break;
        default:
          break;
      }
      this.reportFilter = findData;
    },
    GetReport(){
      let mainThis = this;
      mainThis.isDataLoading = true;
      mainThis.reportList = [];
      mainThis.reportFilter = [];

      mainThis.USERTRANSACTIONREPORT({
        userId:mainThis.selectedUser,
        beginDate:mainThis.ReportDateFormat(mainThis.rangeDate.start),
        endDate:mainThis.ReportDateFormat(mainThis.rangeDate.end)
      })
      .then((res)=>{
          mainThis.reportList = res.data;
          mainThis.reportFilter = mainThis.reportList
          mainThis.isDataLoading = false;
        }).catch(error=>{
          mainThis.isDataLoading = false;
           if(this.IsShowErrorMessage(error)){
             this.$toast.error(error,{
                 position : 'top',
                 duration : 3000
             });
           }
        });
    },

    GetActiveUser(){
      let mainThis = this;
      mainThis.isDataLoading = true;
      mainThis.userList = [];
      if(this.isAdmin){
        mainThis.userList.push({label:"Hepsi",value:"00000000-0000-0000-0000-000000000000"});
      }
      mainThis.USER_ACTIVELIST()
      .then((res)=>{
        res.data.forEach(t => {
          let role = t.role!=null?' ('+t.role+')':'';
          if(this.isAdmin){
            mainThis.userList.push({label:t.name+' '+t.surname + role,value:t.id});
          }else if(this.isOperator && (t.id=='04F11467-B739-4D7A-AB80-C3626C772B05'.toLowerCase() || t.id=='82BE9A94-FDA9-40E8-A1CA-8B7FD7DE6AD3'.toLowerCase())){
            mainThis.userList.push({label:t.name+' '+t.surname + role,value:t.id});
          }
        });
        mainThis.isDataLoading = false;
      }).catch(error=>{
          mainThis.isDataLoading = false;
           if(this.IsShowErrorMessage(error)){
             this.$toast.error(error,{
                 position : 'top',
                 duration : 3000
             });
           }
        });
    },
  },
  mounted: function() {
    this.GetActiveUser();
  }
};
</script>
<style scoped>
</style>