import { createApp } from 'vue';
import Maska from 'maska';
import App from './App.vue';
import router from "./router";
import {store} from "./store";
import {createMetaManager} from 'vue-meta';
import VTooltipPlugin from 'v-tooltip';
import VueCryptojs from 'vue-cryptojs';
import VCalendar from 'v-calendar';
import excel from 'vue-excel-export'

import VueToast from 'vue-toast-notification';

window.$ = window.jQuery = require("jquery");

window.toastr = require('toastr');

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {dom, library} from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import {faLock, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faGooglePlus} from '@fortawesome/free-brands-svg-icons';

import 'vue-toast-notification/dist/theme-default.css';
//import 'vue-toast-notification/dist/theme-sugar.css'
import 'v-tooltip/dist/v-tooltip.css';

//import adminlte scripts
import "../node_modules/admin-lte/dist/js/adminlte.min.js"
import "../node_modules/admin-lte/plugins/select2/js/select2.full.min.js"
import "../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js"
import "../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js"
//import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.js"
//import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js"
//import "../node_modules/admin-lte/plugins/moment/moment.min.js"
import "../node_modules/admin-lte/plugins/jquery-knob/jquery.knob.min.js"
//import "../node_modules/admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js"
//import "../node_modules/admin-lte/plugins/jqvmap/jquery.vmap.min.js"
import "../node_modules/admin-lte/plugins/sparklines/sparkline.js"
//import "../node_modules/admin-lte/plugins/chart.js/Chart.min.js"
import "../node_modules/admin-lte/plugins/jquery/jquery.min.js"
import "../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js"
//import "../node_modules/admin-lte/plugins/inputmask/jquery.inputmask.min.js"
//import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
import "../node_modules/admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js"
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.js"

import "../node_modules/admin-lte/plugins/datatables/jquery.dataTables.min.js"
import "../node_modules/admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js"
import "../node_modules/admin-lte/plugins/datatables-responsive/js/dataTables.responsive.min.js"
import "../node_modules/admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4.min.js"
import "../node_modules/admin-lte/plugins/datatables-buttons/js/dataTables.buttons.min.js"
import "../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.bootstrap4.min.js"
import "../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.bootstrap4.min.js"
import "../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.bootstrap4.min.js"


//import adminlte styles
import '../node_modules/admin-lte/dist/css/adminlte.min.css'
import "../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css"
import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.css"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css"
import "../node_modules/admin-lte/plugins/jqvmap/jqvmap.min.css"
import "../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css"
import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css"
import "../node_modules/admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css"
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.css"

import "../node_modules/admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css"
import "../node_modules/admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css"
import "../node_modules/admin-lte/plugins/datatables-buttons/css/buttons.bootstrap4.min.css"


library.add(fas, fab, far, faLock, faEnvelope, faFacebook, faGooglePlus);
dom.watch();

createApp(App)
.use(router)
.use(store)
.use(createMetaManager)
.use(Maska)
.use(VueToast)
.use(VTooltipPlugin)
.use(VueCryptojs)
.use(VCalendar)
.use(excel)
.component("font-awesome-icon", FontAwesomeIcon)
.mount('#app');
