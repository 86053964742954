<template>
  <div style="min-height: 500px;" class="tab-pane fade p-2" :class="showTab">
    
  </div>
</template>
<style scoped>
  
</style>
<script>
//import {mapActions} from "vuex";
//import {DateFormat,CalculateDateTime} from "../../helpers/date-convert-helper";
export default {
  name: 'TabTrainingCalender',
  components: { 
    },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
    }
  },
  computed:{
    
  },
  methods:{
    //...mapActions(["MEMBER_GETMEMBERFORPROFILEMODAL"]),
    // CalculateDateTimeByOffset(value){
    //     return CalculateDateTime(value);
    // },
    GetInitData(){
    },
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData();
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  }
  
}
</script>