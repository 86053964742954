<template>
  <div v-html="paymetMessage" class="mt-5"></div>
</template>

<script>
export default {
  name: "Checkout",
  data(){
    return{
      status:this.$route.query.status,
      orderid:this.$route.query.orderid,
      authCode:this.$route.query.authCode,
      amount:this.$route.query.amount,
      packageId:this.$route.query.packageId,
      message:this.$route.query.message,
    }
  },
  computed:{
    paymetMessage(){
      let messageHtml = '';
      if(this.status==='ok'){
        messageHtml = '<center><i class="fas fa-check fa-4x text-success"></i><br />Ödeme işlemi başarıyla gerçekleşti.<br />(Ödem Kodu: '+this.authCode+')</center>';
      }else{
        messageHtml = '<center><i class="fas fa-times fa-4x text-danger"></i><br/>Ödeme işlemi sırasında bir hata oluştu.<br />('+this.message+')</center>';
      } 
      return messageHtml;
    }
  },
};
</script>