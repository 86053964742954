import Vuex from "vuex";
import login from "./login.module"
import user_module from "./user.module"
import userprofile_module from "./userprofile.module"
import member_module from "./member.module"
import memberprofile_module from "./memberprofile.module"
import training_module from "./training.module"
import payment_module from "./payment.module"
import teacher_module from "./teacher.module"
import notification_module from "./notification.module"
import task_module from "./task.module"
import reports_module from "./reports.module"
const store = new Vuex.Store({
    strict:true,
    modules : {
        login,
        user_module,
        userprofile_module,
        member_module,
        memberprofile_module,
        training_module,
        payment_module,
        teacher_module,
        notification_module,
        task_module,
        reports_module
    }
});

export {store};