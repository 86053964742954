import {service} from "../helpers/request"

function GetStudentsWithClassToday(){
    return service({
        url:'/Teacher/GetStudentsWithClassToday',
        method:'post',
        data:{}
    })
}

function GetActiveStudents(){
    return service({
        url:'/Teacher/GetActiveStudents',
        method:'post',
        data:{}
    })
}

function GetAvailableHours(){
    return service({
        url:'/Teacher/GetAvailableHours',
        method:'post',
        data:{}
    })
}

function GetLessonsForResult(trainingFileId){
    return service({
        url:'/Teacher/GetLessonsForResult?trainingFileId='+trainingFileId,
        method:'post',
        data:{}
    })
}

function SaveLessonsResult(model){
    return service({
        url:'/Teacher/SaveLessonResult',
        method:'post',
        data:{
            memberId:model.memberId,
            trainingFileId:model.trainingFileId,
            date:model.date,
            status:parseInt(model.status),
            note:model.note,
            incorrectList:model.incorrectList,
            listening : parseInt(model.listening),
            reading : parseInt(model.reading),
            speaking : parseInt(model.speaking),
            vocabulary : parseInt(model.vocabulary),
            grammar : parseInt(model.grammar),
            levelTestLessonResult : parseInt(model.levelTestLessonResult)
        }
    })
}

function SaveAvailableHours(model){
    return service({
        url:'/Teacher/SaveAvailableHours',
        method:'post',
        data : model
    })
}
export {GetStudentsWithClassToday,GetActiveStudents,GetLessonsForResult,SaveLessonsResult,GetAvailableHours,SaveAvailableHours};
