import CryptoJS from 'crypto-js';
import { AccessToken, ForgotPassword, RecoverPassword} from "../services/login.service";
import {SECRET_KEY} from "../settings";
const defaultUser = {
        token : '',
        nameSurname:'',
        refreshToken:'',
        role:'',
        setting:{
            language:'',
            timeOffset:''
        },
};

const login = {
    state:{
        user : defaultUser
    },
    getters:{
        TOKEN : state=>{
            return state.user.token;
        },
        REFRESHTOKEN:state=>{
            return state.user.refreshToken;
        },
        FULLNAME:state=>{
            return state.user.nameSurname;
        },
        PERMISSIONS:state=>{
            return state.user.permissions;
        },
        TIMEOFFSET:state=>{
            return state.user.setting.timeOffset;
        },
        LANGUAGE:state=>{
            return state.user.setting.language;
        },
        ROLE:(state)=>{
            return state.user.role;
        },
        IS_TEACHER:state=>{
            var a = state.user.role=='teacher';
            return a;
        },
        IS_ADMIN:state=>{
            var a = state.user.role=='manager';
            return a;
        },
        IS_OPERATOR:state=>{
            var a = state.user.role=='studentoperator';
            return a;
        },
        IS_SALES:state=>{
            var a = state.user.role=='sales';
            return a;
        },
        IS_SESSOIN:(state)=>{
            return (!state.user.token=='');
        }

    },
    mutations:{
        initialiseStore:(state)=>{
			if(localStorage.getItem('user')) {
				try {
                    let userData = localStorage.getItem('user');
                    let userDataDecrypt = CryptoJS.AES.decrypt(userData, SECRET_KEY).toString(CryptoJS.enc.Utf8);
                    state.user =  JSON.parse(userDataDecrypt);
                } catch {
                    state.user = defaultUser; 
                }
			}else{
                state.user = defaultUser;
            }
		},
        SET_USER : (state,user)=>{
            state.user = user;
        },
    },
    actions:{
        LOGIN : (context,model) =>{
            return new Promise((resolve,reject)=>{
                AccessToken(model.username,model.password)
                .then((res)=>{
                    try {
                        let userData = res.data;
                        context.commit("SET_USER",userData);
                        let userDataEncrypt = CryptoJS.AES.encrypt(JSON.stringify(userData), SECRET_KEY).toString();
                        window.localStorage.setItem('user',userDataEncrypt);
                        resolve();
                    } catch {
                        reject("Kullanıcı bilgisi alınamadı.");
                    }
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        LOGOUT : (context) =>{
            return new Promise((resolve,reject)=>{
                try {
                    context.commit("SET_USER",defaultUser);
                    window.localStorage.removeItem('user');
                    resolve();
                } catch {
                    reject("Kullanıcı çıkş yapılamadı");
                }
            })
        },
        FORGOT_PASSWORD : (context,model) =>{
            return new Promise((resolve,reject)=>{
                ForgotPassword(model.username)
                .then(()=>{
                    resolve();
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        RECOVER_PASSWORD : (context,model) =>{
            return new Promise((resolve,reject)=>{
                RecoverPassword(model.secureCode,model.password)
                .then(()=>{
                    resolve();
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        }
    }
}

export default login;