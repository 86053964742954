function TextConvert(text,parameters){
    try {
        let replacedText = text;
        if(parameters){
            replacedText = replacedText.replaceAll('%YEAR%',parameters.year);
            replacedText = replacedText.replaceAll('%NAME%',parameters.name);
            replacedText = replacedText.replaceAll('%SURNAME%',parameters.surname);
        }
        return replacedText;
    } catch{
        return text;
    }
}

function GetDefaultMailTemplate(){
    let replacedText = "<!DOCTYPE html><html><head><meta charset=\"utf-8\"></head><body style=\"font-family: Arial, Helvetica, sans-serif;\"><table width=\"700\" style=\"margin:0px auto 0px; border: 10px solid #5bb947;\"><tr><td style=\"height:300px; vertical-align: top; padding:35px 20px 0px;\">%BODY%</td></tr><tr><td style=\"text-align: center; line-height: 20px; padding-bottom: 20px; \"><img src=\"https://www.lengedu.com/mail/mail-default-logo.png\" /><br><a href=\"mailto:info@lengedu.com\" target=\"_blank\" style=\"color:#000; text-decoration: none;\">info@lengedu.com</a> | (0212) 515-31-39 | <a href=\"https://www.lengedu.com/?utm_source=mail&utm_content=flex-mail\" target=\"_blank\" style=\"color:#000; text-decoration: none;\">www.lengedu.com</a></td></tr></table></body></html>";
    try {
        replacedText = replacedText.replaceAll('%BODY%','<p>Merhaba %NAME% %SURNAME%</p>');
        return replacedText;
    } catch{
        return replacedText;
    }
}

export {TextConvert,GetDefaultMailTemplate}