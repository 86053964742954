//import {API_BASE_URL} from "../settings";
function CreateLink(fileName){
    try {
        if(!fileName){
            return null;
        }

        let bookUrl = process.env.VUE_APP_ROOT_API + 'Book/BookImage?fileName='+fileName;
        return bookUrl;

    } catch (error) {
        console.error('form not created!');
        return null;
    }
}
export {CreateLink}