import { createWebHistory, createRouter } from "vue-router";
import Login from "./views/Login.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import RecoverPassword from "./views/RecoverPassword.vue";
import Panel from "./views/Panel.vue";
import Home from "./views/pages/Home.vue";
import User from "./views/pages/user/User.vue";
import Teacher from "./views/pages/user/Teacher.vue";
import MemberForm from "./views/pages/member/MemberForm.vue";
import MemberStudent from "./views/pages/member/MemberStudent.vue";
import Ticket from "./views/pages/ticket/Ticket.vue";
import Checkout from "./views/pages/checkout/Checkout.vue";
import Student from "./views/pages/student/Student.vue";
import Task from "./views/pages/task/Task.vue";

import Reports from "./views/pages/reports/Index.vue";
import TeacherReport from "./views/pages/reports/TeacherReport.vue";
import TeacherWithStudent from "./views/pages/reports/TeacherWithStudent.vue";
import TransactionReport from "./views/pages/reports/TransactionReport.vue";
import PaymentReport from "./views/pages/reports/PaymentReport.vue";
import FormReport from "./views/pages/reports/FormReport.vue";

import TeacherPanel from "./views/TeacherPanel.vue";
import TPHome from "./views/pages/teacherpanel/Home.vue";
import TPStudents from "./views/pages/teacherpanel/Students.vue";
import TPAvailablehours from "./views/pages/teacherpanel/AvailableHours.vue";

import {store} from "./store/index";
// lazy-loaded

store.commit('initialiseStore');

const routes = [
  {
    path: "/",
    redirect: (store.getters.IS_TEACHER ? "/teacher/home" : "/panel/home"),
    component: Panel,
  },


  {
    path: "/login",
    component: Login,
    meta: {
      title: 'Lengedu İngilizce Eğitim Programı',
    },
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/recover-password/:q",
    component: RecoverPassword,
  },
  {
    path: "/panel",
    redirect:"/panel/home",
    component: Panel,
    children:[
      {
        path : "home",
        component:Home,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        },
      },
      {
        path : "user",
        component:User,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        },
      },
      {
        path : "teacher",
        component:Teacher,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        },
      },
      {
        path : "member-form",
        component:MemberForm,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        },
      },
      {
        path : "member-student",
        component:MemberStudent,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        },
      },
      {
        path : "student",
        component:Student,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        },
      },
      {
        path : "ticket",
        component:Ticket,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        },
      },
      {
        path : "task",
        component:Task,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        },
      },
      {
        path : "reports",
        component:Reports,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        }
      },
      {
        path : "teacher-report",
        component:TeacherReport,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        }
      },
      {
        path : "teacher-with-student",
        component:TeacherWithStudent,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        }
      },
      {
        path : "transaction-report",
        component:TransactionReport,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        }
      },
      {
        path : "form-report",
        component:FormReport,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        }
      },
      {
        path : "payment-report",
        component:PaymentReport,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        }
      },
    ]
  },
  {
    path : "/checkout",
    component:Checkout,
    meta: {
      title: 'Lengedu İngilizce Eğitim Programı',
    },
  },



  {
    path: "/teacher",
    redirect:"/teacher/home",
    component: TeacherPanel,
    children:[
      {
        path : "home",
        component:TPHome,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        },
      },
      {
        path : "students",
        component:TPStudents,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        },
      },
      {
        path : "availablehours",
        component:TPAvailablehours,
        meta: {
          title: 'Lengedu İngilizce Eğitim Programı',
        },
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to,from,next)=>{
  const publicPages = ["/login","/forgot-password","/recover-password/:q"];
  const isSession = store.getters.IS_SESSOIN;
  const isTeacher = store.getters.IS_TEACHER;
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }
  let path = to.matched[0].path;
  let isTeacherPage = path.substring(1,8)==='teacher';
  let isPanelPage = path.substring(1,6)==='panel';

  if(isSession){
    if(publicPages.includes(path)){
      if(isTeacher){
        next("/teacher/home");
      }else{
        next("/panel/home");
      }
    }else{
      if(isTeacher && isPanelPage){
        next("/teacher/home");
      }else if(!isTeacher && isTeacherPage){
        next("/panel/home");
      }else{
        next();
      }
    }
  }else{
    if(!publicPages.includes(path)){
      next("/login");
    }else{
      if(isTeacher && isPanelPage){
        next("/teacher/home");
      }else if(!isTeacher && isTeacherPage){
        next("/panel/home");
      }else{
        next();
      }
    }
  }
});

export default router;