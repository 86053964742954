import { CreateTask,GetTasks,TaskTransaction,GetReminderTasks } from "../services/task.service";

const task_module = {
    state:{},
    getters:{},
    mutations:{},
    actions:{
        CREATE_TASK : (context,model) =>{
            return new Promise((resolve,reject)=>{
                CreateTask(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        GET_TASKS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetTasks(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        GET_REMINDERTASKS : () =>{
            return new Promise((resolve,reject)=>{
                GetReminderTasks()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TASK_TRANSACTION : (context,model) =>{
            return new Promise((resolve,reject)=>{
                TaskTransaction(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
    }
}

export default task_module;