<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Görev / Hatırlatma Listesi</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item active">Görev / Hatırlatma Listesi</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">        
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 col-md-7 col-lg-8">
                    <div class="float-left" style="width:305px">
                        <v-date-picker v-model="rangeDate" mode="date" is-range>
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="input-group input-group-sm">      
                                <input
                                  :value="inputValue.start"
                                  v-on="inputEvents.start"
                                  :disabled="isDataLoading"
                                  class="form-control form-control-sm"
                                />
                                <input
                                  :value="inputValue.end"
                                  v-on="inputEvents.end"
                                  :disabled="isDataLoading"
                                  class="form-control form-control-sm"
                                />
                                <span class="input-group-append">
                                  <button type="button" class="btn btn-sm btn-default btn-flat" :disabled="isDataLoading" @click="GetTasks()">Listele</button>
                                </span>
                            </div>    
                          </template>
                        </v-date-picker>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-7 col-lg-4">
                    <div class="input-group input-group-sm"> 
                      <select class="form-control form-control-sm" v-model="category" :disabled="isDataLoading">
                        <option v-for="c in categoryListForFilter" :key="c" v-bind:value="c.value">{{c.label}}</option>
                      </select>
                      <span class="input-group-append">
                        <button type="button" class="btn btn-sm btn-default btn-flat" :disabled="isDataLoading" @click="ApplyFilter()">Uygula</button>
                        <button type="button" class="btn btn-sm btn-default btn-flat" :disabled="isDataLoading" @click="OpenCloseTaskModal(true,null)">Yeni Hatırlatma / Görev</button>
                       </span>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <span v-if="isDataLoading"><i class="fas fa-solid fa-circle-notch fa-spin"></i> Yükleniyor. Lütfen bekleyiniz.</span>
                <span v-else>
                  <h5 class="float-left">{{ listTitle }}</h5>
                  <span class="float-right">{{taskCount}} adet kayıt bulundu.</span>
                </span>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="row border-bottom mb-3" v-for="t in filterTasks" :key="t">
                  <div class="col-sm-12 col-md-1 col-lg-1 text-center">
                      <div class="h6 m-0">{{ TaskDateListFormat(CalculateDateTimeByOffset(t.taskDate)) }}</div>
                      <div class="h4">{{ TaskTimeListFormat(CalculateDateTimeByOffset(t.taskDate)) }}</div>
                  </div>
                  <div class="col-sm-12 col-md-8 col-lg-8">
                    <h5 class="mb-1"><a href="javascript:;" class="link-color-black" @click="OpenCloseMemberProfileModal(true,t.memberId)">{{ t.member }}</a></h5>
                    <div v-if="t.category!=''"><span class="badge bg-warning">{{ TaskCategory(t.category) }}</span></div>
                    <div v-if="t.content!=''"><em>{{ t.content }}</em></div>
                    <div class="text-black-50 text-sm">{{t.creationUser}} | {{CreationDateFormat(CalculateDateTimeByOffset(t.creationDate))}}</div>
                  </div>
                  <div class="col-sm-12 col-md-2 col-lg-2 text-right">
                    <div>{{ t.toUser.fullName }}</div>
                  </div>
                  <div class="col-sm-12 col-md-1 col-lg-1 text-right"><button class="btn btn-primary btn-sm" @click="OpenCloseTaskTransactionModal(true,t.id,t.category,t.content,t.member)" :disabled="isDataLoading">İşlem Yap</button></div>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
    <TaskModal :Visible="taskModalVisible" :MemberId="taskModalMemberId" @CloseTaskModal="OpenCloseTaskModal($event,null)"></TaskModal>
    <TaskTransactionModal :Visible="taskTransactionModalVisible" :TaskId="taskModal.taskId" :Category="taskModal.category" :Content="taskModal.content" :Member="taskModal.member" @CloseTaskModal="OpenCloseTaskTransactionModal($event,0,0,null,null)"></TaskTransactionModal>
    <MemberProfileModal :Visible="memberProfileModalVisible" :MemberId="memberProfileModalMemberId" @CloseMemberProfileModal="OpenCloseMemberProfileModal($event,null)"></MemberProfileModal>
</template>

<script>
import {mapActions} from "vuex";
import {taskCategories} from "../../../helpers/const-data.js";
import {GetTaskCategoryText} from "../../../helpers/enum-convert-helper.js";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import TaskModal from '../../../components/TaskModal.vue';
import TaskTransactionModal from '../../../components/TaskTransactionModal.vue';
import MemberProfileModal from '../../../components/MemberProfile/MemberProfileModal.vue';
import {DateFormat,CalculateDateTime} from "../../../helpers/date-convert-helper";
export default {
  name: "Task",
  components: {
    TaskModal,
    TaskTransactionModal,
    MemberProfileModal
  },
  data(){
    return{
     tasks:[],
     filterTasks:[],
     category:-1,
     isDataLoading:false,
     listTitle:'',
     rangeDate:{
        start: new Date(),
        end: new Date()
      },
      taskModal:{
        taskId:0,
        category:0,
        content:'',
        member:''
      },
      taskModalVisible:false,
      taskTransactionModalVisible:false,
      taskModalMemberId:null,
      memberProfileModalVisible:false,
      memberProfileModalMemberId:null,
    }
  },
  computed:{
    categoryListForFilter:function(){
      let categories = JSON.parse(JSON.stringify(taskCategories));
      categories.unshift({value:-1,label:'Hepsi'});
      return categories;
    },
    taskCount:function(){
      return this.filterTasks.length +'/'+this.tasks.length; 
    }
  },
  watch:{
    
  },
  methods: {
    ...mapActions(["GET_TASKS"]),
    TaskCategory(value){
      return GetTaskCategoryText(value);
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    TaskDateFormatForModal(value){
      return DateFormat(value,'yyyy-mm-dd hh:MM:ss');
    },
    TaskDateFormat(value){
      return DateFormat(value,'yyyy-mm-dd');
    },
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    CreationDateFormat(value){
      return DateFormat(value,'dd.mm.yyyy hh:MM:ss');
    },
    TaskDateListFormat(value){
      return DateFormat(value,'dd mm');
    },
    TaskTimeListFormat(value){
      return DateFormat(value,'hh MM');
    },
    OpenCloseTaskModal(value,memberId){
      this.taskModalVisible=value;
      this.taskModalMemberId=memberId;
    },
    OpenCloseTaskTransactionModal(value,taskId,category,content,member){
      this.taskModal.taskId=taskId;
      this.taskModal.category=category;
      this.taskModal.content=content;
      this.taskModal.member=member;
      this.taskTransactionModalVisible=value;
      
    },
    GetTasks(){
        this.isDataLoading=true;
        this.ListTitle()
        this.GET_TASKS({
            beginDate:this.TaskDateFormat(this.rangeDate.start).toString()+' 21:00:00',
            finishDate:this.TaskDateFormat(this.rangeDate.end).toString()+' 21:00:00'
          }
        )
        .then((res)=>{
            this.tasks = res.data;
            this.isDataLoading=false;
            this.category=-1;
            this.ApplyFilter();
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.isDataLoading=false;
          });
    },
    ListTitle(){
      let beginDate = this.TaskDateFormat(this.CalculateDateTimeByOffset(this.rangeDate.start));
      let finishDate = this.TaskDateFormat(this.CalculateDateTimeByOffset(this.rangeDate.end));
      if(beginDate==finishDate){
        this.listTitle = beginDate+" Tarihli Görev ve Hatırlatmalar";
      }else{
        this.listTitle = beginDate+" - " +finishDate+" Tarihleri Arasındaki Görev ve Hatırlatmalar";
      }
    },
    ApplyFilter(){
      if(this.category==-1)
      {
        this.filterTasks = this.tasks;
      }else{
        let categoryId=this.category;
        this.filterTasks = this.tasks.filter(x=>x.category===categoryId);
      }

    },
    OpenCloseMemberProfileModal(value,memberId){
      this.memberProfileModalVisible=value;
      this.memberProfileModalMemberId=memberId;
    },
  },
  mounted: function() {
    this.GetTasks();
  }
};
</script>
<style scoped>
</style>