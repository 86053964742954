<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Form Raporu</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{path:'/panel/reports'}">Raprolar</router-link>
              </li>
              <li class="breadcrumb-item active">Form Raporu</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">     
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 col-md-7 col-lg-8">
                    <div class="float-left">
                        <v-date-picker v-model="rangeDate" mode="date" is-range>
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="input-group input-group-sm">      
                                <input
                                  :value="inputValue.start"
                                  v-on="inputEvents.start"
                                  :disabled="isDataLoading"
                                  class="form-control form-control-sm" 
                                  style="width:80px"
                                />
                                <input
                                  :value="inputValue.end"
                                  v-on="inputEvents.end"
                                  :disabled="isDataLoading"
                                  class="form-control form-control-sm"
                                  style="width:80px"
                                />
                                <span class="input-group-append">
                                  <button type="button" class="btn btn-sm btn-default btn-flat" @click="GetReport()" :disabled="isDataLoading">Listele</button>
                                  <export-excel 
                                    :data = "reportList"
                                    :fields ="excel_fields"
                                    worksheet = "Form Listesi"
                                    v-bind:name = "excelFileName"
                                  >
                                    <button type="button" class="btn btn-sm btn-primary btn-flat" :disabled="reportList.length==0"><i class="fas fa-file-excel"></i> Excel Oluştur</button>
                                  </export-excel>
                                </span>
                            </div>    
                          </template>
                        </v-date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header text-right"><small>{{ reportList.length }} adet kayıt listelendi.</small></div>
              <div class="card-body table-responsive p-0 overflow-scroll" style="height:500px;">
                <table class="table">
                  <thead>
                  <tr>
                    <th class="text-center" style="width: 150px;">Tarih</th>
                    <th style="width: 200px;">Satışçı</th>
                    <th style="width: 250px;">Ad-Soyad</th>
                    <th style="width: 250px;">Telefon/E-Posta</th>
                    <th>Kaynak</th>
                  </tr>
                  </thead>
                  <tbody v-if="reportList.length>0">
                    <tr v-for="m in reportList" :key="m">
                      <td class="text-center">{{ ReportViewDateFormat(m.transactionDate) }}</td>
                      <td>{{ m.salePerson }}</td>
                      <td>
                        <div class="badge badge-success" v-if="m.isStudent">Öğrenci</div>
                        <div v-if="!m.isStudent" class="badge" :class="{'badge-danger':m.formStatus==3,'badge-warning':m.formStatus==2,'badge-success':m.formStatus==5}">{{ConvertStatus(m.formStatus)}}</div>
                        <div><a href="javascript:;" @click="OpenCloseMemberProfileModal(true,m.memberId)" class="h5">{{m.name }} {{m.surname }}</a></div>
                      </td>
                      <td><div>{{ m.phoneNumber }}</div><div>{{ m.email }}</div></td>
                      <td>
                        <div><small>Source: {{ m.utmSource }}</small></div>
                        <div><small>Medium: {{ m.utmMedium }}</small></div>
                        <div><small>Content: {{ m.utmContent }}</small></div>
                        <div><small>Term: {{ m.utmTerm }}</small></div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="isDataLoading">
                    <tr>
                      <td colspan="7" class="text-center">Lütfen bekleyin.</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="text-center">Kayıt bulunamadı</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->

    <MemberProfileModal :Visible="memberProfileModalVisible" :MemberId="memberProfileModalMemberId" @CloseMemberProfileModal="OpenCloseMemberProfileModal($event,null)"></MemberProfileModal>
</template>

<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import {GetFormStatus} from "../../../helpers/enum-convert-helper";
import {DateFormat,CalculateDateTime} from "../../../helpers/date-convert-helper";
import MemberProfileModal from '../../../components/MemberProfile/MemberProfileModal.vue';
export default {
  name: "TransactionReport",
  components: {
    MemberProfileModal
  },
  data(){
    return{
      rangeDate:{
        start: new Date(),
        end: new Date()
      },
      isDataLoading:false,
      reportList:[],
      memberProfileModalVisible:false,
      memberProfileModalMemberId:null,
      excel_fields: {
            'Tarih' : {
                field: 'transactionDate',
                callback: (value) => {
                    return this.ReportViewDateFormat(value);
                }
            },
            'Satışçı':'salePerson',
            'Ad': 'name',
            'Soyad': 'surname',
            'Telefon':'phoneNumber',
            'E-Posta' : 'email',
            'Source':'utmSource',
            'Medium':'utmMedium',
            'Content':'utmContent',
            'Campaign':'utmCampaign',
            'Term':'utmTerm',
            'Form Durum': {
                field: 'formStatus',
                callback: (value) => {
                    return `${this.ConvertStatus(value)}`;
                }
            },
            'Öğrenci Mi' : {
                field: 'isStudent',
                callback: (value) => {
                    return value?'Evet':'Hayır';
                }
            }
        },
    }
  },
  computed:{
    excelFileName(){
      let beginDate = DateFormat(this.rangeDate.start,'yyyymmdd');
      let endDate = DateFormat(this.rangeDate.end,'yyyymmdd');
      return beginDate+"-"+endDate+'_FormListesi.xlsx';
    }
  },
  watch:{
  },
  methods: {
    ...mapActions(["FORMREPORT"]),
    
    ConvertStatus(value){
      return GetFormStatus(value);
    },
    OpenCloseMemberProfileModal(value,memberId){
      this.memberProfileModalVisible=value;
      this.memberProfileModalMemberId=memberId;
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    ReportDateFormat(value){
      return DateFormat(value,'yyyy-mm-dd');
    },
    ReportViewDateFormat(value){
      return DateFormat(CalculateDateTime(value),'dd.mm.yyyy hh:dd:ss');
    },
    GetReport(){
      let mainThis = this;
      mainThis.isDataLoading = true;
      mainThis.reportList = [];
      mainThis.reportFilter = [];

      mainThis.FORMREPORT({
        beginDate:mainThis.ReportDateFormat(mainThis.rangeDate.start),
        endDate:mainThis.ReportDateFormat(mainThis.rangeDate.end)
      })
      .then((res)=>{
          mainThis.reportList = res.data;
          mainThis.isDataLoading = false;
        }).catch(error=>{
          mainThis.isDataLoading = false;
           if(this.IsShowErrorMessage(error)){
             this.$toast.error(error,{
                 position : 'top',
                 duration : 3000
             });
           }
        });
    },

    
  },
  mounted: function() {
  }
};
</script>
<style scoped>
</style>