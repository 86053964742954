import { 
    CCPayment,
    GetFreePayments,
    MoneyOrderPayment,
    PaymentInternalAsync,
    PaymentInternal
 } from "../services/payment.service";

const payment_module = {
    state:{},
    getters:{},
    mutations:{},
    actions:{
        CCPAYMENT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                CCPayment(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        MONEYORDERPAYMENT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                MoneyOrderPayment(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        PAYMENTINTERNAL : (context,model) =>{
            return new Promise((resolve,reject)=>{
                PaymentInternalAsync(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        PAYMENTINTERNALNEW : (context,model) =>{
            return new Promise((resolve,reject)=>{
                PaymentInternal(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        GETFREEPAYMENTS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetFreePayments(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
    }
}

export default payment_module;