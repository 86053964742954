function ConvertTaxRateToNumber(value){
    if(value){
        return value * 100;
    }
    return 0;
}
function CalculateRate(value,rate){
    if(value){
        return value * rate;
    }
    return 0;
}

function RoundFormatAmount(value){
    let result = 0;
    if(value){
        result = Math.round(value * 100) / 100;
        return result.toFixed(2);
    }
    return result.toFixed(2);
}

export {
    ConvertTaxRateToNumber,
    CalculateRate,
    RoundFormatAmount
}