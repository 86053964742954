<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Raporlar</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item active">Raporlar</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">        
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <router-link v-if="IsPermision(PermissionCodes.REPORT3)" :to="{path:'/panel/teacher-report'}" class="btn btn-app">
                  <i class="fas fa-user-tie"></i> Eğitmen Raporu
                </router-link>
                <router-link v-if="IsPermision(PermissionCodes.REPORT7)"  :to="{path:'/panel/teacher-with-student'}" class="btn btn-app">
                  <i class="fas fa-users"></i> Eğitmen / Öğrenci Raporu
                </router-link>
                <router-link v-if="IsPermision(PermissionCodes.REPORT4)"  :to="{path:'/panel/transaction-report'}" class="btn btn-app">
                  <i class="fas fa-heartbeat"></i> İşlemler Raporu
                </router-link>
                <router-link v-if="IsPermision(PermissionCodes.REPORT5)"  :to="{path:'/panel/payment-report'}" class="btn btn-app">
                  <i class="fas fa-lira-sign"></i> Ödeme Raporu
                </router-link>
                <router-link v-if="IsPermision(PermissionCodes.REPORT6)"  :to="{path:'/panel/form-report'}" class="btn btn-app">
                  <i class="fab fa-wpforms"></i> Form Raporu
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import permissionMixins from "../../../mixins/permissons-mixin";
import {mapGetters} from "vuex"
export default {
  name: "Reports",
  components: {
  },
  data(){
    return{
    }
  },
  computed:{
    ...mapGetters(["IS_ADMIN","IS_OPERATOR"]),
    isAdmin(){
      return this.IS_ADMIN;
    },
    isOperator(){
      return this.IS_OPERATOR;
    }
  },
  watch:{
  },
  methods: {
  },
  mounted: function() {
  },
  mixins:[permissionMixins],
};
</script>
<style scoped>
</style>