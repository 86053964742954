<template>
  <div class="modal fade" v-bind:id="ModalName" tabindex="2" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Eğitim Durumu Değişikliği</h5>
            <button type="button" class="close" @click="CloseTrainingStatusChangeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-12">
                <div class="alert alert-warning text-sm">Yapacağınız bu işlem mevcut <b>öğretmendeki saatini</b> kaybetmesine yol açacaktır.</div>
                <p class="text-center"><small>"{{ TrainingFileId }}" idli eğitim paketinin mevcut durumu <i><b>"{{ TrainingFileStatus }}"</b></i></small></p>
                <label>Eğitim paketinin yeni durumunu seçiniz.</label>
                <select class="form-control" v-model="trainingFileStatusId">
                  <option v-for="s in TrainingFileStatuses" :key="s" v-bind:value="s.value">{{ s.label }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label>Açıklama</label>
                <input type="text" class="form-control" v-model="content" placeholder="Açıklama" maxlength="300">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="ChangeTrianiningStatus()" :disabled="submitLoading">
                <span v-show="submitLoading" class="spinner-border spinner-border-sm" ></span>
                <span class="ml-2">Kaydet</span>
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import {trainingFileStatuses} from "../../../helpers/const-data";

export default {
  name: 'TrainingStatusChangeModal',
  components: {  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    TrainingFileId:{
      type:String,
      default:null
    },
    TrainingFileStatus:{
      type:String,
      default:null
    },
    ModalName:{
      type:String,
      default:'TrainingStatusChangeModal'
    }
    
  },
  data() {
    return {
      trainingFileStatusId:0,
      content:'',
      submitLoading:false,
    }
  },
  computed:{
    TrainingFileStatuses(){
      return trainingFileStatuses.filter(x=>x.value!=3);
    }
  },
  methods:{
    ...mapActions(["TRAINING_CHANGETRAININGFILESTATUS"]),
      IsShowErrorMessage(value){
        return IsShowErrorMessage(value);
      },
      CloseTrainingStatusChangeModal(){
        window.$("#"+this.ModalName).modal('hide');
        this.$emit("OpenCloseTrainingStatusChangeModal", false);
      },
      ChangeTrianiningStatus(){
        if(this.trainingFileStatusId==0){
          alert('Eğitim paketinin durumunu seçiniz.');
          this.submitLoading=false;
          return;
        }
        
        if(!confirm('Bu işlemi yapmak istiyor musunuz?'))
        {
          this.submitLoading=false;
          return;
        }

        this.submitLoading=true;
        this.TRAINING_CHANGETRAININGFILESTATUS({
          trainingFileId:this.TrainingFileId,
          trainingFileStatus:this.trainingFileStatusId,
          content:this.content
          }
        )
        .then((res)=>{
            let message = res.data.description;
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
            });
            this.submitLoading=false;
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.submitLoading=false;
          });
      }
  },
  watch: {
    Visible(value){
      if(value){
        this.trainingFileStatusId=0;
        this.content='';
        this.submitLoading=false;
        window.$("#"+this.ModalName).modal({backdrop: 'static',keyboard: false, show: true});
      }
    }
  },
  mounted: function() {
  }
  
}
</script>