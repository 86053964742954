import { TeacherPaymentReport,TeacherWithStudentReport,TransactionReport,PaymentReport,FormReport } from "../services/report.service";

const reports_module = {
    state:{},
    getters:{},
    mutations:{},
    actions:{
        TEACHERPAYMENTREPORT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                TeacherPaymentReport(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHERWITHSTUDENTREPORT : () =>{
            return new Promise((resolve,reject)=>{
                TeacherWithStudentReport()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        USERTRANSACTIONREPORT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                TransactionReport(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        PAYMENTREPORT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                PaymentReport(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        FORMREPORT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                FormReport(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
    }
}

export default reports_module;