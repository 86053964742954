import {service} from "../helpers/request"

function MemberOldListWithPage(page){
    return service({
        url:'/Member/MemberOldListWithPage?page='+page,
        method:'post',
        data:{}
    })
}

function MemberNewListWithPage(model){
    return service({
        url:'/Member/MemberNewListWithPage',
        method:'post',
        data:model
    })
}

function MemberPrepareListWithPage(model){
    return service({
        url:'/Member/MemberPrepareListWithPage',
        method:'post',
        data:model
    })
}
function MemberReturnCancellationFormListWithPage(model){
    return service({
        url:'/Member/MemberReturnCancellationFormListWithPage',
        method:'post',
        data:model
    })
}

function MemberWaitingListWithPage(model){
    return service({
        url:'/Member/MemberWaitingListWithPage',
        method:'post',
        data:model
    })
}
function MemberDateListWithPage(model){
    return service({
        url:'/Member/MemberDateListWithPage',
        method:'post',
        data:{
            page:model.page,
            user:model.user,
            beginDate: new Date(model.beginDate),
            endDate : new Date(model.endDate)
        }
    })
}

function MemberCancelListWithPage(model){
    return service({
        url:'/Member/MemberCancelListWithPage',
        method:'post',
        data:{
            page:model.page,
            user:model.user,
            reasonForCancellation:model.reasonForCancellation,
            beginDate: new Date(model.beginDate),
            endDate : new Date(model.endDate)
        }
    })
}

function MemberSearchListWithPage(model){
    return service({
        url:'/Member/MemberSearchListWithPage',
        method:'post',
        data:{
            page: model.page,
            searchField: parseInt(model.searchField,10),
            searchText : model.searchText
        }
    })
}

function MemberFormCancel(model){
    return service({
        url:'/Member/MemberCancel',
        method:'post',
        data:{
            memberId: model.id,
            why: model.why,
            content : model.content
        }
    })
}

function MemberFormPrepare(model){
    return service({
        url:'/Member/MemberPrepare',
        method:'post',
        data:{
            memberId: model.id,
            why: model.why,
            prepareDate: model.prepareDate,
            content : model.content
        }
    })
}

function MemberNoteListWithPage(model){
    return service({
        url:'/Member/MemberNoteListWithPage',
        method:'post',
        data:{
            memberId: model.memberId,
            page: model.page
        }
    })
}

function MemberSaveNote(model){
    return service({
        url:'/Member/MemberSaveNote',
        method:'post',
        data:{
            memberId: model.memberId,
            content : model.content
        }
    })
}

function MemberInfoUpdate(model){
    return service({
        url:'/Member/MemberInfoUpdate',
        method:'post',
        data:{
            memberId: model.memberId,
            name : model.name,
            surname : model.surname,
            gender : parseInt(model.gender,10)
        }
    })
}

function MemberContactEmailUpdate(model){
    return service({
        url:'/Member/MemberContactEmailUpdate',
        method:'post',
        data:{
            memberId: model.memberId,
            email : model.eMail
        }
    })
}

function MemberContactPhoneUpdate(model){
    return service({
        url:'/Member/MemberContactPhoneUpdate',
        method:'post',
        data:{
            memberId: model.memberId,
            phoneCountryCode : model.phoneCode,
            phoneNumber : model.phoneNumber
        }
    })
}

function GetOperationUserList(){
    return service({
        url:'/Member/GetOperationUserList',
        method:'post',
        data:{}
    })
}

function GetOperationUserListForChangeUser(){
    return service({
        url:'/Member/GetOperationUserListForChangeUser',
        method:'post',
        data:{}
    })
}

function MemberFormOperatorUpdate(model){
    return service({
        url:'/Member/MemberFormOperatorUpdate',
        method:'post',
        data:{
            memberId: model.memberId,
            userId : model.userId
        }
    })
}

function MemberSalesPersonUpdate(model){
    return service({
        url:'/Member/MemberSalesPersonUpdate',
        method:'post',
        data:{
            memberId: model.memberId,
            userId : model.userId
        }
    })
}

function MemberStudentOperatorUpdate(model){
    return service({
        url:'/Member/MemberStudentOperatorUpdate',
        method:'post',
        data:{
            memberId: model.memberId,
            userId : model.userId
        }
    })
}

function MemberActiveStudentListWithPage(page){
    return service({
        url:'/Member/ActiveStudentListWithPage?page='+page,
        method:'post',
        data:{}
    })
}
function MemberComplatedStudentListWithPage(page){
    return service({
        url:'/Member/MemberComplatedStudentListWithPage?page='+page,
        method:'post',
        data:{}
    })
}
function MemberTryYourSelfStudentListWithPage(page){
    return service({
        url:'/Member/MemberTryYourSelfStudentListWithPage?page='+page,
        method:'post',
        data:{}
    })
}
function MemberInActiveStudentListWithPage(page){
    return service({
        url:'/Member/InActiveStudentListWithPage?page='+page,
        method:'post',
        data:{}
    })
}

function MemberFreezeEducationStudentListWithPage(page){
    return service({
        url:'/Member/FreezeEducationStudentListWithPage?page='+page,
        method:'post',
        data:{}
    })
}

function MemberFindTeacherStudentListWithPage(page){
    return service({
        url:'/Member/FindTeacherStudentListWithPage?page='+page,
        method:'post',
        data:{}
    })
}

function MemberBreakTimeStudentListWithPage(page){
    return service({
        url:'/Member/BreakTimeStudentListWithPage?page='+page,
        method:'post',
        data:{}
    })
}

function MemberStudentSearchListWithPage(model){
    return service({
        url:'/Member/StudentSearchListWithPage',
        method:'post',
        data:{
            page: model.page,
            searchField: parseInt(model.searchField,10),
            searchText : model.searchText
        }
    })
}

function FormRegister(model){
    return service({
        url:'/Member/RegisterForm',
        method:'post',
        data:{
            name: model.name,
            surname: model.surname,
            phoneCountryCode: model.phoneCountryCode,
            phoneNumber: model.phoneNumber,
            eMail: model.eMail,
            utmSource: model.utmSource,
            utmMedium: '',
            utmContent: model.utmContent,
            utmCampaign: '',
            utmTerm: ''
        }
    })
}
function AddReferenceNumber(model){
    return service({
        url:'/Member/AddReferenceNumber',
        method:'post',
        data:{
            referenceNumber: model.referenceNumber,
            memberId: model.memberId,
        }
    })
}

function GetReasonForCancellation(){
    return service({
        url:'/Member/GetReasonForCancellation',
        method:'get',
        data:{}
    })
}

function GetReasonForDelay(){
    return service({
        url:'/Member/GetReasonForDelay',
        method:'get',
        data:{}
    })
}

function GetBillInfos(memberId){
    return service({
        url:'/Member/GetBillInfos?memberId='+memberId,
        method:'post',
        data:{}
    })
}

function CreatBillInfo(model){
    return service({
        url:'/Member/CreatBillInfo',
        method:'post',
        data:{
            memberId: model.memberId,
            title: model.title,
            taxOffice: model.taxOffice,
            taxNumber: model.taxNumber,
            address: model.address,
            country: model.country,
            city: model.city,
            district: model.district,
            postCode: model.postCode,
            isUse: true
        }
    })
}

function GetTransactionListWithPage(model){
    return service({
        url:'/Member/GetTransactionList',
        method:'post',
        data:{
            page: model.page,
            memberId: model.memberId
        }
    })
}

function ImportFormWithCsv(model){
    return service({
        url:'/Form/ImportFormWithCsv',
        method:'post',
        data:model
    })
}

function GetMemberForProfileModal(id){
    return service({
        url:'/Member/GetMemberForProfileModal?id='+id,
        method:'post',
        data:{}
    })
}

function MemberChangePasswordForPanel(model){
    return service({
        url:'/Member/MemberChangePasswordForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            password: model.password,
            isSendEmail: model.isSendEmail
        }
    })
}
export {
    MemberOldListWithPage,
    MemberNewListWithPage,
    MemberPrepareListWithPage,
    MemberWaitingListWithPage,
    MemberCancelListWithPage,
    MemberDateListWithPage,
    MemberSearchListWithPage,
    MemberFormCancel,
    MemberFormPrepare,
    MemberNoteListWithPage,
    MemberSaveNote,
    MemberInfoUpdate,
    MemberContactEmailUpdate,
    MemberContactPhoneUpdate,
    GetOperationUserList,
    GetOperationUserListForChangeUser,
    MemberFormOperatorUpdate,
    MemberSalesPersonUpdate,
    MemberStudentOperatorUpdate,
    MemberActiveStudentListWithPage,
    MemberComplatedStudentListWithPage,
    MemberInActiveStudentListWithPage,
    MemberFreezeEducationStudentListWithPage,
    MemberFindTeacherStudentListWithPage,
    MemberBreakTimeStudentListWithPage,
    MemberStudentSearchListWithPage,
    FormRegister,
    AddReferenceNumber,
    GetReasonForCancellation,
    GetReasonForDelay,
    GetBillInfos,
    CreatBillInfo,
    GetTransactionListWithPage,
    ImportFormWithCsv,
    GetMemberForProfileModal,
    MemberTryYourSelfStudentListWithPage,
    MemberChangePasswordForPanel,
    MemberReturnCancellationFormListWithPage
};
