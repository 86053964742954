import {service} from "../helpers/request"

function CreateTask(model){
    return service({
        url:'/Task/CreateTask',
        method:'post',
        data:{
            category:model.category,
            taskDate : model.taskDate,
            content : model.content,
            toUser:model.toUser,
            memberId:model.memberId,
            isSetReminder:model.isSetReminder
        }
    })
}

function GetTasks(model){
    return service({
        url:'/Task/Tasks',
        method:'post',
        data:{
            beginDate:model.beginDate,
            finishDate : model.finishDate
        }
    })
}

function GetReminderTasks(){
    return service({
        url:'/Task/ReminderTasks',
        method:'post',
        data:{}
    })
}

function TaskTransaction(model){
    return service({
        url:'/Task/TaskTransaction',
        method:'post',
        data:{
            id:model.id,
            taskDate : model.taskDate,
            isComplated : model.isComplated,
            isSetReminder:model.isSetReminder,
            taskNote : model.taskContent
        }
    })
}

export {CreateTask,GetTasks,TaskTransaction,GetReminderTasks};
