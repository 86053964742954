import {service} from "../helpers/request"

function TrainingPackageList(){
    return service({
        url:'/Training/TrainingPackageList',
        method:'post',
        data:{}
    })
}

function TrainingLessonTeacherTimeList(){
    return service({
        url:'/Training/TrainingLessonTeacherTimeList',
        method:'post',
        data:{}
    })
}

function GetBookWithLevel(){
    return service({
        url:'/Training/GetBookWithLevel',
        method:'post',
        data:{}
    })
}

function GetBookWithSubject(){
    return service({
        url:'/Training/GetBookWithSubject',
        method:'post',
        data:{}
    })
}


function GetScheduleForPanel(memberId){
    return service({
        url:'/Training/GetScheduleForPanel?memberId='+memberId,
        method:'post',
        data:{}
    })
}

function TrainingCreate(model){
    return service({
        url:'/Training/TrainingCreate',
        method:'post',
        data:{
            packageId:model.packageId,
            currency:model.currency,
            basePrice:model.basePrice,
            discountPrice:model.discountPrice,
            lateChargePrice :model.lateChargePrice,
            taxPrice:model.taxPrice,
            taxRate:model.taxRate,
            campaings:model.campaings,
            memberId:model.memberId,
            teacherId:model.teacherId,
            beginDate:model.beginDate,
            lessonTime:model.lessonTime,
            month:model.month,
            weekDay:model.weekDay,
            duration:model.duration,
            rights:model.rights,
            contactType:model.contactType,
            contactData:model.contactData,
            bookName:model.bookName,
            bookLevel:model.bookLevel,
            payments:model.payments,
            firstLessonIsLevelTest:model.firstLessonIsLevelTest
        }
    })
}

function ChangeBookInScheduleForPanel(model){
    return service({
        url:'/Training/ChangeBookInScheduleForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            lessonDate: model.lessonDate,
            bookId: model.bookId,
            bookSubjectId: model.bookSubjectId,
            isChangeOneLesson: model.isChangeOneLesson
        }
    })
}

function GetLessonInScheduleForPanel(model){
    return service({
        url:'/Training/GetLessonInScheduleForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            lessonDate: model.lessonDate
        }
    })
}

function AddOrRemoveLessonInScheduleForPanel(model){
    return service({
        url:'/Training/AddOrRemoveLessonInScheduleForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            processType: model.processType,
            numberOfLesson: model.numberOfLesson
        }
    })
}

function ChangeTeacherTimeForPanel(model){
    return service({
        url:'/Training/ChangeTeacherTimeForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            teacherId: model.teacherId,
            lessonTime: model.lessonTime,
            useRight: false
        }
    })
}

function UpdateSkypeAddressForPanel(model){
    return service({
        url:'/Training/UpdateSkypeAddressForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            skypeAddress: model.skypeAddress
        }
    })
}

function UpdateRightChangeTeacherForPanel(model){
    return service({
        url:'/Training/UpdateRightChangeTeacherForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            rightNumber: model.rightNumber
        }
    })
}

function UpdateRightPostponeForPanel(model){
    return service({
        url:'/Training/UpdateRightPostponeForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            rightNumber: model.rightNumber
        }
    })
}

function UpdateRightMissedForPanel(model){
    return service({
        url:'/Training/UpdateRightMissedForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            rightNumber: model.rightNumber
        }
    })
}

function UpdateRightFreezeLessonForPanel(model){
    return service({
        url:'/Training/UpdateRightFreezeLessonForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            rightNumber: model.rightNumber
        }
    })
}

function CreateDemoLessonForPanel(model){
    return service({
        url:'/Training/CreateDemoLessonForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            teacherId: model.teacherId,
            lessonDate: model.lessonDate,
            lessonDuration:model.lessonDuration,
            lessonTime:model.lessonTime,
            password:model.password,
            skypeAddress:model.skypeAddress
        }
    })
}

function GetLessonForPanel(model){
    return service({
        url:'/Training/GetLessonsForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            lessonDate: model.lessonDate,
            direction:model.direction
        }
    })
}

function GetLessonByDate(model){
    return service({
        url:'/Training/GetLessonByDate',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            lessonDate: model.lessonDate
        }
    })
}

function SaveLessonByDate(model){
    let data = {
        memberId: model.memberId,
        trainingFileId: model.trainingFileId,
        lessonDate: model.lessonDate,
        scheduleStatus:parseInt(model.scheduleStatus),
        teacherId:model.teacherId,
        note:model.note,
        incorrectList :model.incorrectList,
        listening:parseInt(model.listening==''?"0":model.listening),
        reading:parseInt(model.reading==''?"0":model.reading),
        speaking:parseInt(model.speaking==''?"0":model.speaking),
        vocabulary:parseInt(model.vocabulary==''?"0":model.vocabulary),
        grammar:parseInt(model.grammar==''?"0":model.grammar),
        usePostponeRight:model.usePostponeRight,
        hardProcess:model.hardProcess,
        levelTestLessonResult:parseInt(model.levelTestLessonResult==''?"0":model.levelTestLessonResult),
    };
    return service({
        url:'/Training/SaveLessonByDate',
        method:'post',
        data:data
    })
}

function FreezeTrainingFileForPanel(model){
    return service({
        url:'/Training/FreezeTrainingFileForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            beginDateStr: model.beginDate,
            finishDateStr: model.finishDate,
        }
    })
}

function LessonSlideForPanel(model){
    return service({
        url:'/Training/LessonSlideForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            beginDateStr: model.beginDate,
            finishDateStr: model.finishDate,
        }
    })
}

function UpdateStartDateForPanel(model){
    return service({
        url:'/Training/UpdateStartDateForPanel',
        method:'post',
        data:{
            memberId: model.memberId,
            trainingFileId: model.trainingFileId,
            beginDateStr: model.beginDate,
        }
    })
}

function ChangeTrainingFileStatusForPanel(model){
    return service({
        url:'/Training/ChangeTrainingFileStatusForPanel',
        method:'post',
        data:{
            trainingFileId: model.trainingFileId,
            status: parseInt(model.trainingFileStatus,10),
            content: model.content
        }
    })
}


export {
    TrainingPackageList,
    TrainingLessonTeacherTimeList,
    TrainingCreate,
    GetBookWithLevel,
    GetBookWithSubject,
    GetScheduleForPanel,
    ChangeBookInScheduleForPanel,
    GetLessonInScheduleForPanel,
    AddOrRemoveLessonInScheduleForPanel,
    ChangeTeacherTimeForPanel,
    UpdateSkypeAddressForPanel,
    UpdateRightChangeTeacherForPanel,
    UpdateRightPostponeForPanel,
    UpdateRightMissedForPanel,
    UpdateRightFreezeLessonForPanel,
    CreateDemoLessonForPanel,
    GetLessonForPanel,
    GetLessonByDate,
    SaveLessonByDate,
    FreezeTrainingFileForPanel,
    LessonSlideForPanel,
    UpdateStartDateForPanel,
    ChangeTrainingFileStatusForPanel
};
