<template>
  <div style="min-height: 500px;" class="tab-pane fade p-2" :class="showTab">
    <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
    <div v-else>
      <div v-if="trainingList.length==0" class="text-center mt-5">Herhangi bir eğitim paketi bulunamadı.</div>
      <div class="overflow-auto mb-1" style="height:500px;">
        <!-- <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation" v-for="(g,index) in trainingGroup" :key="g">
            <button class="nav-link" :class="{'active':index==0}" v-bind:id="'t'+index+'-tab'" data-toggle="tab" v-bind:data-target="'#t'+index" type="button" role="tab" v-bind:aria-controls="'t'+index" aria-selected="true">{{index+1}}. Hesap</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div  v-for="(g,index) in trainingGroup" :key="g" class="tab-pane fade show" :class="{'active':index==0}" v-bind:id="'t'+index" role="tabpanel" v-bind:aria-labelledby="'t'+index+'-tab'">Hesap {{ index }}</div>
        </div> -->

        <div class="card" v-for="t in trainingList" :key="t">
          <h5 class="card-header bg-light">
            <div class="btn-group">
              <button  type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown">
                <i class="fas fa-bars"></i>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'Calendar')}">Eğitim Takvimi</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'ChangeTimeTeacher')}">Eğitmen/Saat Değişitir</a>
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'Freeze')}">Ders Dondur</a>
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'AddRemoveLesson')}">Ders Ekle/Sil</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'ChangeSkype')}">Skype Adresini Değiştir</a>
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'ChangeFreezeRight')}">Ders Dondurma Hakkını Değiştir</a>
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'PostponeRight')}">Ders Erteleme Hakkını Değiştir</a>
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'ChangeBeginDate')}">Başlangıç Tarihini Değiştir</a>
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'SkipLesson')}">Ders Kaydır</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'NewPackage')}" @click="onNewPackage(t.accountId)">Yeni Paket</a>
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'UpdatePackage')}">Uzatma</a>
                <a class="dropdown-item text-sm" href="javascript:;" :class="{'disabled':MenuDisabled(t.status,'ChangePackage')}">Paket Değişikliği</a>
                <div class="dropdown-divider" v-if="IsPermision(PermissionCodes.STUDENT17)"></div>
                <a class="dropdown-item text-sm" href="javascript:;" v-if="IsPermision(PermissionCodes.STUDENT17)" :class="{'disabled':MenuDisabled(t.status,'ChangeTrainingStatus')}" @click="OpenCloseTrainingStatusChangeModal(true,t.trainingFileId,GetTrainingFileStatusText(t.status))" >Eğitim Durumunu Değişikliği</a>
              </div>
            </div>
            {{t.packageTitle}} <span class="badge " :class="{'badge-success':t.status==1,'badge-primary':t.status==2,'badge-warning':t.status>2}">{{GetTrainingFileStatusText(t.status)}}</span>
          </h5>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-lg-7">
                <div class="h5 mb-0"><span class="h3">{{CalculateTimeByOffset(t.lessonTime)}}</span> {{t.teacherFullName}} ({{ t.teacherNickname }})</div>
                <div><b>Toplam Ders Adedi :</b> {{t.lessonTotalCount}}</div>
                <div><b>Kalan Ders Adedi :</b> {{t.lessonWaitCount}}</div>
                <div v-if="t.beginDateAfterFreeze!=null"><b>Ders Dond. Başlangıç Tarihi :</b> {{t.beginDateAfterFreeze}}</div>
              </div>
              <div class="col-sm-12 col-lg-5">
                  <div><b>Skype :</b> {{t.skypeName}}</div>
                  <div><b>Ders Erteleme :</b> {{t.rights.postpone}} Adet</div>
                  <div><b>Ders Dondurma :</b> {{t.rights.freezeLesson}} Adet</div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right"><small>Eğitim Id : {{t.trainingFileId}}</small></div>
        </div>
      </div>
    </div>
  </div>
  <TrainingStatusChangeModal :ModalName="'TrainingStatusChangeModal'" :Visible="trainingStatusChangeModalVisible" :TrainingFileId="selectTrainingFileId" @OpenCloseTrainingStatusChangeModal="OpenCloseTrainingStatusChangeModal($event)" :TrainingFileStatus="selectTrainingFileStatus"></TrainingStatusChangeModal>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import permissionMixins from "../../mixins/permissons-mixin.js";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
import {DateFormat,CalculateTime} from "../../helpers/date-convert-helper";
import {GetTrainingFileStatus} from "../../helpers/enum-convert-helper";
import TrainingStatusChangeModal from "./../../components/MemberProfile/Modals/TrainingStatusChangeModal.vue";
export default {
  name: 'TabTraining',
  emits: ['NewPackage'],
  components: {
    TrainingStatusChangeModal 
    },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      isLoading:true,
      trainingList:[],
      selectTrainingFileId:'',
      selectTrainingFileStatus:'',

      trainingStatusChangeModalVisible:false
    }
  },
  computed:{
    trainingGroup(){
      let t = [];
      if(this.trainingList && this.trainingList.length>0){
        let g = Object.groupBy(this.trainingList,({accountId})=>accountId);
        for(let key in g){
          t.push(key);
        }
      }
      return t;
      //let t = [];
      //if(this.trainingList && this.trainingList.length>0){
      //  let g = Object.groupBy(this.trainingList,({accountId})=>accountId);
      //  for(let key in g){
      //    let obj = g[key];
      //    for(let prop in obj){
      //      t.push(obj[prop]);
      //    }
      //  }
      //  return t;
      //}
      //return t
    }
  },
  methods:{
    ...mapActions(["MEMBERPROFILE_TRAININGS"]),
    CalculateTimeByOffset(value){
        return CalculateTime(value);
    },
    CreationDateFormat(value){
      return DateFormat(value,'dd.mm.yyyy hh:MM:ss');
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    GetTrainingFileStatusText(value){
      return GetTrainingFileStatus(value);
    },
    onNewPackage(value){
      this.$emit("NewPackage", value);
    },
    MenuDisabled(status,menuName){
      let result = false;
      if(menuName=='Calendar'){
        result = this.CalendarDisabled(status)
      }else if(menuName=='ChangeTimeTeacher'){
        result = this.ChangeTimeTeacherDisabled(status)
      }else if(menuName=='Freeze'){
        result = this.FreezeDisabled(status)
      }else if(menuName=='AddRemoveLesson'){
        result = this.AddRemoveLessonDisabled(status)
      }else if(menuName=='ChangeSkype'){
        result = this.ChangeSkypeDisabled(status)
      }else if(menuName=='ChangeFreezeRight'){
        result = this.ChangeFreezeRightDisabled(status)
      }else if(menuName=='PostponeRight'){
        result = this.PostponeRightDisabled(status)
      }else if(menuName=='ChangeBeginDate'){
        result = this.ChangeBeginDateDisabled(status)
      }else if(menuName=='SkipLesson'){
        result = this.SkipLessonDisabled(status)
      }else if(menuName=='NewPackage'){
        result = this.NewPackageDisabled(status)
      }else if(menuName=='UpdatePackage'){
        result = this.UpdatePackageDisabled(status)
      }else if(menuName=='ChangePackage'){
        result = this.ChangePackageDisabled(status)
      }else if(menuName=='ChangeTrainingStatus'){
        result = this.ChangeTrainingStatusDisabled(status)
      }else{
        result = false;
      }
      return result;
    },
    CalendarDisabled(status){
      let result = false;
      if(status==1){
        result = false
      }else if(status==2){
        result = false
      }else if(status==3){
        result = false
      }else if(status==4){
        result = false
      }else if(status==5){
        result = false
      }
      return result;
    },
    ChangeTimeTeacherDisabled(status){
      let result = false;
      if(status==1){
        result = false
      }else if(status==2){
        result = true
      }else if(status==3){
        result = false
      }else if(status==4){
        result = false
      }else if(status==5){
        result = false
      }
      return result;
    },
    FreezeDisabled(status){
      let result = false;
      if(status==1){
          result = false
        }else if(status==2){
          result = true
        }else if(status==3){
          result = true
        }else if(status==4){
          result = false
        }else if(status==5){
          result = false
        }
      return result;
    },
    AddRemoveLessonDisabled(status){
      let result = false;
      if(status==1){
          result = false
        }else if(status==2){
          result = true
        }else if(status==3){
          result = false
        }else if(status==4){
          result = false
        }else if(status==5){
          result = false
        }
      return result;
    },
    ChangeSkypeDisabled(status){
      let result = false;
      if(status==1){
          result = false
        }else if(status==2){
          result = true
        }else if(status==3){
          result = false
        }else if(status==4){
          result = false
        }else if(status==5){
          result = false
        }
      return result;
    },
    ChangeFreezeRightDisabled(status){
      let result = false;
      if(status==1){
          result = false
        }else if(status==2){
          result = true
        }else if(status==3){
          result = false
        }else if(status==4){
          result = false
        }else if(status==5){
          result = false
        }
      return result;
    },
    PostponeRightDisabled(status){
      let result = false;
      if(status==1){
          result = false
        }else if(status==2){
          result = true
        }else if(status==3){
          result = false
        }else if(status==4){
          result = false
        }else if(status==5){
          result = false
        }
      return result;
    },
    ChangeBeginDateDisabled(status){
      let result = false;
      if(status==1){
          result = false
        }else if(status==2){
          result = true
        }else if(status==3){
          result = true
        }else if(status==4){
          result = true
        }else if(status==5){
          result = false
        }
      return result;
    },
    SkipLessonDisabled(status){
      let result = false;
      if(status==1){
          result = false
        }else if(status==2){
          result = true
        }else if(status==3){
          result = true
        }else if(status==4){
          result = true
        }else if(status==5){
          result = false
        }
      return result;
    },
    ChangePackageDisabled(status){
      let result = false;
      if(status==1){
          result = false
        }else if(status==2){
          result = true
        }else if(status==3){
          result = false
        }else if(status==4){
          result = false
        }else if(status==5){
          result = false
        }
      return result;
    },
    NewPackageDisabled(status){
      let result = false;
      console.log(status);
      //if(status==1){
      //    result = true
      //  }else if(status==2){
      //    result = false
      //  }else if(status==3){
      //    result = true
      //  }else if(status==4){
      //    result = true
      //  }else if(status==5){
      //    result = true
      //  }
      return result;
    },
    UpdatePackageDisabled(status){
      let result = false;
      if(status==1){
          result = false
        }else if(status==2){
          result = true
        }else if(status==3){
          result = false
        }else if(status==4){
          result = false
        }else if(status==5){
          result = false
        }
      return result;
    },
    ChangeTrainingStatusDisabled(status){
      let result = false;
      if(status==1){
          result = false
        }else if(status==2){
          result = false
        }else if(status==3){
          result = false
        }else if(status==4){
          result = false
        }else if(status==5){
          result = false
        }
      return result;
    },

    GetInitData(){
      this.isLoading=true;
      this.MEMBERPROFILE_TRAININGS({id:this.MemberId})
        .then((res)=>{
          this.isLoading=false;
          this.trainingList = res.data;
        })
        .catch(error=>{
            this.isLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
          });
    },
    OpenCloseTrainingStatusChangeModal(value,trainingFileId,trainingFileStatus){
      this.selectTrainingFileId = trainingFileId;
      this.selectTrainingFileStatus = trainingFileStatus;
      this.trainingStatusChangeModalVisible = value;
    }
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData();
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  },
  mixins:[permissionMixins],
  
}
</script>