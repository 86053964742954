<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
  />
</template>

<script>
import { Doughnut } from 'vue-chartjs'
import { 
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'DoughnutChart',
  components: { Doughnut },
  props: {
    chartId: {
      type: String,
      default: 'chartId'
    },
    datasetIdKey: {
      type: String,
      default: 'datasetIdKey'
    },
    chartData: {
      type: Object,
      required: true
    },
    
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  }
}
</script>