<template>
  <div style="min-height: 500px;" class="tab-pane fade p-2" :class="showTab">
    <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
    <div v-else>
      <div v-if="permissions.length==0" class="text-center mt-5">Herhangi bir izin bulunamadı.</div>
      <div v-else>
        <div class="overflow-auto mb-2" style="height:440px;">
            <ul class="list-group mb-2" v-for="g in PermissionGroups" :key="g">
              <li class="list-group-item text-uppercase active">{{ g }}</li>
              <li class="list-group-item" v-for="(p,i) in GetPermissionsByGroup(g)" :key="p">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" v-model="allowPermissions" v-bind:value="p.permissionId" v-bind:id="'per'+g+i" :checked="p.isAllow">
                  <label class="form-check-label" v-bind:for="'per'+g+i">
                    {{p.title}}
                  </label>
                </div>  
              </li>
            </ul>
          </div>
          <a href="javascript:;" @click="SavePermission()" class="btn btn-sm btn-primary float-right" :disabled="isSaveButtonLoading">Kaydet</a>
      </div>
    </div>
  </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
export default {
  name: 'TabPermisson',
  components: { 
    },
  props: {
    UserId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      permissions:[],
      allowPermissions:[],
      isLoading:false,
      isSaveButtonLoading:false
    }
  },
  computed:{
    PermissionGroups: function(){
      return Object.keys(this.permissions.reduce((r,{groupCode}) => (r[groupCode]='', r) , {}))
    }
  },
  methods:{
    ...mapActions(["USERPROFILE_GETPERMISSIONS","USERPROFILE_SAVEPERMISSIONS"]),
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    GetPermissionsByGroup(value){
      if(!value)
        return [];

      return this.permissions.filter(x=>x.groupCode==value);
    },
    SavePermission(){
      this.isSaveButtonLoading=true;
      if(!confirm('İzinleri güncellemek istiyor musunuz?')){
        this.isSaveButtonLoading=false;
        return;
      }

      let requestModel = {
        id:this.UserId,
        permissions:this.allowPermissions
      };

      this.USERPROFILE_SAVEPERMISSIONS(requestModel)
        .then((res)=>{
          this.isSaveButtonLoading=false;
          this.$toast.success(res.data.description,{
                position : 'top',
                duration : 3000
          });
          this.GetInitData();
        })
        .catch(error=>{
            this.isSaveButtonLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
          });
    },
    GetInitData(){
      this.isLoading=true;
      this.USERPROFILE_GETPERMISSIONS({id:this.UserId})
        .then((res)=>{
          this.isLoading=false;
          this.permissions = res.data;
          this.allowPermissions = this.permissions.filter(x=>x.isAllow).map((x)=> x['permissionId']);
        })
        .catch(error=>{
            this.isLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
          });
    },
  },
  watch: {
    Visible(value){
      if(value && this.UserId){
        this.showTab='show active';
        this.permissions=[];
        this.allowPermissions=[];
        this.isLoading=false;
        this.isSaveButtonLoading=false;
        this.GetInitData();
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  }
  
}
</script>