import {service} from "../helpers/request"

function GetUserById(id){
    return service({
        url:'/UserProfile/GetUserById?id='+id,
        method:'post',
        data:{}
    })
}

function GetPermissonsByUserId(id){
    return service({
        url:'/UserProfile/GetPermissonsByUserId?id='+id,
        method:'post',
        data:{}
    })
}

function SavePermissonsByUserId(model){
    return service({
        url:'/UserProfile/SavePermissions',
        method:'post',
        data:model
    })
}

function GetTransactions(model){
    return service({
        url:'/UserProfile/GetTransactionList',
        method:'post',
        data:{
            page: model.page,
            userId: model.userId
        }
    })
}

export {GetUserById,GetPermissonsByUserId,SavePermissonsByUserId,GetTransactions};
