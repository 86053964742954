<template>
  <div style="min-height: 500px;" class="tab-pane fade p-2" :class="showTab">
    <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
    <div v-else>
        <div class="mt-3 row justify-content-md-center">
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <div class="input-group  input-group-lg">
                    <input type="text" class="form-control form" v-model.lazy="form.amount" v-money="money" :disabled="form.isLoading">
                    <div class="input-group-append">
                      <span class="input-group-text">TL</span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Kart Sahibi" maxlength="30" v-model="form.holderName"  :disabled="form.isLoading"/>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control"  v-maska="'####-####-####-####'" v-model="form.cardNumber"  :disabled="form.isLoading"/>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group mb-1">
                      <input type="text" class="form-control" v-maska="'##/####'" v-model="form.expiryDate"  :disabled="form.isLoading"/>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group mb-1">
                      <input type="text" class="form-control" v-maska="'###'" v-model="form.cvv"  :disabled="form.isLoading"/>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="form-group mb-1">
                      <select class="form-control" v-model="form.installment" :disabled="form.isLoading">
                        <option value="1">1 Taksit</option>
                        <option value="2">2 Taksit</option>
                        <option value="3">3 Taksit</option>
                        <option value="4">4 Taksit</option>
                        <option value="5">5 Taksit</option>
                        <option value="6">6 Taksit</option>
                        <option value="7">7 Taksit</option>
                        <option value="8">8 Taksit</option>
                        <option value="9">9 Taksit</option>
                        <option value="10">10 Taksit</option>
                        <option value="11">11 Taksit</option>
                        <option value="12">12 Taksit</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 text-center">
                      <button type="submit" class="btn btn-primary btn-lg btn-block" @click="pay()" :disabled="form.isLoading">
                        <span class="ml-2">Ödeme Al</span>
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      
    </div>
  </div>
  <PaymentThreeDModal :ThreeUrl="paymentThreeUrl" :Visible="paymentThreeDModalVisible" @CloseModal="OpenClosePaymentThreeDModalModal($event)"></PaymentThreeDModal>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {VMoney} from "v-money";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
import PaymentThreeDModal from "../PaymentThreeDModal.vue"
//import {DateFormat,CalculateDateTime} from "../../helpers/date-convert-helper";
export default {
  name: 'TabPaymentCheckOut',
  components: { 
    PaymentThreeDModal
    },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      isLoading:true,
      paymentThreeDModalVisible:false,
      paymentThreeUrl:'',
      form:{
        amount:0.00,
        holderName:'',
        cardNumber:'',
        expiryDate:'',
        cvv:'',
        installment:1,
        isLoading:false
      },
      money: {
          decimal: '.',
          thousands: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        }
    }
  },
  directives: {money: VMoney},
  computed:{

  },
  methods:{
    ...mapActions(["PAYMENTINTERNAL"]),
    //CalculateDateTimeByOffset(value){
    //    return CalculateDateTime(value);
    //},
    //ModalDateFormat(value){
    //    return DateFormat(this.CalculateDateTimeByOffset(value),'dd.mm.yyyy hh:MM:ss');
    //},
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    pay(){
      let isValid = this.formIsValid();
      if(!isValid)
        return;

      
      if(!confirm(parseFloat(this.form.amount).toFixed(2) + ' TL kredi kartından çekim yapmak istiyor musunuz?'))
      {
        return;
      }
      let model = {
          platform: 3,
          form:{
            memberId:this.MemberId
          },
          payment: {
              paymentType: 1,
              cardNumber: this.form.cardNumber,
              cardHolder: this.form.holderName,
              cardExpireDate: this.form.expiryDate,
              cardCvv: this.form.cvv,
              installment: this.form.installment,
              isThreeD: true,
              amount:this.form.amount,
              currency:3
          }
      }

      this.PAYMENTINTERNAL(model)
      .then((result)=>{
          let threeDUrl = process.env.VUE_APP_PAYMENT_API + '?q=' + result.data.paymentId;
          this.OpenClosePaymentThreeDModalModal(true,threeDUrl);
      })
      .catch(error=>{
        if(this.IsShowErrorMessage(error)){
         this.$toast.error(error,{
                 position : 'top',
                 duration : 3000
             });
         }
      });
    },
    formIsValid(){
        let amount = parseFloat(this.form.amount);
        if(amount<1 || amount>99999.99){
            alert('1₺ ile 99.999,99₺ arasında çekim yapabilirsiniz.');
            return false;
        }

        if(this.form.holderName.length<3){
            alert('Kartı sahibinin adını girmelisiniz.');
            return false;
        }

        if(this.form.cardNumber.length!=19){
            alert('Kartı numarası hatalı.');
            return false;
        }

        if(this.form.expiryDate.length!=7){
            alert('Kartın son kullanım tarihi yanlış formatta girdiniz. Ör: 12/2025');
            return false;
        }
        let expiryMonth = parseInt(this.form.expiryDate.substring(0,2),10);
        let expiryYear = parseInt(this.form.expiryDate.substring(3,7),10);

        if(expiryMonth<1 || expiryMonth>12){
            alert('Kartın son kullanım tarihini yanlış girdiniz.');
            return false;
        }
        if(expiryYear<new Date().getFullYear()){
            alert('Kartın son kullanım tarihini yanlış girdiniz.');
            return false;
        }

        this.form.cvv
        if(this.form.cvv.length<3){
            alert('Kartın güvenlik kodu hatalı');
            return false;
        }

        return true;
    },
    GetInitData(){
      this.isLoading=false;
      this.form.amount=0.00;
      this.form.holderName='';
      this.form.cardNumber='';
      this.form.expiryDate='';
      this.form.cvv='';
      this.form.installment=1;
      this.form.isLoading=false;
    },
    paymentModalClose(){
      window.$('#PaymentIFrame').attr('src',''); 
      window.$('#PaymentModal').modal('hide');
      this.form.amount=0.00;
      this.form.holderName='';
      this.form.cardNumber='';
      this.form.expiryDate='';
      this.form.cvv='';
      this.form.installment=1;
      this.form.isLoading=false;
    },
    OpenClosePaymentThreeDModalModal(value,url){
      this.paymentThreeUrl=url;
      this.paymentThreeDModalVisible=value;
    },
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData();
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  }
  
}
</script>