<template>
  <div class="modal fade" id="UpdateStartDateModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Başlangıç Tarihini Değiştir</h5>
            <button type="button" class="close" @click="CloseUpdateStartDateModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              
              <div class="col-sm-12 col-md-12 col-lg-12">
                <label>Başlangıç tarihini seçiniz.</label>
                <v-date-picker v-model="startDate" mode="date" :min-date="new Date()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="input-group">  
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                      </div>    
                        <input
                          :value="inputValue"
                          v-on="inputEvents"
                          :disabled="submitLoading"
                          class="form-control"
                        />
                    </div>    
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="UpdateStartDate()" :disabled="submitLoading">
                <span v-show="submitLoading" class="spinner-border spinner-border-sm" ></span>
                <span class="ml-2" >Değiştir</span>
            </button>
            
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../helpers/error-message-helper";
import {DateFormat} from "../helpers/date-convert-helper";
export default {
  name: 'UpdateStartDateModal',
  components: {  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    MemberId:{
      type:String,
      default:null
    },
    TrainingFileId:{
      type:String,
      default:null
    }
  },
  data() {
    return {
      startDate:new Date(),
      submitLoading:false
    }
  },
  computed:{},
  methods:{
    ...mapActions(["TRAINING_UPDATESTARTDATE"]),
      IsShowErrorMessage(value){
       return IsShowErrorMessage(value);
      },
      DateFormat(value){
       return DateFormat(value,'yyyy-mm-dd');
      },
      CloseUpdateStartDateModal(){
        window.$("#UpdateStartDateModal").modal('hide');
        this.$emit("CloseUpdateStartDateModal", false);
      },

      UpdateStartDate(){
        this.submitLoading=true;
        if(!confirm('Başlangış tarihini değiştirmek istiyor musunuz?')){
          this.submitLoading=false;
          return;
        }

        this.TRAINING_UPDATESTARTDATE({
          memberId:this.MemberId,
          trainingFileId:this.TrainingFileId,
          beginDate:this.DateFormat(this.startDate).toString()
          }
        )
        .then(()=>{
            let message = "Başlangıç tarihi başarıyla değiştirildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
            });
            this.submitLoading=false;
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.submitLoading=false;
          });
      }
  },
  watch: {
    Visible(value){
      if(value){
        this.startDate=new Date();
        window.$("#UpdateStartDateModal").modal({backdrop: 'static',keyboard: false, show: true});
      }
    }
  },
  mounted: function() {}
}
</script>