function GetGenderText(value){
    if(value===1){
        return 'Erkek';
    }else if(value===2){
        return 'Kadın'
    }else{
        return '-';
    }
}

function GetRoleText(value){
    if(value===1){
        return 'Yönetim';
    }else if(value===2){
        return 'Öğrenci Operatörü'
    }else if(value===3){
        return 'Form Operatörü'
    }else if(value===4){
        return 'Satışçı'
    }else if(value===5){
        return 'Öğretmen'
    }else{
        return '-';
    }
}

function GetFormStatus(value){
    if(value===1 || value===6){
        return 'Yeni Form';
    }else if(value===2){
        return 'İleri Tarihli Form';
    }else if(value===3){
        return 'İptal Form'
    }else if(value===4){
        return 'Eski Form'
    }else if(value===5){
        return 'Öğrenci'
    }else{
        return '-';
    }
}

function GetStatusText(value){
    if(value===0){
        return 'Pasif';
    }else if(value===1){
        return 'Aktif';
    }else{
        return '-';
    }
}

function GetTrainingFileStatus(value){
    if(value===1){
        return 'Aktif';
    }else if(value===2){
        return 'Tamamlandı';
    }else if(value===3){
        return 'Donduruldu';
    }else if(value===4){
        return 'Uzun Süre Katılmadı';
    }else if(value===5){
        return 'Eğitmen Bulunacak';
    }else{
        return '-';
    }
}

function GetCurrencyText(value){
    if(value===1){
        return 'EUR';
    }else if(value===2){
        return 'USD'
    }else if(value===3){
        return 'TL'
    }else{
        return '';
    }
}

function GetPaymentTypeText(value){
    if(value===1){
        return 'Kredi Kart';
    }else if(value===2){
        return 'Havale'
    }else if(value===3){
        return 'Nakit'
    }else if(value===4){
        return 'İade'
    }else{
        return '';
    }
}

function GetScheduleStatusText(value){
    if(value===0){
        return '';
    }else if(value===1){
        return 'Katılmadı';
    }else if(value===2){
        return 'Katıldı'
    }else if(value===3){
        return 'Ertelenedi'
    }else if(value===4){
        return ''
    }else{
        return '';
    }
}

function GetLessonPuanText(value){
    if(value===1){
        return 'E';
    }else if(value===2){
        return 'D';
    }else if(value===3){
        return 'C'
    }else if(value===4){
        return 'B'
    }else if(value===5){
        return 'A'
    }else{
        return '';
    }
}
function GetEnglishLevelText(value){
    if(value===0){
        return 'No Level';
    }else if(value===1){
        return 'A1– Başlangıç (Beginner)';
    }else if(value===2){
        return 'A2- Temel (Elementary)'
    }else if(value===3){
        return 'B1- Orta seviye öncesi (Pre-Intermediate)'
    }else if(value===4){
        return 'B2- Orta seviye (Intermediate)'
    }else if(value===5){
        return 'C1- Orta seviyenin üstü (Upper-Indermediate)'
    }else if(value===6){
        return 'C2- İleri (Advanced)'
    }else{
        return '';
    }
}

function GetTaskCategoryText(value){
    if(value===0){
        return 'Özel hatırlatma / görev';
    }else if(value===1){
        return 'Skype hatırlatma';
    }else if(value===2){
        return 'İlk ders memnuniyeti ve ekran tanıtımı';
    }else if(value===3){
        return 'Paket bitiminde tekrar kayıt';
    }else if(value===4){
        return 'Ders dondruma sonrası başlangıç';
    }else if(value===5){
        return '5 ders devamsızlık';
    }else if(value===10){
        return 'Referans araması';
    }else if(value===15||value===16||value===17){
        return 'Paket yükseltme';
    }else if(value===20){
        return 'Eğitime Başlayacak Kontrol';
    }else if(value===21){
        return 'İlk Ders';
    }else if(value===22){
        return 'Memnuniyet';
    }else if(value===23){
        return 'Yorum';
    }else if(value===24){
        return 'Devamsızlık';
    }else if(value===25 || value===26){
        return 'Paket Satışı';
    }else if(value===30 || value===31 || value===32 || value===33){
        return 'Paket Uzatma';
    }else{
        return '';
    }
}

function GetNotificationViewZone(value){
    if(value===1){
        return 'Student Panel Header';
    }else if(value===2){
        return 'Student Panel Dashboard'
    }else if(value===5){
        return 'Student Panel Modal'
    }else if(value===10){
        return 'Teacher Panel Modal'
    }else{
        return '';
    }
}

function GetActionText(value){
    if(value==='interested'){
        return 'İlgileniyorum';
    }else if(value==='notinterested'){
        return 'İlgilenmiyorum'
    }else{
        return '';
    }
}


export {
    GetGenderText,
    GetRoleText,
    GetFormStatus,
    GetStatusText,
    GetTrainingFileStatus,
    GetCurrencyText,
    GetPaymentTypeText,
    GetScheduleStatusText,
    GetLessonPuanText,
    GetEnglishLevelText,
    GetTaskCategoryText,
    GetNotificationViewZone,
    GetActionText
}