<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Kullanıcı İşlemleri</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item active">Kullanıcı İşlemleri</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <button type="button" class="btn btn-sm btn-default" data-toggle="modal" data-target="#CreateUserModal">
                  <i class="nav-icon fas fa-user"></i>
                  Kullanıcı Oluştur
                </button>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>Ad / Soyad</th>
                      <th width="100">Kullanıcı Türü</th>
                      <th width="70">Cinsiyet</th>
                      <th width="70">Durum</th>
                      <th width="200">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr><td colspan="5" class="text-center" v-show="dataLoading">Yükleniyor...</td></tr>
                    <tr v-for="item in items" :key="item.id">
                      <td><a href="#javascript:;" @click="OpenCloseUserProfileModal(true,item.id)" class="text-capitalize">{{item.name}} {{item.surname}}</a></td>
                      <td>{{GetRole(item.role)}}</td>
                      <td>{{GetGender(item.gender)}}</td>
                      <td>
                        <span class="badge bg-success" v-if="item.status==1">Aktif</span>
                        <span class="badge bg-danger" v-else-if="item.status==0">Pasif</span>
                      </td>
                      <td>
                          <button type="button" class="btn btn-sm btn-default mr-1" @click="GetUser(item.id)" :disabled="statusBtnDisabled" >
                            <i class="fas fa-edit"></i> Düzelt
                          </button>
                          <button type="button" class="btn btn-sm btn-default mr-1" v-if="item.status==1" @click="ChangeUserStatus(item.id,0)" :disabled="statusBtnDisabled"> 
                            Pasif Yap
                          </button>
                          <button type="button" class="btn btn-sm btn-default mr-1" v-else-if="item.status==0" @click="ChangeUserStatus(item.id,1)" :disabled="statusBtnDisabled">
                            Aktif Yap
                          </button>
                          <button type="button" class="btn btn-sm btn-default" @click="ChangePassword(item.id)" :disabled="statusBtnDisabled" >
                            <i class="fas fa-key"></i> Yeni Şifre Oluştur
                          </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <!-- /.card -->
              
                <div class="card-footer clearfix" v-show="totalPages>0">
                  <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item" v-show="(currentPage-1)>0"><a class="page-link" href="#" @click="GetUsers(currentPage-1)">&laquo;</a></li>
                    <li v-for="page in totalPages" :key="page" class="page-item" :class="{'active':page==currentPage}"><a class="page-link" href="#" @click="GetUsers(page)">{{page}}</a></li>
                    <li class="page-item" v-show="(currentPage+1)<=totalPages"><a class="page-link" href="#" @click="GetUsers(currentPage+1)">&raquo;</a></li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->

    <!-- Modal -->
    <div class="modal fade" id="CreateUserModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Yeni Kullanıcı</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <Form @submit="HandleUserAdd" :validation-schema="schema" ref="NewUserForm">
            <div class="modal-body">
              <div class="row">
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label" for="role">Kullanıcı Türü</label>
                        <div class="col-sm-9">
                          <Field class="form-control" name="role" id="role" as="select">
                            <option value=""></option>
                            <option value="1">Yönetim</option>
                            <option value="2">Öğrenci Operatörü</option>
                            <option value="3">Form Operatörü</option>
                            <option value="4">Satışçı</option>
                            <option value="5">Öğretmen</option>
                          </Field>
                          <small><ErrorMessage name="role" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"  for="name">Ad</label>
                        <div class="col-sm-9">
                          <Field type="text" class="form-control" id="name" name="name"/>
                          <small><ErrorMessage name="name" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"  for="surname">Soyad</label>
                        <div class="col-sm-9">
                          <Field type="text" class="form-control" id="surname" name="surname"/>
                          <small><ErrorMessage name="surname" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"  for="birthday">Doğum Tarihi</label>
                        <div class="col-sm-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="far fa-calendar-alt"></i></span>
                            </div>
                            <Field type="text" class="form-control" name="birthday" v-maska="'####-##-##'" />
                          </div>
                          <small><ErrorMessage name="birthday" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"  for="role">Cinsiyet</label>
                        <div class="col-sm-9">
                          <Field class="form-control" name="gender" id="gender" as="select">
                            <option value=""></option>
                            <option value="1">Erkek</option>
                            <option value="2">Kadın</option>
                          </Field>
                          <small><ErrorMessage name="gender" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"  for="phoneNumber">Telefon No</label>
                        <div class="col-sm-9">
                          <Field type="text" class="form-control" id="phoneNumber" name="phoneNumber"/>
                          <small><ErrorMessage name="phoneNumber" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"  for="eMailAddress">E-Posta Adresi</label>
                        <div class="col-sm-9">  
                          <Field type="text" class="form-control" id="eMailAddress" name="eMailAddress"/>
                          <small><ErrorMessage name="eMailAddress" class="error-feedback"/></small>
                        </div>
                      </div>
                    
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label" for="username">Kullanıcı Adı</label>
                        <div class="col-sm-9">
                          <Field type="text" class="form-control" name="username"/>
                          <small><ErrorMessage name="username" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"  for="password">Şifre</label>
                        <div class="col-sm-9">
                          <Field type="password" class="form-control" name="password"/>
                          <small><ErrorMessage name="password" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"  for="status">Durum</label>
                        <div class="col-sm-9">
                          <Field class="form-control" name="status" as="select">
                            <option value=""></option>
                            <option value="1">Aktif</option>
                            <option value="0">Pasif</option>
                          </Field>
                          <small><ErrorMessage name="status" class="error-feedback"/></small>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="submitLoading">
                <span v-show="submitLoading" class="spinner-border spinner-border-sm"></span>
                <span class="ml-2" >Kaydet</span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="EditUserModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Kullanıcı Düzelt</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <Form @submit="HandleUserUpdate" :validation-schema="schemaEdit" ref="EditUserForm">
            <div class="modal-body">
              <div class="row">
                    <div class="col-12">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label" for="edit_role">Kullanıcı Türü</label>
                        <div class="col-sm-9">
                          <Field class="form-control" name="edit_role" id="edit_role" as="select">
                            <option value=""></option>
                            <option value="1">Yönetim</option>
                            <option value="2">Öğrenci Operatörü</option>
                            <option value="3">Form Operatörü</option>
                            <option value="4">Satışçı</option>
                          </Field>
                          <small><ErrorMessage name="edit_role" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label" for="edit_name">Ad</label>
                        <div class="col-sm-9">
                          <Field type="text" class="form-control" id="edit_name" name="edit_name"/>
                          <small><ErrorMessage name="edit_name" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"  for="edit_surname">Soyad</label>
                        <div class="col-sm-9">
                          <Field type="text" class="form-control" id="edit_surname" name="edit_surname"/>
                          <small><ErrorMessage name="edit_surname" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"  for="edit_birthday">Doğum Tarihi</label>
                        <div class="col-sm-9">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="far fa-calendar-alt"></i></span>
                            </div>
                            <Field type="text" class="form-control" name="edit_birthday" id="edit_birthday" v-maska="'####-##-##'" />
                          </div>
                          <small><ErrorMessage name="edit_birthday" class="error-feedback"/></small>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"  for="edit_gender">Cinsiyet</label>
                        <div class="col-sm-9">
                          <Field class="form-control" name="edit_gender" id="edit_gender" as="select">
                            <option value=""></option>
                            <option value="1">Erkek</option>
                            <option value="2">Kadın</option>
                          </Field>
                          <small><ErrorMessage name="edit_gender" class="error-feedback"/></small>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="submitUpdateLoading">
                <span v-show="submitUpdateLoading" class="spinner-border spinner-border-sm"></span>
                <span class="ml-2" >Kaydet</span>
              </button>
            </div>
            <Field type="hidden" name="edit_id" id="edit_id" />
          </Form>
        </div>
      </div>
    </div>
    
    <UserProfileModal :Visible="userProfileModalVisible" :UserId="userProfileModalUserId" @CloseUserProfileModal="OpenCloseUserProfileModal($event,null)"></UserProfileModal>
</template>

<script>
import {mapActions} from "vuex"
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import {GetGenderText,GetRoleText} from "../../../helpers/enum-convert-helper"
import {DateFormat} from "../../../helpers/date-convert-helper"
import UserProfileModal from '../../../components/UserProfile/UserProfileModal.vue';
export default {
  name: "User",
  components: {
    Form,
    Field,
    ErrorMessage,
    UserProfileModal
  },
  data(){
    const schema = yup.object().shape({
      role: yup.string().required("Kullanıcı türünü seçiniz!"),
      name: yup.string().required("Ad giriniz!"),
      surname: yup.string().required("Soyad giriniz!"),
      birthday: yup.string().required("Doğum tarihini giriniz!"),
      gender: yup.string().required("Cinsiyet seçiniz!"),
      username: yup.string().required("Kullanıcı adını giriniz!"),
      password: yup.string().required("Şifre giriniz!"),
      eMailAddress: yup.string().required("E-posta adresi giriniz!"),
      phoneNumber: yup.string().required("Telefon numarası giriniz!"),
      status: yup.string().required("Kullanıcı durumunu seçiniz!"),
    });

    const schemaEdit =  yup.object().shape({
      edit_role: yup.string().required("Kullanıcı türünü seçiniz!"),
      edit_name: yup.string().required("Ad giriniz!"),
      edit_surname: yup.string().required("Soyad giriniz!"),
      edit_birthday: yup.string().required("Doğum tarihini giriniz!"),
      edit_gender: yup.string().required("Cinsiyet seçiniz!"),
      edit_id:yup.string()
    });


    return{
      currentPage:0,
      items:[],
      pageSize:0,
      totalItems:0,
      totalPages:0,
      dataLoading:false,
      statusBtnDisabled:false,
      submitLoading: false,
      submitUpdateLoading:false,
      schema,
      schemaEdit,
      userProfileModalVisible:false,
      userProfileModalUserId:null,
    }
  },
  computed: {
    isShowPagination(){
        return this.totalPages>1;
    }
  },
  created() {
    this.GetUsers(1);
  },
  methods: {
    ...mapActions(["USER_LIST","DO_ACTIVE","DO_INACTIVE","CREATE","GET_USER","UPDATE","CHANGE_PASSWORD"]),
    GetGender(value){
      return GetGenderText(value);
    },
    GetRole(value){
      return GetRoleText(value);
    },
    ChangeUserStatus(userId,status){
        this.statusBtnDisabled=true;
        if(status==1){
          this.DO_ACTIVE({userId})
          .then(()=>{
              this.items.forEach(item => {
                if(item.id==userId){
                  item.status = status;
                }
              });
              this.statusBtnDisabled=false;
          })
          .catch(error=>{
              let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
              this.$toast.error(message,{
                position : 'top',
                duration:3000
              });
              this.statusBtnDisabled=false;
          });
        }else{
            this.DO_INACTIVE({userId})
            .then(()=>{
                this.items.forEach(item => {
                  if(item.id==userId){
                    item.status = status;
                  }
                });
                this.statusBtnDisabled=false;
            })
            .catch(error=>{
                let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                this.$toast.error(message,{
                  position : 'top',
                  duration:3000
                });
                this.statusBtnDisabled=false;
            });
        }
    },
    GetUsers(page) {
      this.dataLoading=true;
      if(!page) {  page=1; }
      
      this.USER_LIST({page})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.dataLoading=false;
        });
    },
    GetUser(userId) {
      this.GET_USER({userId})
      .then((res)=>{
            this.$refs.EditUserForm.setFieldValue('edit_id',res.data.id);
            this.$refs.EditUserForm.setFieldValue('edit_role',res.data.role);
            this.$refs.EditUserForm.setFieldValue('edit_name',res.data.name);
            this.$refs.EditUserForm.setFieldValue('edit_surname',res.data.surname);
            this.$refs.EditUserForm.setFieldValue('edit_birthday',DateFormat(res.data.birthday,'yyyy-mm-dd'));
            this.$refs.EditUserForm.setFieldValue('edit_gender',res.data.gender);
            window.$("#EditUserModal").modal('show');
        }).catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.dataLoading=false;
        });
    },
    HandleUserAdd(form) {
      this.submitLoading = true;

      let role = parseInt(form.role);
      let name = form.name;
      let surname = form.surname;
      let birthday = new Date(form.birthday).toISOString();
      let gender = parseInt(form.gender);
      let username = form.username;
      let password = form.password;
      let eMailAddress = form.eMailAddress;
      let phoneNumber = form.phoneNumber;
      let status = parseInt(form.status);

      this.CREATE({
        role:role,
        name:name,
        surname:surname,
        birthday:birthday,
        gender:gender,
        username:username,
        password:password,
        eMailAddress:eMailAddress,
        phoneNumber:phoneNumber,
        status:status
        }
        )
      .then(()=>{
          window.$("#CreateUserModal").modal('hide');
          this.ClearForm();
          let message = "Kullanıcı başarıyla oluşturuldu.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.GetUsers(0);

      })
      .catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.submitLoading=false;
        });
    },
    ClearForm(){
       this.$refs.NewUserForm.setFieldValue('role','');
        this.$refs.NewUserForm.setFieldValue('name','');
        this.$refs.NewUserForm.setFieldValue('surname','');
        this.$refs.NewUserForm.setFieldValue('birthday','');
        this.$refs.NewUserForm.setFieldValue('gender','');
        this.$refs.NewUserForm.setFieldValue('username','');
        this.$refs.NewUserForm.setFieldValue('password','');
        this.$refs.NewUserForm.setFieldValue('eMailAddress','');
        this.$refs.NewUserForm.setFieldValue('phoneNumber','');
        this.$refs.NewUserForm.setFieldValue('status','');
        this.submitLoading=false;
    },
    HandleUserUpdate(form) {
      this.submitUpdateLoading = true;
      let id = form.edit_id;
      let role = parseInt(form.edit_role);
      let name = form.edit_name;
      let surname = form.edit_surname;
      let birthday = new Date(form.edit_birthday).toISOString();
      let gender = parseInt(form.edit_gender);

      this.UPDATE({
        id:id,
        role:role,
        name:name,
        surname:surname,
        birthday:birthday,
        gender:gender
        }
       )
      .then(()=>{
          window.$("#EditUserModal").modal('hide');
          this.ClearEditForm();
          let message = "Kullanıcı başarıyla güncellendi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.GetUsers(0);

      })
      .catch(error=>{
            let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            this.$toast.error(message,{
                position : 'top',
                duration : 3000
            });
            this.submitUpdateLoading=false;
        });
    },
    ClearEditForm(){
        this.$refs.EditUserForm.setFieldValue('id','');
        this.$refs.EditUserForm.setFieldValue('role','');
        this.$refs.EditUserForm.setFieldValue('name','');
        this.$refs.EditUserForm.setFieldValue('surname','');
        this.$refs.EditUserForm.setFieldValue('birthday','');
        this.$refs.EditUserForm.setFieldValue('gender','');
        this.submitUpdateLoading=false;
    },
    ChangePassword(userId){
      this.statusBtnDisabled=true;
      let result = confirm('Kullanıcıya yeni şifre oluşturmak istiyor musunuz?');
      if(result){
          this.CHANGE_PASSWORD({userId})
          .then(()=>{
              let message = "Şifre başarıyla güncellendi.";
              this.$toast.success(message,{
                    position : 'top',
                    duration : 3000
                });
              this.statusBtnDisabled=false;
          })
          .catch(error=>{
              let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
              this.$toast.error(message,{
                position : 'top',
                duration:3000
              });
              this.statusBtnDisabled=false;
          });
      }else{
        this.statusBtnDisabled=false;
      }
    },
    OpenCloseUserProfileModal(value,userId){
      this.userProfileModalVisible=value;
      this.userProfileModalUserId=userId;
    },
  },
};
</script>
<style scoped>
.error-feedback { color: red;}
</style>