<template>
  <div style="min-height: 500px;" class="tab-pane fade p-2" :class="showTab">
    <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
    <div v-else>
      <div v-if="payments.length==0" class="text-center mt-5">Herhangi bir ödeme bulunamadı.</div>
      <div class="overflow-auto" style="height:500px">
        <div class="row border-bottom p-1 bt-1" v-for="(p,index) in payments" :key="p">
          <div class="col-1 text-center">
            <i class="fas fa-lg mt-2" :class="{'fa-check text-success':p.isSuccess,'fa-times text-danger':!p.isSuccess}"></i>
          </div>
          <div class="col-5">
            <div>
              <span class="badge badge-primary" v-html="p.paymentType==1?'Kredi Kartı':'Havale'"></span>
              <span class="ml-2" v-html="p.installment<2?'Tek Çekim':p.installment+' Taksit'"></span>
            </div>
            {{ModalDateFormat(p.creationDate)}}
            <div><small>{{ p.paymentId }}</small></div>
          </div>
          <div class="col-2 h4 text-center mt-2">{{p.amount.toFixed(2)}}₺</div>
          <div class="col-4 text-right">
            <button class="btn btn-default mr-1" @click="ModalOpenInTab('BillModal',index)"><i class="fas fa-receipt"></i></button>
            <button class="btn btn-default mr-1" @click="ModalOpenInTab('ContractModal',index)"><i class="fas fa-file-alt"></i></button>
            <button class="btn btn-default mr-1" @click="ModalOpenInTab('ProductModal',index)"><i class="fas fa-boxes"></i></button>
            <button class="btn btn-default" @click="ModalOpenInTab('PaymentLogModal',index)"><i class="fas fa-fire-alt"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal fade" tabindex="-1" role="dialog" id="BillModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Fatura Bilgisi</h5>
          <button type="button" class="close" @click="ModalCloseInTab('BillModal')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Fatura Başlığı</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" readonly v-model="GetBillForm.invoiceTitle">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Telefon</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" readonly v-model="GetBillForm.invoicePhoneNumber">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Adres</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" readonly v-model="GetBillForm.invoiceAddress">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Vergi Numarası</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" readonly  v-model="GetBillForm.invoiceTaxNumber">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Vergi Dairesi</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" readonly  v-model="GetBillForm.invoiceTaxOffice">
              </div>
            </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Send message</button>
        </div> -->
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" id="ContractModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Sözleşme</h5>
          <button type="button" class="close" @click="ModalCloseInTab('ContractModal')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body overflow-auto" style="max-height: 500px;" v-html="GetContract">
            
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" id="ProductModal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Satın Alınan Ürünler</h5>
          <button type="button" class="close" @click="ModalCloseInTab('ProductModal')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
            <table class="table table-hover">
              <thead>
                <tr>
                  <!-- <th class="text-center">Durum</th> -->
                  <th class="text-center">Eğitim Paketi</th>
                  <th class="text-center">Paket Ücreti</th>
                  <th class="text-center">Vergi</th>
                  <th class="text-center">Vade Farkı</th>
                  <th class="text-center">İndirim</th>
                  <th class="text-center">Toplam</th>
                  <th class="text-center">İndirim Kodu</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="pr in GetProducts" :key="pr">
                  <!-- <td class="text-center"></td> -->
                  <td class="text-center">{{pr.month}} Ay {{pr.duration}} Dakika (Haftada {{pr.weekDay}} Gün)</td>
                  <td class="text-center">{{pr.basePrice.toFixed(2)}}₺ </td>
                  <td class="text-center">{{pr.taxPrice.toFixed(2)}}₺ (%{{pr.taxRate.toFixed(0)}})</td>
                  <td class="text-center">{{pr.lateChargePrice.toFixed(2)}}₺</td>
                  <td class="text-center">{{pr.discountPrice.toFixed(2)}}₺</td>
                  <td class="text-center">{{pr.totalPrice.toFixed(2)}}₺</td>
                  <td class="text-center">&nbsp;</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1" role="dialog" id="PaymentLogModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ödeme Logları</h5>
          <button type="button" class="close" @click="ModalCloseInTab('PaymentLogModal')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="text-center">Tarih</th>
                  <th class="text-center">Method</th>
                  <th class="text-center">Durum</th>
                  <th class="text-center">Hata Mesajı</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="t in GetPaymentLogs" :key="t">
                  <td class="text-center">{{ModalDateFormat(t.responseDate)}}</td>
                  <td class="text-center">{{t.method}}</td>
                  <td class="text-center" v-html="t.status?'Başarılı'+ (t.method==='TP_WMD_Pay'?' ('+PaymentResponseParse(t).authCode+')':''):'Başarısız'"></td>
                  <td class="text-center">{{ t.errorMessge }}<div>{{ PaymentResponseParse(t).errorMessage }}</div></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>

</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
import {DateFormat,CalculateDateTime} from "../../helpers/date-convert-helper";
export default {
  name: 'TabPayment',
  components: { 
    },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      isLoading:true,
      payments:[],
      selectedPaymentIndex:0
    }
  },
  computed:{
    GetBillForm(){
      let model={
        invoiceTitle:'',
        invoicePhoneNumber:'',
        invoiceAddress:'',
        invoiceTaxOffice:'',
        invoiceTaxNumber:''
      };

      if(this.payments.length>0){
        model.invoiceTitle=this.payments[this.selectedPaymentIndex].invoiceTitle;
        model.invoicePhoneNumber=this.payments[this.selectedPaymentIndex].invoicePhoneNumber;
        model.invoiceAddress=this.payments[this.selectedPaymentIndex].invoiceAddress;
        model.invoiceTaxOffice=this.payments[this.selectedPaymentIndex].invoiceTaxOffice;
        model.invoiceTaxNumber=this.payments[this.selectedPaymentIndex].invoiceTaxNumber;
      }

      return model;
    },
    GetContract(){
      if(this.payments.length>0){
        return this.payments[this.selectedPaymentIndex].distanceSellingContract;
      }
      return '';
    },
    GetProducts(){
      if(this.payments.length>0){
        return this.payments[this.selectedPaymentIndex].purchasedProducts;
      }
      return [];
    },
    GetPaymentLogs(){
      if(this.payments.length>0){
        return this.payments[this.selectedPaymentIndex].paymentTransactions;
      }
      return [];
    }

  },
  methods:{
    ...mapActions(["MEMBERPROFILE_TABSACCOUNTINGPAYMENTINIT"]),
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    ModalDateFormat(value){
        return DateFormat(this.CalculateDateTimeByOffset(value),'dd.mm.yyyy hh:MM:ss');
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    PaymentResponseParse(value){
      let model = {
        authCode:'',
        errorMessage:''
      };
      if(value){
        if(value.method=='TP_WMD_Pay'){
          let response = JSON.parse(value.response);
          model.authCode=response.Bank_AuthCode;
          model.errorMessage=decodeURIComponent(response.Sonuc_Ack);
        }
      }
      return model;
    },
    GetInitData(){
      this.isLoading=true;
      this.MEMBERPROFILE_TABSACCOUNTINGPAYMENTINIT({id:this.MemberId})
        .then((res)=>{
          this.isLoading=false;
          this.payments = res.data;
        })
        .catch(error=>{
            this.isLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
          });
    },
    ModalOpenInTab(value,index){
      this.selectedPaymentIndex=index;
      window.$("#"+value).modal({backdrop: 'static',keyboard: false, show: true});
    },
    ModalCloseInTab(value){
      window.$("#"+value).modal('hide');
    },
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData();
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  }
  
}
</script>