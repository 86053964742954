<template>
  <body class="hold-transition login-page">
  <div class="login-box">
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <img src="../assets/logo-text.jpg" alt="Lengedu" style="width:250px;">
      </div>
      <div class="card-body">
        <p class="login-box-msg">Oturum açmak için giriş yapınız.</p>
        <Form @submit="handleLogin" :validation-schema="schema">
          <div class="mb-3" >
            <div class="form-group mb-0">
              <Field name="username" type="text" class="form-control" />
            </div>
            <small><ErrorMessage name="username" class="error-feedback" /></small>
          </div>
          <div class="mb-3">
          <div class="input-group mb-1">
            <Field name="password" type="password" class="form-control" />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <small><ErrorMessage name="password" class="error-feedback" /></small>
          </div>
          <div class="row mb-3">
            
            <div class="col-7">
              <router-link :to="{path:'forgot-password'}">
              Şifremi Unuttum
              </router-link>
            </div>
            <div class="col-5">
              <button class="btn btn-primary btn-block" :disabled="loading">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <span class="ml-2" >Giriş Yap</span>
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  </body>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Kullanıcı adı giriniz!"),
      password: yup.string().required("Şifre giriniz!"),
    });

    return {
      loading: false,
      schema,
    };
  },
  computed: {
    ...mapGetters(["TOKEN"]),
    getToken(){
      return this.TOKEN;
    }
  },
  methods: {
    ...mapActions(["LOGIN"]),
    handleLogin(form) {
      this.loading = true;
      var username = form.username;
      var password = form.password;
      this.LOGIN({username,password})
      .then(()=>{
          this.$router.push("/");
      })
      .catch((error)=>{
          this.loading = false;
          this.$toast.error(error,{
            position : 'top',
            duration:3000
          });
      })
    },
  },
  created() {
    if (this.getToken) {
      this.$router.push("/");
    }
  },
  // beforeCreate:function(){
  //   this.$store.commit('initialiseStore');
  // }
};
</script>
<style scoped>
  .error-feedback {
    color: red;
  }
</style>