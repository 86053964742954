<template>
  <div class="modal fade" v-bind:id="ModalName" tabindex="2" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Şifre Değişikliği</h5>
            <button type="button" class="close" @click="CloseMemberChangePasswordModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-warning text-sm">Yapacağınız bu işlem öğrencinin mevcut şifresinin değişmesine yol açacaktır.</div>
            <div class="row mb-3">
              <div class="col-12">
                <label>Yeni Şifre</label>
                <input type="text" class="form-control" v-model="password" minlength="3" maxlength="8">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" v-model="isSendMail" v-bind:id="'SendMail'"/>
                  <label class="form-check-label" v-bind:for="'SendMail'">Öğrenciyi e-postayla bilgilendir.</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="ChangePassword()" :disabled="submitLoading">
                <span v-show="submitLoading" class="spinner-border spinner-border-sm" ></span>
                <span class="ml-2">Kaydet</span>
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";

export default {
  name: 'MemberChangePasswordModal',
  emits:['OpenCloseMemberChangePasswordModal'],
  components: {  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    MemberId:{
      type:String,
      default:null
    },
    ModalName:{
      type:String,
      default:'MemberChangePasswordModal'
    }
    
  },
  data() {
    return {
      isSendMail:false,
      password:'',
      submitLoading:false,
    }
  },
  computed:{
    
  },
  methods:{
    ...mapActions(["MEMBER_MEMBERCHANGEPASSWORDFORPANEL"]),
      IsShowErrorMessage(value){
        return IsShowErrorMessage(value);
      },
      CloseMemberChangePasswordModal(){
        window.$("#"+this.ModalName).modal('hide');
        this.$emit("OpenCloseMemberChangePasswordModal", false);
      },
      ChangePassword(){
         if(this.password.length<3 || this.password.length>8){
           alert('Şifre en az 4 en fazla 8 karakterden oluşmalıdır.');
           this.submitLoading=false;
           return;
         }
        
        if(!confirm('Bu işlemi yapmak istiyor musunuz?'))
        {
          this.submitLoading=false;
          return;
        }

        this.MEMBER_MEMBERCHANGEPASSWORDFORPANEL({
          memberId:this.MemberId,
          password:this.password,
          isSendEmail:this.isSendMail
          }
        )
        .then((res)=>{
            let message = res.data.description;
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
            });
            this.submitLoading=false;
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.submitLoading=false;
        });
      }
  },
  watch: {
    Visible(value){
      if(value){
        this.isSendMail=false,
        this.password='';
        this.submitLoading=false;
        window.$("#"+this.ModalName).modal({backdrop: 'static',keyboard: false, show: true});
      }
    }
  },
  mounted: function() {
  },
  
  
}
</script>