<template>
  <body class="hold-transition login-page">
  <div class="login-box">
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <img src="../assets/logo-text.jpg" alt="Lengedu" style="width:250px;">
      </div>
      <div class="card-body">
        <p class="login-box-msg">Yeni şifrenizden sadece bir adım uzaktasınız, şifrenizi şimdi belirleyin.</p>
        <Form @submit="handleRecoverPassword" :validation-schema="schema">
          <div class="mb-3">
            <div class="input-group mb-1">
              <Field name="password" type="password" class="form-control" />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <small><ErrorMessage name="password" class="error-feedback" /></small>
          </div>

          <div class="mb-3">
            <div class="input-group mb-1">
              <Field name="passwordConfirmation" type="password" class="form-control" />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <small><ErrorMessage name="passwordConfirmation" class="error-feedback" /></small>
          </div>
          <div class="row mb-3">
            <div class="col-12 mb-2">
              <button class="btn btn-primary btn-block" :disabled="loading">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              <span class="ml-2" >Şifre Değiştir</span>
            </button>
            </div>
            <div class="col-12">
              <router-link :to="{path:'/login'}">
              Giriş
              </router-link>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  </body>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "RecoverPassword",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    const schema = yup.object().shape({
      password: yup.string().required("Şifre giriniz!"),
      passwordConfirmation : yup.string().required("Şifrenizi tekrarlayınız.").oneOf([yup.ref('password'),null], 'Şifre aynı olmalıdır.')
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    ...mapGetters(["TOKEN"]),
    getToken(){
      return this.TOKEN;
    }
  },
  created() {
    if (this.getToken) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions(["RECOVER_PASSWORD"]),
    handleRecoverPassword(form) {
    this.loading = true;
    let secureCode = this.$route.params.q;
    let password = form.password;
    this.RECOVER_PASSWORD({secureCode,password})
    .then((res)=>{
          this.$toast.success(res.data.description,{
              position : 'top',
              duration:3000
          });
          this.loading = false;
      })
      .catch((error)=>{
          this.loading = false;
          this.$toast.error(error,{
            position : 'top',
            duration:3000
          });
      });
    },
  },
};
</script>
<style scoped>
.error-feedback {
  color: red;
}
</style>