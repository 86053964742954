<template>
  <footer class="main-footer">
    <!-- To the right -->
    <!-- <div class="float-right d-none d-sm-inline">
      Anything you want
    </div> -->
    <!-- Default to the left -->
    <strong>Copyright &copy; 2021-{{GetYear()}} <a href="https://lengedu.com">Lengedu</a>.</strong> All rights reserved.
  </footer>
  <TaskTransactionModal :Visible="taskTransactionModalVisible" :TaskId="taskModal.taskId" :Category="taskModal.category" :Content="taskModal.content" :Member="taskModal.member" @CloseTaskModal="OpenCloseTaskTransactionModal($event,0,0,null,null)"></TaskTransactionModal>
</template>

<script>
import {mapActions} from "vuex";
import {DateFormat,CalculateDateTime} from "../helpers/date-convert-helper";
import {GetTaskCategoryText} from "../helpers/enum-convert-helper.js";
import TaskTransactionModal from '../components/TaskTransactionModal.vue';
export default {
  name: "Footer",
  components: {
    TaskTransactionModal
  },
  data(){
    return{
      minuteTimer:null,
      checkTaskCount:0,
      tasks:[],
      taskModal:{
        taskId:0,
        category:0,
        content:'',
        member:''
      },
      taskTransactionModalVisible:false,
    }
  },
  methods:{
    ...mapActions(["GET_REMINDERTASKS"]),
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    GetNowDateUTC(){
      return DateFormat(new Date(),'yyyy-mm-dd hh:MM:ss',true);
    },
    GetTaskTimeInDate(value){
      return DateFormat(value,'hh MM');
    },
    TaskCategory(value){
      return GetTaskCategoryText(value);
    },
    GetYear(){
        return new Date().getFullYear();
    },
    OpenCloseTaskTransactionModal(value,taskId,category,content,member){
      this.taskModal.taskId=taskId;
      this.taskModal.category=category;
      this.taskModal.content=content;
      this.taskModal.member=member;
      this.taskTransactionModalVisible=value;
      
    },
    GetTaks(){
      this.GET_REMINDERTASKS()
        .then((res)=>{
          for (let i = 0; i < res.data.length; i++) {
            let find = this.tasks.find(x=>x.hashCode===res.data[i].hashCode);
            if(!find){
              this.tasks.push({
                id:res.data[i].id,
                category:res.data[i].category,
                member:res.data[i].member,
                content:res.data[i].content,
                taskDate:res.data[i].taskDate,
                isShow:false,
                hashCode:res.data[i].hashCode
              });
            }
          }
        })
        .catch(()=>{});
    },
    CheckTaskTime(){
      let mainThis = this;
      let t = new Date(mainThis.GetNowDateUTC());
      let startDate = t.setSeconds(t.getSeconds());
      let finisDate = t.setSeconds(t.getSeconds()+10);
      if(mainThis.tasks.length>0){
          let taskTime;
          let toastMessage='';
          for (let i = 0; i < mainThis.tasks.length; i++) {
              taskTime = new Date(mainThis.tasks[i].taskDate).getTime();
              if(parseInt(taskTime,10)>=parseInt(startDate,10) && parseInt(taskTime,10)<=parseInt(finisDate,10) && !mainThis.tasks[i].isShow)
              {
                mainThis.tasks[i].isShow=true;

                let taskTimeText = mainThis.GetTaskTimeInDate(mainThis.CalculateDateTimeByOffset(mainThis.tasks[i].taskDate));
                toastMessage = "<span style='font-size:20px; font-weight:bold;'>"+taskTimeText+"</span>";
                
                if(mainThis.tasks[i].member!=null) 
                {
                  toastMessage += " - "+mainThis.tasks[i].member;
                }
                if(mainThis.tasks[i].category>0){
                  toastMessage += "<div><small>"+mainThis.TaskCategory(mainThis.tasks[i].category)+"</small></div>";
                }
                
                if(mainThis.tasks[i].content!=''){
                  toastMessage += "<div>"+mainThis.tasks[i].content+"</div>";
                }
                mainThis.$toast.info(toastMessage,{
                    position : 'bottom-right',
                    duration : 0,
                    onclick:function(){
                      mainThis.OpenCloseTaskTransactionModal(true,mainThis.tasks[i].id,mainThis.tasks[i].category,mainThis.tasks[i].content,mainThis.tasks[i].member);
                    }
                });
              }
          }
      }

      if(mainThis.checkTaskCount>=60)
      {
        mainThis.GetTaks();
        mainThis.checkTaskCount=0;
      }else
      {
        mainThis.checkTaskCount++;
      }
    },
    MinuteTimerStart(){
      let mainThis = this;
      mainThis.CheckTaskTime();
      this.minuteTimer = setInterval(function(){
        mainThis.CheckTaskTime();
      },1000);
    }
  },
  mounted:function(){
    this.GetTaks();
    if(this.minuteTimer==null){
      this.MinuteTimerStart();
    }
  }
};
</script>