<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Form İşlemleri</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item active">Form İşlemleri</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-8">
                      <button class="btn btn-success btn-sm mr-1 float-left" @click="OpenCreateFormModal()" :disabled="dataLoading">Form Oluştur</button>
                      <div class="float-left mr-1" style="width:200px" v-if="saleUserVisible">
                        <select class="form-control form-control-sm float-left" v-model="mainListSaleUserId" :disabled="dataLoading" @change="MainListChange()">
                          <option value="" selected>Tüm Satışçılar</option>
                          <option v-for="user in operationUsers.filter(x=>x.role===4)" :key="user" :value="user.id">{{user.fullName}}</option>
                        </select>
                      </div>
                      <div class="float-left mr-1" style="width:160px">
                        <select class="form-control form-control-sm float-left" v-model="mainListType" :disabled="dataLoading" @change="MainListChange()">
                          <option value="" selected></option>
                          <option value="new">Yeni Formlar</option>
                          <option value="waiting">Bekleyen Formlar</option>
                          <option value="prepare">Gelecekteki Formlar</option>
                          <option value="cancel">İptal Formlar</option>
                          <option value="returnedcancellationform">Dağıtılan İptal Formlar</option>
                          <!--<option value="old">Eski Formlar</option>
                          <option value="prepare">Gelecekteki Formlar</option> -->
                        </select>
                      </div>
                      <div class="float-left mr-1" style="width:200px" v-if="cancellationOptionVisible">
                        <select class="form-control form-control-sm float-left" v-model="mainListCancelType" :disabled="dataLoading" @change="MainListChange()">
                          <option value="" selected></option>
                          <option v-for="o in cancellationOptions" :key="o" v-bind:value="o.value">{{ o.label }}</option>
                        </select>
                      </div>
                      <div class="float-left mr-1" style="width:250px">
                          <v-date-picker v-model="rangeDate" mode="date"  is-range>
                            <template v-slot="{ inputValue, inputEvents }">
                              <div class="input-group input-group-sm">      
                                  <input
                                    :value="inputValue.start"
                                    :disabled="dataLoading"
                                    v-on="inputEvents.start"
                                    class="form-control form-control-sm"
                                  />
                                  <input
                                    :value="inputValue.end"
                                    :disabled="dataLoading"
                                    v-on="inputEvents.end"
                                    class="form-control form-control-sm"
                                  />
                                  <span class="input-group-append">
                                    <button type="button" class="btn btn-sm btn-default btn-flat" @click="ListButtonClick(mainListType=='cancel'?'cancel':'dateSearch')" :disabled="dataLoading">Listele</button>
                                  </span>
                              </div>    
                            </template>
                          </v-date-picker>
                      </div>
                      <div class="float-left" style="width:100px">
                        <button class="btn btn-default btn-sm" :disabled="dataLoading" @click="OpenCloseUploadFormModal(true)">Form Yükle</button>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="float-right">
                        <div class="input-group input-group-sm">
                          <select class="form-control" v-model="searchTextType" :disabled="dataLoading">
                            <option value="1">Ad Soyad</option>
                            <option value="3">E-Posta</option>
                            <option value="2">Telefon</option>
                          </select>
                          <input type="text" class="form-control" v-model="searchText" :disabled="dataLoading" v-on:keyup.enter="ListButtonClick('textSearch')">
                          <span class="input-group-append">
                            <button type="button" class="btn btn-default btn-flat" @click="ListButtonClick('textSearch')" :disabled="dataLoading">Ara</button>
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <span v-if="listType==null">Henüz bir liste seçmediniz! Yukarıda bulunan listeleme seçenekleri kullanabilirsiniz.</span>
                <span v-else-if="dataLoading"><i class="fas fa-solid fa-circle-notch fa-spin"></i> Yükleniyor. Lütfen bekleyiniz.</span>
                <span v-else>
                    <h5 class="float-left">{{listTitle}}</h5>
                    <span class="float-right">{{totalItems}} adet kayıt bulundu.</span>
                </span>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="row p-2 border-bottom" v-for="(item,index) in items" :key="item.id" >
                  <div class="col-12" v-show="item.registerStatus==2">
                    <div class="alert alert-warning alert-dismissible p-1">
                      <small><i class="icon fas fa-exclamation-triangle"></i>Bu kayıt daha önce öğrenci olmuş. Öğrenci <span class="text-bold" v-if="item.studentStatus==0">pasif</span> <span class="text-bold" v-else>aktif</span> durumdadır. Kontrol edilmeli.</small>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="btn-group  btn-group-sm float-left mr-2">
                      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" :disabled="dataLoading">
                        <i class="fas fa-bars"></i>
                      </button>
                      <div class="dropdown-menu" role="menu">
                        <a class="dropdown-item text-sm" href="javascript:;" v-show="item.formStatus!=3" @click="OpenCancelModal(item.id,index)">İptal Et</a>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenPrepareModal(item.id,index)">İleri Tarihe At</a>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenNoteListModal(item.id)">Not Listesi</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenUpdateInfoModal(item.id,item.name,item.surname,item.gender)">Bilgileri Düzelt</a>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenUpdateContactInfoModal(item.id,item.eMail,item.phoneNumber)">İletişim Bilgilerini Düzelt</a>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenUpdateUserModal(item.id,item.memberUsers)">Kullanıcılar</a>
                        <a class="dropdown-item text-sm" href="javascript:;" v-if="item.reference==null" @click="OpenAddReferenceNumberModal(item.id)">Referans Numarası Ekle</a>
                        <div class="dropdown-divider" v-show="item.registerStatus!=2"></div>
                        <a class="dropdown-item text-sm" href="javascript:;" v-show="item.registerStatus!=2" @click="OpenPackageModal(item.id,item.eMail,item.phoneNumber,item.name,item.surname)">Öğrenci Kaydı Oluştur</a>
                        <a class="dropdown-item text-sm" href="javascript:;" v-if="isAdmin && item.registerStatus!=2" @click="OpenDemoLessonModal(item.id)">Demo Ders Oluştur</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item text-sm" href="javascript:;" @click="OpenCloseTaskModal(true,item.id)">Hatırlatma / Görev Ekle</a>
                        <!-- <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">Yapılan İşlemler</a> -->
                      </div>
                    </div>
                    <h5 class="float-left text-capitalize"><i class="nav-icon fas fa-sm" v-if="item.gender>0" :class="{'fa-mars text-primary':item.gender==1,'fa-venus text-danger':item.gender==2}"></i><a href="javascript:;" @click="OpenCloseMemberProfileModal(true,item.id)">{{item.name}} {{item.surname}}</a></h5>
                    <div class="clearfix"></div>
                    <span class="p-1 pointer" style="font-size:22px;" @click="OpenSMSModal(item)"><i class="nav-icon fas fa-phone fa-sm"></i> {{item.phoneNumber}}</span> | <a class="ml-2" v-bind:href="'https://wa.me/' + item.phoneNumber.replace('0090-','90')" target="_blank"><i class="fab fa-whatsapp"></i></a>
                    <div class="clearfix"></div>
                    <span class="badge mr-1" :class="{'badge-danger':item.formStatus==3,'badge-success':item.formStatus==5,'badge-warning':(item.formStatus!=3&&item.formStatus!=5)}">{{FormStatus(item.formStatus)}}</span>
                    <span class="p-1 pointer text-sm" v-if="item.eMail!=null && item.eMail!=''" @click="OpenMailModal(item)"> <i class="nav-icon fas fa-at fa-sm"></i> {{item.eMail}}</span>
                    <div class="clearfix"></div>
                    <span class="mr-1 text-sm">{{DateFormat(CalculateDateTimeByOffset(item.date))}}</span>
                    <span class="badge badge-pill text-sm badge-light mr-1" :class="{'text-danger':item.utmData.utmSource.substring(0,17)=='kendini-dene-form'}" v-if="item.utmData!=null && item.utmData.utmSource!=null">{{item.utmData.utmSource}}</span>
                    <span class="badge badge-pill text-sm badge-light mr-1" v-if="item.utmData!=null">{{item.utmData.utmContent}}</span>
                    <span class="badge badge-pill text-sm badge-light mr-1" v-if="item.utmData!=null">{{item.utmData.utmMedium}}</span>
                    <span class="badge badge-pill text-sm badge-light mr-1" v-if="item.utmData!=null">{{item.utmData.utmCampaign}}</span>
                    <span class="badge badge-pill text-sm badge-light mr-1" v-if="item.utmData!=null">{{item.utmData.utmTerm}}</span>
                    <div class="clearfix"></div>
                    <span class="mr-1 text-sm" v-html="'Referans No : '+item.referenceNumber"></span> <span class="mr-1 text-sm" v-if="item.reference!=null" v-html="'Referans : ' + item.reference.name + ' ' +item.reference.surname"></span>
                    <div class="clearfix"></div>
                    <span class="mr-1 text-sm" v-html="item.id"></span>
                    <div v-if="item.lastPaymentModel!=null" class="clearfix"></div>
                    
                  </div>
                  <div class="col-sm-12 col-md-5" v-tooltip="'En son eklenen not.'">
                    <div v-if="item.lastComment!=null" class="overflow-auto" style="height:100px;">
                      <span class="d-block">{{item.lastComment.userFullName}} | <em>{{DateFormat(CalculateDateTimeByOffset(item.lastComment.date))}}</em></span>
                      <div class="clearfix"></div>
                      <div>
                          <small v-if="item.lastComment.constComment!=''">{{item.lastComment.constComment}}</small>
                          <small>{{item.lastComment.comment}}</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-2">
                    <div class="role bg-info float-left text-center text-sm rounded mb-1 mr-1 p-2" v-for="user in item.memberUsers" :key="user" v-show="user.fullName!=null && user.fullName.length>2"  v-tooltip="Role(user.role)">{{user.fullName}}</div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div v-if="item.lastPaymentModel!=null" v-html="GetPaymentInfo(item.lastPaymentModel)"></div>
                    <div v-if="item.lastTestModel!=null" v-html="GetTestInfo(item.lastTestModel)"></div>
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
              <!-- /.card -->
              <div class="card-footer clearfix">
                <PaginationBST :page="currentPage" :totalPage="totalPages" @PageNumber="GetMemberFormList($event)"></PaginationBST>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->

    <!-- Modal AddReferenceNumberModal -->
    <div class="modal fade" id="AddReferenceNumberModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <Form @submit="SaveReferenceNumber" :validation-schema="schemaReferenceNumber" ref="ReferenceNumberForm">
            <div class="modal-header">
              <h5 class="modal-title">Referans Numarası Ekle</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label" for="ReferenceNumber">Referans Numarası</label>
                  <div class="col-sm-8">
                    <Field type="text" class="form-control" id="ReferenceNumber" name="ReferenceNumber"/>
                    <small><ErrorMessage name="ReferenceNumber" class="error-feedback"/></small>
                  </div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                  <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                  <span class="ml-2">Ekle</span>
                </button>
            </div>
          </Form>
        </div>
      </div>
    </div>


    <!-- Modal CreateFormModal -->
    <div class="modal fade" id="CreateFormModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <Form @submit="SaveFormRegister" :validation-schema="schemaFormRegister" ref="FormRegister">
            <div class="modal-header">
              <h5 class="modal-title">Yeni Form Oluştur</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label" for="FormName">Ad</label>
                  <div class="col-sm-8">
                    <Field type="text" class="form-control" id="FormName" name="FormName"/>
                    <small><ErrorMessage name="FormName" class="error-feedback"/></small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label" for="FormSurname">Soyad</label>
                  <div class="col-sm-8">
                    <Field type="text" class="form-control" id="FormSurname" name="FormSurname"/>
                    <small><ErrorMessage name="FormSurname" class="error-feedback"/></small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label" for="FormPhoneCountryCode">Telefon Ülke Kodu</label>
                  <div class="col-sm-8">
                    <Field type="text" class="form-control" id="FormPhoneCountryCode" name="FormPhoneCountryCode" value="0090"/>
                    <small><ErrorMessage name="FormPhoneCountryCode" class="error-feedback"/></small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label" for="FormPhoneNumber">Telefon Numarası</label>
                  <div class="col-sm-8">
                    <Field type="text" class="form-control" id="FormPhoneNumber" name="FormPhoneNumber"/>
                    <small><ErrorMessage name="FormPhoneNumber" class="error-feedback"/></small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label" for="FormEMail">E-Posta Adresi</label>
                  <div class="col-sm-8">
                    <Field type="text" class="form-control" id="FormEMail" name="FormEMail"/>
                    <small><ErrorMessage name="FormEMail" class="error-feedback"/></small>
                  </div>
                </div>
                <Field type="hidden" class="form-control" id="FormUtmSource" name="FormUtmSource" value="panel.lengedu.com"/>
                <Field type="hidden" class="form-control" id="FormUtmContent" name="FormUtmContent" value="yeni-form-olustur"/>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                  <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                  <span class="ml-2">Oluştur</span>
                </button>
            </div>
          </Form>
        </div>
      </div>
    </div>


    <!-- Modal DemoLessonsModal -->
    <div class="modal fade" id="DemoLessonModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Demo Ders Oluştur</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
                <div class="form-group row">
                    <label class="col-form-label col-sm-4">Eğitmen</label>
                    <select class="form-control col-sm-8" v-model="demoLessonData.teacherId">
                      <option value=""></option>
                      <option v-for="teacher in demoLessonData.activeTeacherList" :key="teacher" :value="teacher.id">{{teacher.name}} {{teacher.surname}} ({{teacher.nickname}})</option>
                    </select>
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-sm-4">Ders Bilgileri</label>
                    <input type="text" class="form-control col-sm-3" v-model="demoLessonData.lessonDate" v-maska="'####-##-##'" placeholder="____-__-__"/>
                    <input type="text" class="form-control col-sm-2" v-model="demoLessonData.lessonTime" v-maska="'##:##'" placeholder="__:__"/>
                    <select class="form-control col-sm-3" v-model="demoLessonData.lessonDuration">
                      <option value=""></option>
                      <option v-for="m in [10,20]" :key="m" :value="m">{{m}} Dakika</option>
                    </select>
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-sm-4">Öğrenci Bilgileri</label>
                    <input type="text" class="form-control col-sm-4" v-model="demoLessonData.password" placeholder="Giriş Şifresi"/>
                    <input type="text" class="form-control col-sm-4" v-model="demoLessonData.skypeAddress" placeholder="Skype Adresi"/>
                </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="demoLessonData.formSendLoading" @click="CreateDemoLesson()">
                <span class="spinner-border spinner-border-sm" v-show="demoLessonData.formSendLoading"></span>
                <span class="ml-2">Demo Ders Oluştur</span>
              </button>
            </div>
        </div>
      </div>
    </div>


    <!-- Modal NotifcationSMSModal -->
    <div class="modal fade" id="NotifcationSmsModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">SMS Gönder</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <input class="form-control" v-model="smsSendData.phoneNumber" disabled>
                    </div>
                    <div class="form-group row">
                      <select class="form-control" v-model="smsSendData.id">
                          <option value="0"></option>
                          <option value="99999">Sms İçeriği Oluştur</option>
                          <option v-for="t in smsTemplates" :key="t" v-bind:value="t.id">{{t.title}}</option>
                        </select>
                    </div>
                    <div class="form-group row">
                        <textarea class="form-control" style="resize: none;" rows="5" v-model="smsSendData.content" :disabled="smsSendData.id!=99999"></textarea>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="smsSendData.formSendLoading || smsSendData.id==0" @click="SendSms()">
                <span class="spinner-border spinner-border-sm" v-show="smsSendData.formSendLoading"></span>
                <span class="ml-2">Gönder</span>
              </button>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal NotifcationMailModal -->
    <div class="modal fade" id="NotifcationMailModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">E-Posta Gönder</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <input class="form-control" v-model="mailSendData.to" disabled>
                    </div>
                    <div class="form-group row">
                        <select class="form-control" v-model="mailSendData.id">
                          <option value="0"></option>
                          <option value="99999">Mail İçeriği Oluştur</option>
                          <option v-for="t in mailTemplates" :key="t" v-bind:value="t.id">{{t.title}}</option>
                        </select>
                    </div>
                    <div class="form-group row">
                      <label>Mail Konusu</label>  
                      <input class="form-control" v-model="mailSendData.subject">
                    </div>
                    <div v-if="mailSendData.id==99999">
                      <editor :init="{license_key: 'gpl',menubar: false,toolbar: 'bold italic | alignleft aligncenter alignright alignjustify | bullist numlist'}" v-model="mailSendData.content"  tinymceScriptSrc="/tinymce/tinymce.min.js"/>
                    </div>
                    <div class="form-group row m-0" v-else-if="mailSendData.id>0">
                      <iframe id="MailView" v-bind:srcdoc="mailSendData.content" width="100%" height="350" frameborder="0" scrolling="yes"></iframe>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="mailSendData.formSendLoading || mailSendData.id==0" @click="SendMail()">
                <span class="spinner-border spinner-border-sm" v-show="mailSendData.formSendLoading"></span>
                <span class="ml-2">Gönder</span>
              </button>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal CancelModal -->
    <div class="modal fade" id="CancelModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">İptal Et</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <Form @submit="SaveCancelMemberForm" :validation-schema="schemaCancelMemberForm" ref="CancelMemberForm">
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <Field class="form-control" name="CancelMemberFormCancelOption" id="CancelMemberFormCancelOption" as="select">
                          <option value=""></option>
                          <option v-for="item in reasonForCancellation" :key="item" v-bind:value="item.code"  v-html="item.title"></option>
                        </Field>
                        <small><ErrorMessage name="CancelMemberFormCancelOption" class="error-feedback"/></small>
                    </div>
                    <div class="form-group row">
                        <Field class="form-control" style="resize: none;" rows="3" name="CancelMemberFormContent" id="CancelMemberFormContent" as="textarea"/>
                        <small><ErrorMessage name="CancelMemberFormContent" class="error-feedback"/></small>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                <span class="ml-2" >İptal Et</span>
              </button>
            </div>
            <Field type="hidden" name="CancelMemberFormId" id="CancelMemberFormId"/>
          </Form>
        </div>
      </div>
    </div>

    <!-- Modal PrepareModal -->
    <div class="modal fade" id="PrepareModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">İleri Tarihe At</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <Form @submit="SavePrepareMemberForm" :validation-schema="schemaPrepareMemberForm" ref="PrepareMemberForm">
            <div class="modal-body">
              <div class="row">
                <div class="col-8">
                  <div class="form-group">
                    <Field class="form-control" name="PrepareMemberFormOption" id="PrepareMemberFormOption" as="select">
                      <option value=""></option>
                      <option v-for="item in reasonForDelay" :key="item" v-bind:value="item.code" v-html="item.title"></option>
                    </Field>
                    <small><ErrorMessage name="PrepareMemberFormOption" class="error-feedback"/></small>
                  </div>
                </div>
                <div class="col-4">
                  <v-date-picker v-model="prepareDate" mode="date" :min-date="new Date()">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                        </div>
                        <input
                            :value="inputValue"
                            v-on="inputEvents"
                            class="form-control"
                          />
                      </div>
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                    <div class="form-group row">
                        <Field class="form-control" style="resize: none;" rows="3" name="PrepareMemberFormContent" id="PrepareMemberFormContent" as="textarea"/>
                        <small><ErrorMessage name="PrepareMemberFormContent" class="error-feedback"/></small>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                <span class="ml-2" >Kaydet</span>
              </button>
            </div>
              <Field type="hidden" name="PrepareMemberFormId" id="PrepareMemberFormId"/>
            </Form>
        </div>
      </div>
    </div>

    <!-- Modal NoteListModal -->
    <div class="modal fade" id="NoteListModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Not Listesi</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-4">
                    <Form @submit="SaveNoteMemberForm" :validation-schema="schemaNoteMemberForm" ref="NoteMemberForm">
                    <div class="form-group row">
                        <Field class="form-control" style="resize: none;" rows="15" name="NoteMemberFormContent" id="NoteMemberFormContent" as="textarea"/>
                        <small><ErrorMessage name="NoteMemberFormContent" class="error-feedback"/></small>
                    </div>
                    <div class="form-group row">
                      <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                        <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                        <span class="ml-2">Kaydet</span>
                      </button>
                    </div>
                    </Form>
                </div>
                <div class="col-8">
                  <div class="overflow-auto mb-1" style="height:400px;">
                    <div class="border-bottom p-1 mb-1" v-for="item in note.items" :key="item.id">
                      <small>
                        <span class="text-bold"><em>{{item.creationUserFullName}} | {{DateFormat(CalculateDateTimeByOffset(item.creationDate))}}</em></span>
                        <p v-if="item.constContent!=''">{{item.constContent}}</p>
                        <p>{{item.description}}</p>
                      </small>
                    </div>
                  </div>
                  <div v-show="note.totalPages>0">
                    <ul class="pagination pagination-sm m-0 float-right">
                      <li class="page-item" v-show="(note.currentPage-1)>0"><button class="page-link" @click="GetMemberNote(note.currentPage-1)" :disabled="note.dataLoading">&laquo;</button></li>
                      <li v-for="page in note.totalPages" :key="page" class="page-item" :class="{'active':page==note.currentPage}"><button class="page-link" @click="GetMemberNote(page)" :disabled="note.dataLoading">{{page}}</button></li>
                      <li class="page-item" v-show="(note.currentPage+1)<=note.totalPages"><button class="page-link" @click="GetMemberNote(note.currentPage+1)" :disabled="note.dataLoading">&raquo;</button></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal UpdateInfoModal -->
    <div class="modal fade" id="UpdateInfoModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Bilgileri Düzelt</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <Form @submit="SaveInfoMemberForm" :validation-schema="schemaInfoMemberForm" ref="InfoMemberForm">
              <div class="modal-body">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label" for="InfoMemberFormName">Ad</label>
                  <div class="col-sm-9">
                    <Field type="text" class="form-control" id="InfoMemberFormName" name="InfoMemberFormName"/>
                    <small><ErrorMessage name="InfoMemberFormName" class="error-feedback"/></small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label" for="InfoMemberFormSurname">Soyad</label>
                  <div class="col-sm-9">
                    <Field type="text" class="form-control" id="InfoMemberFormSurname" name="InfoMemberFormSurname"/>
                    <small><ErrorMessage name="InfoMemberFormSurname" class="error-feedback"/></small>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label" for="gender">Cinsiyet</label>
                  <div class="col-sm-9">
                    <Field class="form-control" name="InfoMemberFormGenderOption" id="InfoMemberFormGenderOption" as="select">
                      <option value=""></option>
                      <option value="1">Erkek</option>
                      <option value="2">Kadın</option>
                    </Field>
                    <small><ErrorMessage name="InfoMemberFormGenderOption" class="error-feedback"/></small>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                  <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                  <span class="ml-2">Kaydet</span>
                </button>
              </div>
              <Field type="hidden" name="InfoMemberFormId" id="InfoMemberFormId"/>
          </Form>
        </div>
      </div>
    </div>

    <!-- Modal UpdateContactInfoModal -->
    <div class="modal fade" id="UpdateContactInfoModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">İletişim Bilgileri Düzelt</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-group">
              <Form @submit="SaveContactEmailMemberForm" :validation-schema="schemaContactEmailMemberForm" ref="ContactEmailMemberForm">
                <div class="input-group">
                  <Field type="text" class="form-control" id="ContactEmailMemberFormEmail" name="ContactEmailMemberFormEmail"/>
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                      <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                      <span class="ml-2">Kaydet</span>
                    </button>
                  </div>
                </div>
                <small><ErrorMessage name="ContactEmailMemberFormEmail" class="error-feedback"/></small>
                <Field type="hidden" name="ContactEmailMemberFormId" id="ContactEmailMemberFormId"/>
              </Form>
            </div>
            <div class="form-group">
              <Form @submit="SaveContactPhoneMemberForm" :validation-schema="schemaContactPhoneMemberForm" ref="ContactPhoneMemberForm">
                <div class="input-group">
                  <Field type="text" class="form-control col-2" id="ContactPhoneMemberFormPhoneCode" name="ContactPhoneMemberFormPhoneCode"/>
                  <Field type="text" class="form-control" id="ContactPhoneMemberFormPhone" name="ContactPhoneMemberFormPhone"/>
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                      <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                      <span class="ml-2">Kaydet</span>
                    </button>
                  </div>
                </div>
                <small><ErrorMessage name="ContactPhoneMemberFormPhone" class="error-feedback"/></small>
                <Field type="hidden" name="ContactPhoneMemberFormId" id="ContactPhoneMemberFormId"/>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal UpdateUserModal -->
    <div class="modal fade" id="UpdateUserModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Kullanıcılar</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
                <div class="form-group">
                  <Form @submit="SaveUserFormOperatorMemberForm" :validation-schema="schemaUserFormOperatorMemberForm" ref="UserFormOperatorMemberForm">
                    <label class="col-form-label" for="UserFormOperatorOption">Form Temsilcisi</label>
                    <div class="input-group">
                      <Field class="form-control" name="UserFormOperatorOption" id="UserFormOperatorOption" as="select">
                        <option value=""></option>
                        <option v-for="user in operationUsers.filter(x=>x.role===3)" :key="user" :value="user.id">{{user.fullName}}</option>
                      </Field>
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                          <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                          <span class="ml-2">Kaydet</span>
                        </button>
                      </div>
                    </div>
                    <small><ErrorMessage name="UserFormOperatorOption" class="error-feedback"/></small>
                    <Field type="hidden" name="UserFormOperatorMemberFormId" id="UserFormOperatorMemberFormId"/>
                  </Form>
                </div>
                <hr/>
                <div class="form-group">
                  <Form @submit="SaveUserSaleMemberForm" :validation-schema="schemaUserSaleMemberForm" ref="UserSaleMemberForm">
                    <label class="col-form-label" for="UserSaleOption">Satış Temsilcisi</label>
                    <div class="input-group">
                      <Field class="form-control" name="UserSaleOption" id="UserSaleOption" as="select">
                        <option value=""></option>
                        <option v-for="user in operationUsers.filter(x=>x.role===4)" :key="user" :value="user.id">{{user.fullName}}</option>
                      </Field>
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                          <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                          <span class="ml-2">Kaydet</span>
                        </button>
                      </div>
                    </div>
                    <small><ErrorMessage name="UserSaleOption" class="error-feedback"/></small>
                    <Field type="hidden" name="UserSaleMemberFormId" id="UserSaleMemberFormId"/>
                  </Form>
                </div>
                <hr/>
                <div class="form-group">
                  <Form @submit="SaveUserStudentOperatorMemberForm" :validation-schema="schemaUserStudentOperatorMemberForm" ref="UserStudentOperatorMemberForm">
                    <label class="col-form-label" for="UserStudentOperatorOption">Öğrenci Temsilcisi</label>
                    <div class="input-group">
                      <Field class="form-control" name="UserStudentOperatorOption" id="UserStudentOperatorOption" as="select">
                        <option value=""></option>
                        <option v-for="user in operationUsers.filter(x=>x.role===2)" :key="user" :value="user.id">{{user.fullName}}</option>
                      </Field>
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-primary" :disabled="modalSubmitLoading">
                          <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                          <span class="ml-2">Kaydet</span>
                        </button>
                      </div>
                    </div>
                    <small><ErrorMessage name="UserStudentOperatorOption" class="error-feedback"/></small>
                    <Field type="hidden" name="UserStudentOperatorMemberFormId" id="UserStudentOperatorMemberFormId"/>
                  </Form>
                </div>
            </div>
        </div>
      </div>
    </div>

    <!-- Modal PackageModal -->
    <div class="modal fade" id="PackageModal" tabindex="1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="progress mb-3">
              <div class="progress-bar bg-private" role="progressbar" aria-valuemin="0" aria-valuemax="100" v-bind:style="{width: stepper.progressValue + '%'}"></div>
            </div>
            <div class="pr-3 pl-3 pb-3" v-show="stepper.stepBlock1">
                <h3>Paket Seçimi</h3>
                <div class="row mb-2">
                    <div class="col-7">
                      <div class="form-group">
                        <select class="form-control" v-model="trainingPackage.selected.packageId" @change="OnSelectPackage()">
                            <option value=""></option>
                            <optgroup v-for="m in packageOptions" :key="m" :label="m.title">
                                <option v-for="p in m.packages" :key="p" :value="p.id">
                                  {{ p.title }}
                                </option>
                            </optgroup>
                        </select>
                      </div>
                      <div class="overflow-auto mt-2" style="height:114px">
                        <div class="form-group" v-if="(trainingPackage.package)?true:false">
                          <div class="form-check" v-for="(campaing,index) in trainingPackage.package.campaigns" :key="campaing" >
                            <input class="form-check-input" type="checkbox" v-bind:value="campaing.id" @change="OnCheckCampaign($event)" v-bind:id="'campaingchk'+index">
                            <label class="form-check-label" v-bind:for="'campaingchk'+index">{{campaing.title}} (%{{ campaing.discountRate }} İnidirim)</label>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2">
                          <div class="row border-bottom">
                            <div class="col-2"></div>
                            <div class="col-2 text-center" v-tooltip="'Eğitim Süresi (Ay)'"><small><i class="far fa-calendar-alt"></i></small></div>
                            <div class="col-2 text-center" v-tooltip="'Ders Erteleme (Adet)'"><small><i class="fas fa-redo-alt"></i></small></div>
                            <div class="col-2 text-center" v-tooltip="'Eğitmen Saat Değişikliği (Adet)'"><small><i class="fas fa-people-arrows"></i></small></div>
                            <div class="col-2 text-center" v-tooltip="'Ders Dondurma (Gün)'"><small><i class="fas fa-snowflake"></i></small></div>
                            <div class="col-2 text-center"><small></small></div>
                          </div>
                          <div class="row border-bottom">
                            <div class="col-2"><small>Paket</small></div>
                            <div class="col-2 text-center"><small>{{trainingPackage.selectedTraining.month}}</small></div>
                            <div class="col-2 text-center"><small>{{trainingPackage.selectedTraining.postpone}}</small></div>
                            <div class="col-2 text-center"><small>{{trainingPackage.selectedTraining.teacherChange}}</small></div>
                            <div class="col-2 text-center"><small>{{trainingPackage.selectedTraining.freezeLesson}}</small></div>
                            <!-- <div class="col-2 text-center"><small>{{trainingPackage.selectedTraining.missed}}</small></div> -->
                            <div class="col-2 text-center"><small></small></div>
                          </div>
                          <div class="row border-bottom">
                            <div class="col-2"><small>Kampanya</small></div>
                            <div class="col-2 text-center"><small>{{trainingPackage.selectedCampaign.month}}</small></div>
                            <div class="col-2 text-center"><small>{{trainingPackage.selectedCampaign.postpone}}</small></div>
                            <div class="col-2 text-center"><small>{{trainingPackage.selectedCampaign.teacherChange}}</small></div>
                            <div class="col-2 text-center"><small>{{trainingPackage.selectedCampaign.freezeLesson}}</small></div>
                            <!-- <div class="col-2 text-center"><small>{{trainingPackage.selectedCampaign.missed}}</small></div> -->
                            <div class="col-2 text-center"><small></small></div>
                          </div>
                          <!-- <span class="mr-2" v-tooltip="'Eğitmen Saat Değişikliği'"><i class="fas fa-people-arrows"></i> 5 Adet</span>
                          <span class="mr-2" v-tooltip="'Ders Erteleme'"><i class="fas fa-redo-alt"></i> 10 Adet</span>
                          <span v-tooltip="'Ders Dondurma'"><i class="fas fa-snowflake"></i> 45 Gün</span> -->
                      </div>
                      <div class="text-danger"><small><i>* Toplam tutara <u>KDV dahil dir.</u></i></small></div>
                    </div>
                    <div class="col-5">
                      <div>
                        <div class="form-group input-group-sm row mb-1">
                          <label class="col-sm-5 col-form-label">Tutar</label>
                          <div class="col-sm-7">
                            <div class="input-group input-group-sm">
                              <input type="text" class="form-control" v-model="trainingPackage.price" disabled>
                              <div class="input-group-append">
                                <span class="input-group-text">TL</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group input-group-sm row mb-1">
                          <label class="col-sm-5 col-form-label">İndirim</label>
                          <div class="col-sm-7">
                            <div class="input-group input-group-sm">
                              <input type="text" class="form-control" v-model="trainingPackage.discount"  disabled>
                              <div class="input-group-append">
                                <span class="input-group-text">TL</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group input-group-sm row mb-1">
                          <label class="col-sm-5 col-form-label">Toplam Tutar</label>
                          <div class="col-sm-7">
                            <div class="input-group input-group-sm">
                              <input type="text" class="form-control" v-model="trainingPackage.totalPrice" disabled>
                              <div class="input-group-append">
                                <span class="input-group-text">TL</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div>
                          <div class="row border-bottom" v-for="(taxe,index) in trainingPackage.taxes" :key="taxe">
                            <div class="col-1"><input type="radio" name="taxeInput" @click="trainingPackage.selectedTaxe=taxe" v-bind:checked="index === 0" v-bind:id="'taxeradio'+index"></div>
                            <div class="col-3"><label v-bind:for="'taxeradio'+index"><small>{{taxe.title}}</small></label></div>
                            <div class="col-3 text-center"><label v-bind:for="'taxeradio'+index"><small>{{taxe.taxePrice}} TL</small></label></div>
                            <div class="col-5 text-center"><label v-bind:for="'taxeradio'+index"><small>{{taxe.totalPrice}} TL</small></label></div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <button class="btn btn-sm btn-primary float-right" @click="onClickStep(1)" :disabled="(trainingPackage.selected.packageId==='')">Devam Et <i class="fas fa-angle-double-right"></i></button>
                <div class="clearfix"></div>
            </div>
            <div class="pr-3 pl-3 pb-3" v-show="stepper.stepBlock2">
              <h3>Ödeme</h3>
              <div class="mb-2">
                  <div class="row">
                    <div class="col-5">
                      <p v-if="((trainingPackage.package)?trainingPackage.package.month:-1)>0">{{(trainingPackage.package)?trainingPackage.package.month:0}} Ay {{(trainingPackage.package)?trainingPackage.package.duration:0}} Dakika (Haftada {{(trainingPackage.package)?trainingPackage.package.weekDay:0}} Gün)</p>
                      <p v-else-if="((trainingPackage.package)?trainingPackage.package.month:-1)==0">Kendini Dene Paketi (Hafta içi {{(trainingPackage.package)?trainingPackage.package.weekDay:0}} Gün {{(trainingPackage.package)?trainingPackage.package.duration:0}} Dakika)</p>
                      <div class="row mb-2">
                        <div class="col-6">
                          <div class="input-group input-group">
                            <input type="text" class="form-control" v-model="paymentInfo.payTotalPrice" :disabled="(!paymentInfo.isPartialPay)">
                            <div class="input-group-append">
                              <span class="input-group-text">TL</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-check mt-2">
                            <input type="checkbox" class="form-check-input" id="partialPay" v-model="paymentInfo.isPartialPay" :disabled="true">
                            <label class="form-check-label" for="partialPay">Parçalı Ödeme Al</label>
                          </div>
                        </div>
                      </div>
                      <div id="accordion">
                        <div class="card card-secondary">
                          <div class="card-header">
                            <h4 class="card-title w-100">
                              <a class="d-block w-100" data-toggle="collapse" href="#collapseOne">
                                <i class="far fa-credit-card"></i> Kredi Kartı
                              </a>
                            </h4>
                          </div>
                          <div id="collapseOne" class="collapse show" data-parent="#accordion">
                            <div class="card-body">
                                <Form @submit="SubmitPayCreditCardForm" :validation-schema="payCreditCardForm" ref="PayCreditCardForm">
                                  <div class="form-group">
                                    <Field type="text" class="form-control" placeholder="Kart Sahibi" maxlength="30" name="CardHolder" id="CardHolder" />
                                    <small><ErrorMessage name="CardHolder" class="error-feedback"/></small>
                                  </div>
                                  <div class="form-group">
                                    <Field type="text" class="form-control" v-maska="'####-####-####-####'" name="CardNumber" id="CardNumber" />
                                    <small><ErrorMessage name="CardNumber" class="error-feedback"/></small>
                                  </div>
                                  <div class="row">
                                    <div class="col-4">
                                      <div class="form-group mb-1">
                                        <Field type="text" class="form-control" v-maska="'##/####'" name="CardExpireDate" id="CardExpireDate" />
                                      </div>
                                    </div>
                                    <div class="col-3">
                                      <div class="form-group mb-1">
                                        <Field type="text" class="form-control" v-maska="'###'" name="CardCvv" id="CardCvv" />
                                      </div>
                                    </div>
                                    <div class="col-5">
                                      <div class="form-group mb-1">
                                        <Field class="form-control" name="CardPayTaxeNumber" id="CardPayTaxeNumber" :disabled="(!paymentInfo.isPartialPay)" as="select">
                                          <option value="1">1 Taksit</option>
                                          <option value="2">2 Taksit</option>
                                          <option value="3">3 Taksit</option>
                                          <option value="4">4 Taksit</option>
                                          <option value="5">5 Taksit</option>
                                          <option value="6">6 Taksit</option>
                                          <option value="7">7 Taksit</option>
                                          <option value="8">8 Taksit</option>
                                          <option value="9">9 Taksit</option>
                                          <option value="10">10 Taksit</option>
                                          <option value="11">11 Taksit</option>
                                          <option value="12">12 Taksit</option>
                                        </Field>
                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div><small><ErrorMessage name="CardExpireDate" class="error-feedback"/></small></div>
                                      <div><small><ErrorMessage name="CardCvv" class="error-feedback"/></small></div>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col-6">
                                      <div class="form-check">
                                        <Field type="checkbox" class="form-check-input" name="Pay3DChk" id="Pay3DChk" :value="true"/>
                                        <label class="form-check-label" for="Pay3DChk">3D Secure</label>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group">
                                        <button type="submit" class="btn btn-primary float-right" :disabled="modalSubmitLoading">
                                          <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                                          <span class="ml-2">Ödeme Al</span>
                                        </button>
                                        <div class="clearfix"></div>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                            </div>
                          </div>
                        </div>
                        <div class="card card-secondary">
                          <div class="card-header">
                            <h4 class="card-title w-100">
                              <a class="d-block w-100" data-toggle="collapse" href="#collapseTwo">
                                <i class="fas fa-university"></i> Havale
                              </a>
                            </h4>
                          </div>
                          <div id="collapseTwo" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <div class="form-group text-center">
                                  <button type="submit" class="btn btn-primary" @click="SubmitPayMoneyOrderForm()" :disabled="modalSubmitLoading">
                                    <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                                    <span class="ml-2">Havale ile Ödeme Al</span>
                                  </button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-7">
                        <h5>Başarılı Ödemeler</h5>
                        <table class="table table-sm">
                          <thead>
                          <tr>
                            <th style="width: 30px">#</th>
                            <th style="width: 100px">&nbsp;</th>
                            <th class="text-center">Tutar</th>
                            <th class="text-center" style="width: 150px">Tarih</th>
                          </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(p , index) in freePayments" :key="p">
                              <td><input type="checkbox" v-bind:id="'paychk'+index" v-model="paymentInfo.paymentIds" v-bind:value="p.paymentId" /></td>
                              <td class="text-center"><label v-bind:for="'paychk'+index" style="font-weight:normal;">{{GetPaymentTypeText(p.paymentType)}}</label></td>
                              <td class="text-center"><label v-bind:for="'paychk'+index" style="font-weight:normal;">{{p.amount}} {{GetCurrencyText(p.currency)}}</label></td>
                              <td class="text-center"><label v-bind:for="'paychk'+index" style="font-weight:normal;">{{DateFormat(CalculateDateTimeByOffset(p.date))}}</label></td>
                            </tr>
                          </tbody>
                        </table>
                        <h5 class="float-left">Fatura Bilgisi</h5>
                        <button class="btn btn-primary btn-sm float-right" @click="OpenBillModal()">Oluştur</button>
                        <div class="clearfix"></div>
                        <table class="table table-sm mt-1">
                          <tbody>
                            <tr v-for="bill in trainingPackage.billInfos" :key="bill">
                              <td>
                                <div class="form-check">
                                  <input class="form-check-input" type="radio" name="flexRadioDefault" v-bind:id="bill.id" @click="SelectBill(bill.id)">
                                  <label class="form-check-label" v-bind:for="bill.id">
                                    {{bill.title}} - {{bill.taxNumber}} / {{bill.taxOffice}}
                                  </label>
                                </div>
                              </td>
                              <td style="width:40px;" v-tooltip="{ content: bill.title+'<br>'+bill.taxNumber+' / '+bill.taxOffice+'<br>'+bill.address+'<br>'+bill.country+' / '+bill.city+' / '+bill.district, html:true }"><i class="fas fa-eye"></i></td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                  </div>
              </div>
              <button class="btn btn-sm btn-primary float-left" @click="onClickStep(-1)"><i class="fas fa-angle-double-left"></i> Geri</button>
              <button class="btn btn-sm btn-primary float-right" @click="onClickStep(1)" :disabled="paymentInfo.paymentIds.length==0">Devam Et <i class="fas fa-angle-double-right"></i></button>
              <div class="clearfix"></div>
            </div>
            <div class="pr-3 pl-3 pb-3" v-show="stepper.stepBlock3">
              <h3>Eğitmen Seçimi</h3>
              <div class="mb-2">
                <div class="mb-2"><i><strong>Haftada {{trainingPackage.package.weekDay}} gün, {{trainingPackage.selectedTraining.duration}} dakika</strong> eğitim.</i></div>
                <TeacherWorkTimes :Times="teacherTimes" :TrianingWeekDay="trainingPackage.package.weekDay" @SelectedTeacherTime="SelectedTeacherTime($event)"></TeacherWorkTimes>
              </div>
              <button class="btn btn-sm btn-primary float-left" @click="onClickStep(-1)"><i class="fas fa-angle-double-left"></i> Geri</button>
              <button class="btn btn-sm btn-primary float-right" @click="onClickStep(1)" :disabled="selectedTeacher.id===''">Devam Et <i class="fas fa-angle-double-right"></i></button>
              <div class="float-right mr-2 h5 mt-1">{{selectedTeacher.name}} {{selectedTeacher.showTime}}</div>
              <div class="clearfix"></div>
            </div>
            <div class="pr-3 pl-3 pb-3" v-show="stepper.stepBlock4">
              <h3>Eğitim Oluşturma</h3>
              <div class="mb-2">
                  <div class="row mt-3">
                    <div class="col-6">
                      <Form @submit="SubmitComplatedForm" :validation-schema="complatedForm" ref="ComplatedForm">
                        <div class="form-group">
                          <Field type="text" class="form-control" placeholder="Skype Adresi" maxlength="50" name="SkypeAddress" id="SkypeAddress" />
                          <small><ErrorMessage name="SkypeAddress" class="error-feedback"/></small>
                        </div>
                        <v-date-picker v-model="scheduleStartDate" mode="date" :min-date="new Date()">
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="form-group">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                              </div>
                              <input
                                  :value="inputValue"
                                  v-on="inputEvents"
                                  class="form-control"
                                />
                            </div>
                          </div>
                          </template>
                        </v-date-picker>
                        <div class="form-group">
                          <Field class="form-control" name="StartBookLesson" id="StartBookLesson" as="select">
                            <optgroup v-for="b in bookWithLevel" :key="b" :label="b.book.title">
                                <option v-for="l in b.levels" :key="l" :value="b.book.bookId+'#'+l.bookLevelId">
                                  {{ l.title }}
                                </option>
                            </optgroup>
                          </Field>
                          <small><ErrorMessage name="StartBookLesson" class="error-feedback"/></small>
                        </div>
                        <div class="form-check">
                          <Field type="checkbox" class="form-check-input" name="FirstLessonIsLevelTest" id="FirstLessonIsLevelTest" :value="true"/>
                          <label class="form-check-label" for="FirstLessonIsLevelTest">İlk ders seviye belirleme olsun.</label>
                        </div>
                        <div class="row mt-3">
                          <div class="col-12">
                            <div class="form-group">
                              <button type="submit" class="btn btn-primary float-right" :disabled="modalSubmitLoading">
                                <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
                                <span class="ml-2">İşlemleri Tamamla</span>
                              </button>
                              <div class="clearfix"></div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bill Model -->
    <div class="modal fade" id="BillModal" tabindex="1" role="dialog">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Fatura Bilgisi Oluştur</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input type="text" v-model="memberBill.title" class="form-control mb-2" placeholder="Başlık">
            <input type="text" v-model="memberBill.taxNumber" class="form-control mb-2" placeholder="Vergi No / TC.Kimlik No" maxlength="11">
            <input type="text" v-model="memberBill.taxOffice" class="form-control mb-2" placeholder="Vergi Dairesi" maxlength="100">
            <input type="text" v-model="memberBill.address" class="form-control mb-2" placeholder="Adres">
            <input type="text" v-model="memberBill.country" class="form-control mb-2" placeholder="Ülke">
            <input type="text" v-model="memberBill.city" class="form-control mb-2" placeholder="Şehir">
            <input type="text" v-model="memberBill.district" class="form-control mb-2" placeholder="İlçe">
            <input type="text" v-model="memberBill.postCode" class="form-control mb-2" placeholder="Posta Kodu">

            <button type="button" class="btn btn-primary" :disabled="modalSubmitLoading" @click="SaveMemberBill()">
              <span v-show="modalSubmitLoading" class="spinner-border spinner-border-sm"></span>
              <span class="ml-2">Kaydet</span>
            </button>

          </div>
        </div>
      </div>
    </div>

     <!-- PaymentModal CancelModal -->
     <div class="modal fade" id="PaymentModal" tabindex="1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <iframe id="PaymentIFrame" src="" width="100%" height="450"></iframe>
          <div class="modal-footer">
            <button class="btn btn-default btn-sm" @click="paymentModalClose()">Kapat</button>
          </div> 
        </div>
      </div>
    </div>

    <TaskModal :Visible="taskModalVisible" :MemberId="taskModalMemberId" @CloseTaskModal="OpenCloseTaskModal($event,null)"></TaskModal>
    <UploadFormModal :Visible="uploadFormModalVisible" @CloseUploadFormModal="OpenCloseUploadFormModal($event)"></UploadFormModal>
    <MemberProfileModal :Visible="memberProfileModalVisible" :MemberId="memberProfileModalMemberId" @CloseMemberProfileModal="OpenCloseMemberProfileModal($event,null)"></MemberProfileModal>


</template>

<script>
import {mapActions,mapGetters} from "vuex";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import {locale} from "../../../helpers/datatimepicker-option-helper";
import {GetFormStatus, GetRoleText,GetCurrencyText, GetPaymentTypeText} from "../../../helpers/enum-convert-helper";
import {DateFormat,CalculateTime,CalculateDateTime} from "../../../helpers/date-convert-helper";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import {ParsePhoneNumber} from "../../../helpers/phone-helper";
import {TextConvert,GetDefaultMailTemplate} from "../../../helpers/notification-helper";
import Editor from '@tinymce/tinymce-vue';
import PaginationBST from '../../../components/Pagination.vue';
import TeacherWorkTimes from '../../../components/TeacherWorkTimes.vue';
import TaskModal from '../../../components/TaskModal.vue';
import UploadFormModal from '../../../components/UploadFormModal.vue';
import MemberProfileModal from '../../../components/MemberProfile/MemberProfileModal.vue';
import {reasonForCancellations} from "../../../helpers/const-data";
export default {
  name: "MemberForm",
  components: {
    Form,
    Field,
    ErrorMessage,
    'editor': Editor,
    PaginationBST,
    TeacherWorkTimes,
    TaskModal,
    UploadFormModal,
    MemberProfileModal
  },
  data(){
    
    
    const schemaReferenceNumber =  yup.object().shape({
      ReferenceNumber: yup.string().required("Referans numarası giriniz."),
    });

    const schemaFormRegister =  yup.object().shape({
      FormName: yup.string().required("Ad giriniz").min(3,"En az 3 karakter girmelisiniz"),
      FormSurname: yup.string().required("Soyad giriniz").min(2,"En az 2 karakter girmelisiniz"),
      FormPhoneCountryCode: yup.number().required("Ülke telefon kodunu giriniz").typeError('Lütfen bir sayı giriniz.').min(1,'En az bir karakter girmelisiniz.').max(99999,"En fazla 5 karakter girebilirsiniz"),
      FormPhoneNumber: yup.number().required("Telefon numarası giriniz").typeError('Lütfen bir sayı giriniz.').min(10000,'En az 5 karakter girmelisiniz.').max(99999999999,"En fazla 11 karakter girebilirsiniz"),
      FormEMail: yup.string().email('Lütfen geçerli e-posta adresi giriniz.').required("E-Posta adresi giriniz"),
    });
    const schemaCancelMemberForm =  yup.object().shape({
      CancelMemberFormCancelOption: yup.string().required("İptal nedeni belirtiniz."),
      CancelMemberFormContent: yup.string().when('CancelMemberFormCancelOption',{
        is:(val)=>{
          return val!='000'
        },
        then:yup.string().notRequired(),
        otherwise: yup.string().required('İptal nedeni yazınız.')
      }),
      CancelMemberFormId:yup.string()
    });
    const schemaPrepareMemberForm = yup.object().shape({
      PrepareMemberFormOption: yup.string().required("Erteleme nedeni belirtiniz."),
      //PrepareMemberFormDate: yup.string().required("Tarih seçiniz."),
      PrepareMemberFormContent: yup.string().when('PrepareMemberFormOption',{
        is:(val)=>{
          return val!='100'
        },
        then:yup.string().notRequired(),
        otherwise: yup.string().required('Erteleme nedeni yazınız.')
      }),
      PrepareMemberFormId:yup.string()
    });
    const schemaNoteMemberForm =  yup.object().shape({
      NoteMemberFormContent: yup.string().required("Not giriniz."),
    });
    const schemaInfoMemberForm =  yup.object().shape({
      InfoMemberFormName: yup.string().required("Ad giriniz."),
      InfoMemberFormSurname: yup.string().required("Soyad giriniz."),
      InfoMemberFormGenderOption: yup.string().required("Cinsiyet giriniz."),
      InfoMemberFormId:yup.string()
    });
    const schemaContactEmailMemberForm = yup.object().shape({
      ContactEmailMemberFormEmail: yup.string().required("E-posta adresi giriniz."),
      ContactEmailMemberFormId:yup.string()
    });
    const schemaContactPhoneMemberForm = yup.object().shape({
      ContactPhoneMemberFormPhoneCode: yup.string().required("Ülke kodunu giriniz."),
      ContactPhoneMemberFormPhone: yup.string().required("Telefon numarası giriniz."),
      ContactPhoneMemberFormId:yup.string()
    });
    const schemaUserFormOperatorMemberForm = yup.object().shape({
      UserFormOperatorOption: yup.string().required("Form temsilcisi seçiniz."),
      UserFormOperatorMemberFormId:yup.string()
    });
    const schemaUserSaleMemberForm = yup.object().shape({
      UserSaleOption: yup.string().required("Satış temsilcisi seçiniz."),
      UserSaleMemberFormId:yup.string()
    });
    const schemaUserStudentOperatorMemberForm = yup.object().shape({
      UserStudentOperatorOption: yup.string().required("Öğrenci temsilcisi seçiniz."),
      UserStudentOperatorMemberFormId:yup.string()
    });
    const complatedForm = yup.object().shape({
      SkypeAddress: yup.string().required("Skype adresi giriniz."),
      //ScheduleStartDate:yup.string().required("Başlangıç tarihini seçiniz."),
      StartBookLesson:yup.string().required("Başlangıç kitabını seçiniz."),
    });
    const payCreditCardForm = yup.object().shape({
      CardHolder: yup.string().required("Kart sahibinin adını ve soyadını giriniz."),
      CardNumber : yup.string().required("Kart numarasını giriniz."),
      CardCvv: yup.string().required("Kartın güvenlik kodunu giriniz."),
      CardExpireDate: yup.string().required("Kartın son kullanım tarihini giriniz."),
      //Pay3DChk: yup.boolean(),
      //CardPayTaxeNumber : yup.number()
    });

    return{
      currentPage:0,
      selectedItemIndex:0,
      items:[],
      pageSize:0,
      totalItems:0,
      totalPages:0,
      dataLoading:false,
      listType:null,
      listTitle:'',
      searchTextType:2,
      searchText:null,
      rangeDate:{
        start: new Date(),
        end: new Date()
      },
      mainListType:'',
      mainListCancelType:'',
      mainListSaleUserId:'',
      modalSubmitLoading:false,
      prepareDate: new Date(new Date().setDate(new Date().getDate()+1)),
      schemaCancelMemberForm,
      schemaPrepareMemberForm,
      schemaNoteMemberForm,
      schemaInfoMemberForm,
      schemaContactEmailMemberForm,
      schemaContactPhoneMemberForm,
      schemaUserFormOperatorMemberForm,
      schemaUserSaleMemberForm,
      schemaUserStudentOperatorMemberForm,
      payCreditCardForm,
      complatedForm,
      schemaFormRegister,
      schemaReferenceNumber,
      reasonForCancellation:[],
      reasonForDelay:[],
      note:{
        memberId:null,
        dataLoading:false,
        currentPage:0,
        items:[],
        pageSize:0,
        totalItems:0,
        totalPages:0,
      },
      operationUsers:[],
      stepper:{
        currentStep:1,
        maxStep:4,
        stepBlock1:true,
        stepBlock2:false,
        stepBlock3:false,
        stepBlock4:false,
        progressValue:0,
      },
      packages:[],
      packageOptions:[],
      trainingPackage:{
        package:{},
        selected :{
          packageId:'',
          campaings:[]
        },
        selectedTraining:{
            month:0,
            duration:0,
            freezeLesson:0,
            missed:0,
            postpone:0,
            teacherChange:0
        },
        selectedCampaign:{
            month:0,
            freezeLesson:0,
            missed:0,
            postpone:0,
            teacherChange:0
        },
        price :0,
        discount:0,
        totalPrice:0,
        taxRate:0,
        taxAmount:0,
        twoTax: 0,
        threeTax: 0,
        fourTax: 0,
        fiveTax: 0,
        sixTax: 0,
        sevenTax: 0,
        eightTax: 0,
        nineTax: 0,
        tenTax: 0,
        elevenTax: 0,
        twelveTax: 0,
        taxes :[],
        selectedTaxe:{
          taxeNumber:1,
          title:"Tek Çekim",
          taxePrice:0,
          lateCharge:0,
          totalPrice:0
        },
        billInfos:[],
        selectBill:{
          id:'',
          title: '',
          taxOffice: '',
          taxNumber: '',
          address: '',
          country: '',
          city: '',
          district: '',
          postCode: '',
        }
      },
      teacherTimes:[],
      selectedTeacher:{},
      selectedMemberId:null,
      selectedMemberEmail:'',
      selectedMemberPhone:'',
      selectedMemberName:'',
      selectedMemberSurname:'',
      freePayments:[],
      paymentInfo:{
        payTaxeNumber:1,
        payLateCharge:0,
        payTotalPrice:0,
        isPartialPay:false,
        paymentIds:[],
        purchasedProductId:null,
      },
      scheduleStartDate:new Date(),
      bookWithLevel:[],
      mailTemplates:[],
      smsTemplates:[],
      mailSendData:{
        id:0,
        memberId:"",
        to:"",
        subject:"",
        content:"",
        formSendLoading:false
      },
      smsSendData:{
        id:0,
        memberId:"",
        phoneNumber:"",
        content:"",
        formSendLoading:false
      },
      openMemberInfo:{},
      demoLessonData:{
        activeTeacherList:[],
        memberId:"",
        teacherId:"",
        skypeAddress:"",
        lessonDate:"",
        lessonTime:"",
        lessonDuration:0,
        password:"",
        demoLessonSendLoading:false
      },
      memberBill:{
        title: '',
        taxOffice: '',
        taxNumber: '',
        address: '',
        country: '',
        city: '',
        district: '',
        postCode: '',
      },
      taskModalVisible:false,
      taskModalMemberId:null,
      uploadFormModalVisible:false,
      memberProfileModalVisible:false,
      memberProfileModalMemberId:null
    }
  },
  computed: {
    ...mapGetters(["IS_ADMIN"]),
    isAdmin(){
      return this.IS_ADMIN;
    },
    GetCampaingsIdList:function(){
        let campaingArr=[];
        this.trainingPackage.selected.campaings.forEach(function(campaing){
            campaingArr.push(campaing[0].id);
        });
        return campaingArr;
    },
    paymentUrl() {
        return process.env.VUE_APP_PAYMENT_API;
    },
    cancellationOptions(){
      return reasonForCancellations;
    },
    cancellationOptionVisible(){
      return this.mainListType=='cancel';
    },
    saleUserVisible(){
      return this.IS_ADMIN;
    }

  },
  
  methods: {
    ...mapActions([
      "MEMBER_OLDLIST","MEMBER_NEWLIST","MEMBER_PREPARELIST","MEMBER_WAITINGLIST",
      "MEMBER_SEARCHLIST","MEMBER_DATELIST","MEMBER_FORMCANCEL","MEMBER_CANCELLIST",
      "MEMBER_FORMPREPARE","MEMBER_NOTELIST","MEMBER_SAVENOTE",
      "MEMBER_INFOSAVE","MEMBER_EMAILSAVE","MEMBER_PHONESAVE",
      "MEMBER_GETOPERATIONUSERSFORCHANGEUSER","MEMBER_FORMOPERATORSAVE","MEMBER_SALESAVE",
      "MEMBER_STUDENTOPERATORSAVE","TRAINING_PACKAGELIST","TRAINING_TEACHERTIMELIST",
      "TRAINING_CREATE","CCPAYMENT","MONEYORDERPAYMENT","GETFREEPAYMENTS","PAYMENTINTERNAL",
      "TRAINING_GETBOOKWITHLEVEL",
      "MEMBER_REGISTERFORM","MEMBER_REFERENCENUMBERSAVE",
      "SEND_MAIL","SEND_SMS","GETMAILTEMPLATES_FORFORM","GETSMSTEMPLATES_FORFORM",
      "MEMBER_GETREASONFORCANCELLATION","MEMBER_GETREASONFORDELAY","TRAINING_CREATEDEMOLESSON",
      "TEACHER_ACTIVELIST","MEMBER_CREATEBILL","MEMBER_GETBILLS","TEACHER_GETAVAILABLEHOURSALLTEACHER",
      "MEMBER_RETURNEDCANCELLATIONFORM"
    ]),
    OpenBillModal(){
      window.$("#BillModal").modal('show');
    },
    OpenSMSModal(form){
      this.openMemberInfo = form;
      this.smsSendData.phoneNumber=form.phoneNumber;
      this.smsSendData.id=0;
      this.smsSendData.memberId=form.id;
      this.smsSendData.formSendLoading=false;
      this.smsSendData.content="";
      this.GetSmsTemplates();
    },
    OpenMailModal(form){
      this.openMemberInfo = form;
      this.mailSendData.to=form.eMail;
      this.mailSendData.id=0;
      this.mailSendData.memberId=form.id;
      this.mailSendData.formSendLoading=false;
      this.mailSendData.subject="";
      this.mailSendData.content="";
      this.GetMailTemplates();
    },
    GetMailTemplates() {
      this.GETMAILTEMPLATES_FORFORM()
      .then((res)=>{
            this.mailTemplates=res.data;
            window.$("#NotifcationMailModal").modal('show');
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    SendMail() {
      this.mailSendData.formSendLoading=true;
      if(!confirm(this.mailSendData.to+' mail adresine gönderim yapmak istiyor musunuz?'))
      {
        this.mailSendData.formSendLoading=false;
        return;
      }

      this.SEND_MAIL(this.mailSendData)
      .then(()=>{
          this.mailSendData.formSendLoading=false;
          let message = "Başarıyla gönderildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
        }).catch(error=>{
          this.mailSendData.formSendLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    GetSmsTemplates() {
      this.GETSMSTEMPLATES_FORFORM()
      .then((res)=>{
            this.smsTemplates=res.data;
            window.$("#NotifcationSmsModal").modal('show');
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    SendSms() {
      this.smsSendData.formSendLoading=true;
      if(!confirm(this.smsSendData.phoneNumber+' telefon numarasına gönderim yapmak istiyor musunuz?'))
      {
        this.smsSendData.formSendLoading=false;
        return;
      }

      this.SEND_SMS(this.smsSendData)
      .then(()=>{
          this.smsSendData.formSendLoading=false;
          let message = "Başarıyla gönderildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
        }).catch(error=>{
          this.smsSendData.formSendLoading=false;
          if(this.IsShowErrorMessage(error)){
            this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
          }
        });
    },
    OpenCreateFormModal(){
        window.$("#CreateFormModal").modal('show');
    },
    OpenAddReferenceNumberModal(id){
        this.$refs.ReferenceNumberForm.resetForm();
        this.selectedMemberId=id;
        window.$("#AddReferenceNumberModal").modal('show');
    },
    OpenCancelModal(id,index){
        this.selectedItemIndex=index;
        this.$refs.CancelMemberForm.resetForm();
        this.$refs.CancelMemberForm.setFieldValue('CancelMemberFormCancelOption','');
        this.$refs.CancelMemberForm.setFieldValue('CancelMemberFormContent','');
        this.$refs.CancelMemberForm.setFieldValue('CancelMemberFormId',id);
        window.$("#CancelModal").modal('show');
    },
    OpenPrepareModal(id,index){
        this.selectedItemIndex=index;
        this.$refs.PrepareMemberForm.resetForm();
        this.$refs.PrepareMemberForm.setFieldValue('PrepareMemberFormOption','');
        //this.$refs.PrepareMemberForm.setFieldValue('PrepareMemberFormDate','');
        this.$refs.PrepareMemberForm.setFieldValue('PrepareMemberFormContent','');
        this.$refs.PrepareMemberForm.setFieldValue('PrepareMemberFormId',id);
        this.prepareDate=new Date(new Date().setDate(new Date().getDate()+1));
        window.$("#PrepareModal").modal('show');
    },
    OpenNoteListModal(id){
        window.$("#NoteListModal").modal('show');
        this.note.memberId=id;
        this.GetMemberNote(1,id)
    },
    OpenUpdateInfoModal(id,name,surname,gender){
        this.$refs.InfoMemberForm.resetForm();
        this.$refs.InfoMemberForm.setFieldValue('InfoMemberFormGenderOption',gender);
        this.$refs.InfoMemberForm.setFieldValue('InfoMemberFormSurname',surname);
        this.$refs.InfoMemberForm.setFieldValue('InfoMemberFormName',name);
        this.$refs.InfoMemberForm.setFieldValue('InfoMemberFormId',id);
        window.$("#UpdateInfoModal").modal('show');
    },
    OpenUpdateContactInfoModal(id,eMail,phoneNumber){

        this.$refs.ContactEmailMemberForm.resetForm();
        this.$refs.ContactEmailMemberForm.setFieldValue('ContactEmailMemberFormEmail',eMail);
        this.$refs.ContactEmailMemberForm.setFieldValue('ContactEmailMemberFormId',id);

        let phone = ParsePhoneNumber(phoneNumber);

        this.$refs.ContactPhoneMemberForm.resetForm();
        this.$refs.ContactPhoneMemberForm.setFieldValue('ContactPhoneMemberFormPhoneCode',phone.code);
        this.$refs.ContactPhoneMemberForm.setFieldValue('ContactPhoneMemberFormPhone',phone.number);
        this.$refs.ContactPhoneMemberForm.setFieldValue('ContactPhoneMemberFormId',id);

        window.$("#UpdateContactInfoModal").modal('show');
    },
    OpenUpdateUserModal(id,members){
        this.$refs.UserStudentOperatorMemberForm.resetForm();
        this.$refs.UserFormOperatorMemberForm.resetForm();
        this.$refs.UserSaleMemberForm.resetForm();

        let studentOperator = members.filter(x=>x.role===2)[0].userId;
        let formOperator = members.filter(x=>x.role===3)[0].userId;
        let sales = members.filter(x=>x.role===4)[0].userId;
        this.GetOperationUsers(formOperator,studentOperator,sales);

        this.$refs.UserStudentOperatorMemberForm.setFieldValue('UserStudentOperatorMemberFormId',id);
        this.$refs.UserFormOperatorMemberForm.setFieldValue('UserFormOperatorMemberFormId',id);
        this.$refs.UserSaleMemberForm.setFieldValue('UserSaleMemberFormId',id);

    },
    OpenPackageModal(id,mail,phone,name,surname){
      this.selectedMemberId=id;
      this.selectedMemberEmail=mail;
      this.selectedMemberPhone=phone;
      this.selectedMemberName=name;
      this.selectedMemberSurname=surname;
      this.GetPackages();
      this.stepper.currentStep=1;
      this.stepper.stepBlock1=true;
      this.stepper.stepBlock2=false;
      this.stepper.stepBlock3=false;
      this.stepper.stepBlock4=false;
      this.stepper.progressValue=0;
      
      this.PackageInit();
      this.GetBookWithLevel();
      window.$("#PackageModal").modal('show');

    },
    OpenCloseTaskModal(value,memberId){
      this.taskModalVisible=value;
      this.taskModalMemberId=memberId;
    },
    OpenCloseUploadFormModal(value){
      this.uploadFormModalVisible=value;
    },
    OpenCloseMemberProfileModal(value,memberId){
      this.memberProfileModalVisible=value;
      this.memberProfileModalMemberId=memberId;
    },
    
    OpenDemoLessonModal(id){
      this.demoLessonData.memberId=id;
      this.TEACHER_ACTIVELIST()
      .then((res)=>{
        this.demoLessonData.activeTeacherList = res.data;
        window.$("#DemoLessonModal").modal('show');
        }).catch(error=>{
           if(this.IsShowErrorMessage(error)){
             this.$toast.error(error,{
                 position : 'top',
                 duration : 3000
             });
           }
        });
    },
    FormStatus(value){
      return GetFormStatus(value);
    },
    Role(value){
      return GetRoleText(value);
    },
    DateFormat(value){
      return DateFormat(value,'dd.mm.yyyy hh:MM:ss');
    },
    DateFormatForListTitle(value){
      return DateFormat(value,'dd.mm.yyyy');
    },
    DateFormatForCancelList(value){
      return DateFormat(value,'yyyy-mm-dd');
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    CalculateTimeByOffset(value){
        return CalculateTime(value);
    },
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    GetCurrencyText(value){
      return GetCurrencyText(value);
    },
    GetPaymentTypeText(value){
      return GetPaymentTypeText(value);
    },
    ListButtonClick(type){
      this.listType = type;
      this.GetMemberFormList(1);
    },
    MainListChange(){
      this.listType = this.mainListType;
      if(this.listType!=='cancel')
        this.GetMemberFormList(1);
    },
    GetMemberFormList(page){
        switch (this.listType) {
          case 'old':
            this.GetOldMember(page);
            break;
          case 'new':
            this.GetNewMember(page);
            break;
          case 'prepare':
            this.GetPrepareMember(page);
            break;
          case 'waiting':
            this.GetWaitingMember(page);
            break; 
          case 'cancel':{
            this.GetCancelMember(page,this.rangeDate.start,this.rangeDate.end,this.mainListCancelType);
            break;
          }  
          case 'dateSearch':{
            this.GetDateMember(page,this.rangeDate.start,this.rangeDate.end);
            break;
          }
          case 'returnedcancellationform':{
            this.GetReturnedCancellationMember(page);
            break;
          }
          case 'textSearch':
           {
             this.GetSearchMember(page,this.searchTextType,this.searchText)
             break;
           }
          default:
            break;
        }
    },
    GetOldMember(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Eski Form';
      if(!page) {  page=1; }

      this.MEMBER_OLDLIST({page:page,user:this.mainListSaleUserId})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch((error)=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetNewMember(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Yeni Form';
      if(!page) {  page=1; }

      this.MEMBER_NEWLIST({page:page,user:this.mainListSaleUserId})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetPrepareMember(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Gelecek Form';
      if(!page) {  page=1; }

      this.MEMBER_PREPARELIST({page:page,user:this.mainListSaleUserId})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetReturnedCancellationMember(page){
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Yeniden Dağıtılan İptal Form';
      if(!page) {  page=1; }

      this.MEMBER_RETURNEDCANCELLATIONFORM({page:page,user:this.mainListSaleUserId})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetWaitingMember(page) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Bekleyen Form';
      if(!page) {  page=1; }

      this.MEMBER_WAITINGLIST({page:page,user:this.mainListSaleUserId})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetCancelMember(page,beginDate,endDate,reasonForCancellation) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle = this.DateFormatForListTitle(beginDate) + ' - ' + this.DateFormatForListTitle(endDate) + ' Arasındaki İptal Edilen Formlar';
      if(!page) {  page=1; }

      this.MEMBER_CANCELLIST({page,beginDate:this.DateFormatForCancelList(beginDate),endDate:this.DateFormatForCancelList(endDate),reasonForCancellation,user:this.mainListSaleUserId})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },

    
    GetSearchMember(page,searchField,searchText) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle='Form Arama';
      if(!page) {  page=1; }

      this.MEMBER_SEARCHLIST({page,searchField,searchText})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetDateMember(page,beginDate,endDate) {
      this.dataLoading=true;
      this.currentPage=0;
      this.items=[];
      this.pageSize=0;
      this.totalItems=0;
      this.totalPages=0;
      this.listTitle=this.DateFormatForListTitle(beginDate) + ' - ' + this.DateFormatForListTitle(endDate) + ' Arasındaki Formlar';
      if(!page) {  page=1; }

      this.MEMBER_DATELIST({page,beginDate,endDate,user:this.mainListSaleUserId})
      .then((res)=>{
            this.currentPage=res.data.currentPage;
            this.items = res.data.items;
            this.pageSize=res.data.pageSize;
            this.totalItems=res.data.totalItems;
            this.totalPages=res.data.totalPages;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetMemberNote(page) {
      this.note.dataLoading=true;
      let memberId = this.note.memberId;
      if(!page) {  page=1; }

      this.MEMBER_NOTELIST({page,memberId})
      .then((res)=>{
            this.note.currentPage=res.data.currentPage;
            this.note.items = res.data.items;
            this.note.pageSize=res.data.pageSize;
            this.note.totalItems=res.data.totalItems;
            this.note.totalPages=res.data.totalPages;
            this.note.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.note.dataLoading=false;
        });
    },
    GetOperationUsers(formOperator,studentOperator,sales,aloneUser = false) {
      this.MEMBER_GETOPERATIONUSERSFORCHANGEUSER()
      .then((res)=>{
            this.operationUsers=res.data;           
            if(!aloneUser){
              this.$refs.UserStudentOperatorMemberForm.setFieldValue('UserStudentOperatorOption',(studentOperator==null?'':studentOperator));
              this.$refs.UserFormOperatorMemberForm.setFieldValue('UserFormOperatorOption',(formOperator==null?'':formOperator));
              this.$refs.UserSaleMemberForm.setFieldValue('UserSaleOption',(sales==null?'':sales));
              window.$("#UpdateUserModal").modal('show');
            }
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    GetBookWithLevel() {
      this.TRAINING_GETBOOKWITHLEVEL()
      .then((res)=>{
            this.bookWithLevel=res.data;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    GetPackages() {
      this.TRAINING_PACKAGELIST()
      .then((res)=>{
            this.packages = res.data;
            this.dataLoading=false;
            this.GetFilterPackage();
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    CreateOptionInPacket(packages,title){
        let options={};
        if(packages.length>0){
          options = {
            title : title,
            packages : []
          };
          packages.forEach(function(p){
              options.packages.push({
                  id:p.id,
                  title: p.month>0?(p.month+" Ay " + p.duration + " dk (Haftada " + p.weekDay + " gün)"):'Kendini Dene (Hafta içi ' + p.weekDay + ' gün '+ p.duration + ' dk)'
              });
          });
        }
        return options;
    },
    GetFilterPackage(){
        let options = [];
        let tryYourself = this.packages.filter(x=>x.month===0 && x.weekDay===5).sort((a, b) => a.duration - b.duration);
        if(tryYourself.length>0){
            if(tryYourself.length>0) options.push(this.CreateOptionInPacket(tryYourself,"KENDİNİ DENE PAKETLERI (Haftada 5 Gün)"));
        }
        
        let month15 = this.packages.filter(x=>x.month===1 && x.weekDay===5).sort((a, b) => a.duration - b.duration);
        let month13 = this.packages.filter(x=>x.month===1 && x.weekDay===3).sort((a, b) => a.duration - b.duration);
        if(month15.length>0 || month13.length>0){
            if(month15.length>0) options.push(this.CreateOptionInPacket(month15,"1 AY PAKETLER (Haftada 5 Gün)"));
            if(month13.length>0) options.push(this.CreateOptionInPacket(month13,"1 AY PAKETLER (Haftada 3 Gün)"));
        }

        let month25 = this.packages.filter(x=>x.month===2 && x.weekDay===5).sort((a, b) => a.duration - b.duration);
        let month23 = this.packages.filter(x=>x.month===2 && x.weekDay===3).sort((a, b) => a.duration - b.duration);
        let month22 = this.packages.filter(x=>x.month===2 && x.weekDay===2).sort((a, b) => a.duration - b.duration);
        if(month25.length>0 || month23.length>0 || month22.length>0){
            if(month25.length>0) options.push(this.CreateOptionInPacket(month25,"2 AY PAKETLER (Haftada 5 Gün)"));
            if(month23.length>0) options.push(this.CreateOptionInPacket(month23,"2 AY PAKETLER (Haftada 3 Gün)"));
            if(month22.length>0) options.push(this.CreateOptionInPacket(month22,"2 AY PAKETLER (Haftada 2 Gün)"));
        }
        
        let month45 = this.packages.filter(x=>x.month===4 && x.weekDay===5).sort((a, b) => a.duration - b.duration);
        let month43 = this.packages.filter(x=>x.month===4 && x.weekDay===3).sort((a, b) => a.duration - b.duration);
        let month42 = this.packages.filter(x=>x.month===4 && x.weekDay===2).sort((a, b) => a.duration - b.duration);
        if(month45.length>0 || month43.length>0 || month42.length>0){
            if(month45.length>0) options.push(this.CreateOptionInPacket(month45,"4 AY PAKETLER (Haftada 5 Gün)"));
            if(month43.length>0) options.push(this.CreateOptionInPacket(month43,"4 AY PAKETLER (Haftada 3 Gün)"));
            if(month42.length>0) options.push(this.CreateOptionInPacket(month42,"4 AY PAKETLER (Haftada 2 Gün)"));
        }

        let month65 = this.packages.filter(x=>x.month===6 && x.weekDay===5).sort((a, b) => a.duration - b.duration);
        let month63 = this.packages.filter(x=>x.month===6 && x.weekDay===3).sort((a, b) => a.duration - b.duration);
        let month62 = this.packages.filter(x=>x.month===6 && x.weekDay===2).sort((a, b) => a.duration - b.duration);
        if(month65.length>0 || month63.length>0 || month62.length>0){
            if(month65.length>0) options.push(this.CreateOptionInPacket(month65,"6 AY PAKETLER (Haftada 5 Gün)"));
            if(month63.length>0) options.push(this.CreateOptionInPacket(month63,"6 AY PAKETLER (Haftada 3 Gün)"));
            if(month62.length>0) options.push(this.CreateOptionInPacket(month62,"6 AY PAKETLER (Haftada 2 Gün)"));
        }

        let month125 = this.packages.filter(x=>x.month===12 && x.weekDay===5).sort((a, b) => a.duration - b.duration);
        let month123 = this.packages.filter(x=>x.month===12 && x.weekDay===3).sort((a, b) => a.duration - b.duration);
        let month122 = this.packages.filter(x=>x.month===12 && x.weekDay===2).sort((a, b) => a.duration - b.duration);
        if(month125.length>0 || month123.length>0 || month122.length>0){
            if(month125.length>0) options.push(this.CreateOptionInPacket(month125,"12 AY PAKETLER (Haftada 5 Gün)"));
            if(month123.length>0) options.push(this.CreateOptionInPacket(month123,"12 AY PAKETLER (Haftada 3 Gün)"));
            if(month122.length>0) options.push(this.CreateOptionInPacket(month122,"12 AY PAKETLER (Haftada 2 Gün)"));
        }

        this.packageOptions = options;
    },
    
    GetTeacherTimes() {
      this.dataLoading=true;
      this.TEACHER_GETAVAILABLEHOURSALLTEACHER()
      .then((res)=>{
          this.teacherTimes = res.data;
          this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },

    GetFreePayments() {
      this.GETFREEPAYMENTS(this.selectedMemberId)
      .then((res)=>{
            this.freePayments = res.data;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    SelectBill(billId){
      let bill = this.trainingPackage.billInfos.find(x=>x.id==billId);
      if(bill){
        this.trainingPackage.selectBill.id = bill.id;
        this.trainingPackage.selectBill.title = bill.title;
        this.trainingPackage.selectBill.taxOffice= bill.taxOffice;
        this.trainingPackage.selectBill.taxNumber= bill.taxNumber;
        this.trainingPackage.selectBill.address= bill.address;
        this.trainingPackage.selectBill.country= bill.country;
        this.trainingPackage.selectBill.city= bill.city;
        this.trainingPackage.selectBill.district= bill.district;
        this.trainingPackage.selectBill.postCode= bill.postCode;
      }
    },
    GetBillInfos() {
      this.MEMBER_GETBILLS(this.selectedMemberId)
      .then((res)=>{
            this.trainingPackage.billInfos = res.data;
            this.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    SaveMemberBill(){
      this.modalSubmitLoading=true;
      if(this.memberBill.title===''){
        this.modalSubmitLoading=false;
        this.$toast.error("Başlık giriniz.",{position : 'top',duration : 3000});
        return;
      }

      if(this.memberBill.taxNumber==='' || this.memberBill.taxNumber.length<10){
        this.modalSubmitLoading=false;
        this.$toast.error("Vergi numarasını giriniz.[10 ya da 11 hane olmalı]",{position : 'top',duration : 3000});
        return;
      }

      if(this.memberBill.taxOffice===''){
        this.modalSubmitLoading=false;
        this.$toast.error("Vergi dairesini giriniz.",{position : 'top',duration : 3000});
        return;
      }
      let model = {
        memberId:this.selectedMemberId,
        title: this.memberBill.title,
        taxOffice: this.memberBill.taxOffice,
        taxNumber: this.memberBill.taxNumber,
        address: this.memberBill.address,
        country: this.memberBill.country,
        city: this.memberBill.city,
        district: this.memberBill.district,
        postCode: this.memberBill.postCode,
      };
      this.MEMBER_CREATEBILL(model)
      .then(()=>{
            this.$toast.success("Fatura bilgisi başarıyla kaydedildi.",{
                position : 'top',
                duration : 3000
            });
            this.memberBill.title = "";
            this.memberBill.taxOffice = "";
            this.memberBill.taxNumber = "";
            this.memberBill.address = "";
            this.memberBill.country = "";
            this.memberBill.city = "";
            this.memberBill.district = "";
            this.memberBill.postCode = "";
            this.modalSubmitLoading=false;
            this.GetBillInfos();
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading=false;
        });

    },
    OnSelectPackage(){
        let selectPackage = this.packages.filter(x=>x.id===this.trainingPackage.selected.packageId);
        this.trainingPackage.package={};
        this.trainingPackage.selected.campaings=[];
        this.trainingPackage.selectedTraining={month:0,freezeLesson:0,missed:0,postpone:0,teacherChange:0};
        this.trainingPackage.selectedCampaign={month:0,freezeLesson:0,missed:0,postpone:0,teacherChange:0};
        this.trainingPackage.price=0;
        this.trainingPackage.discount=0;
        this.trainingPackage.totalPrice=0;
        this.trainingPackage.twoTax=0;
        this.trainingPackage.threeTax=0;
        this.trainingPackage.fourTax=0;
        this.trainingPackage.fiveTax=0;
        this.trainingPackage.sixTax=0;
        this.trainingPackage.sevenTax=0;
        this.trainingPackage.eightTax=0;
        this.trainingPackage.nineTax=0;
        this.trainingPackage.tenTax=0;
        this.trainingPackage.elevenTax=0;
        this.trainingPackage.twelveTax=0;
        this.trainingPackage.taxes=[];
        this.trainingPackage.selectedTaxe={taxeNumber:1,title:"Tek Çekim",lateCharge:0,taxePrice:0,totalPrice:0};
        this.selectedTeacher = {};
        if(selectPackage.length>0){
          this.trainingPackage.package = selectPackage[0];
          this.CalculatePackageFare();
        }
        
    },
    OnCheckCampaign(e){
        let campaignId = e.target.value;
         if(e.target.checked){
           let campaing = this.trainingPackage.package.campaigns.filter(x=>x.id===campaignId);

           let findIndex = this.trainingPackage.selected.campaings.findIndex(x=>x[0].id === campaignId);
           if(findIndex===-1) {
              this.trainingPackage.selected.campaings.push(JSON.parse(JSON.stringify(campaing)));
           }
         }else{
             let deleteIndex = this.trainingPackage.selected.campaings.findIndex(x=>x[0].id === campaignId);
             if(deleteIndex>-1) {
               this.trainingPackage.selected.campaings.splice(deleteIndex, 1);
             }
         }
         this.CalculatePackageFare();
    },
    CalculatePackageFare(){
      this.trainingPackage.selectedTraining.duration = this.trainingPackage.package.duration;
      this.trainingPackage.selectedTraining.freezeLesson = this.trainingPackage.package.packageRight.freezeLesson;
      this.trainingPackage.selectedTraining.missed = this.trainingPackage.package.packageRight.missed;
      this.trainingPackage.selectedTraining.postpone = this.trainingPackage.package.packageRight.postpone;
      this.trainingPackage.selectedTraining.teacherChange = this.trainingPackage.package.packageRight.teacherChange;
      this.trainingPackage.selectedTraining.month = this.trainingPackage.package.month;

      let freezeLesson = 0;
      let missed= 0;
      let postpone= 0;
      let teacherChange= 0;
      let discountRate = 0;
      let month = 0;

      let twoTax= this.trainingPackage.package.price.lateCharge.twoTax;
      let threeTax= this.trainingPackage.package.price.lateCharge.threeTax;
      let fourTax= this.trainingPackage.package.price.lateCharge.fourTax;
      let fiveTax= this.trainingPackage.package.price.lateCharge.fiveTax;
      let sixTax= this.trainingPackage.package.price.lateCharge.sixTax;
      let sevenTax= this.trainingPackage.package.price.lateCharge.sevenTax;
      let eightTax= this.trainingPackage.package.price.lateCharge.eightTax;
      let nineTax= this.trainingPackage.package.price.lateCharge.nineTax;
      let tenTax= this.trainingPackage.package.price.lateCharge.tenTax;
      let elevenTax= this.trainingPackage.package.price.lateCharge.elevenTax;
      let twelveTax= this.trainingPackage.package.price.lateCharge.twelveTax;

      this.trainingPackage.selected.campaings.forEach(function(campaing){
          freezeLesson = freezeLesson + campaing[0].packageRight.freezeLesson;
          missed = missed + campaing[0].packageRight.missed;
          postpone = postpone + campaing[0].packageRight.postpone;
          teacherChange = teacherChange + campaing[0].packageRight.teacherChange;
          discountRate = discountRate + campaing[0].price.discountRate;
          month = month + campaing[0].month;

          twoTax = (twoTax > campaing[0].price.lateCharge.twoTax) ? campaing[0].price.lateCharge.twoTax : twoTax;
          threeTax = (threeTax > campaing[0].price.lateCharge.threeTax) ? campaing[0].price.lateCharge.threeTax : threeTax;
          fourTax = (fourTax > campaing[0].price.lateCharge.fourTax) ? campaing[0].price.lateCharge.fourTax : fourTax;
          fiveTax = (fiveTax > campaing[0].price.lateCharge.fiveTax) ? campaing[0].price.lateCharge.fiveTax : fiveTax;
          sixTax = (sixTax > campaing[0].price.lateCharge.sixTax) ? campaing[0].price.lateCharge.sixTax : sixTax;
          sevenTax = (sevenTax > campaing[0].price.lateCharge.sevenTax) ? campaing[0].price.lateCharge.sevenTax : sevenTax;
          eightTax = (eightTax > campaing[0].price.lateCharge.eightTax) ? campaing[0].price.lateCharge.eightTax : eightTax;
          nineTax = (nineTax > campaing[0].price.lateCharge.nineTax) ? campaing[0].price.lateCharge.nineTax : nineTax;
          tenTax = (tenTax > campaing[0].price.lateCharge.tenTax) ? campaing[0].price.lateCharge.tenTax : tenTax;
          elevenTax = (elevenTax > campaing[0].price.lateCharge.elevenTax) ? campaing[0].price.lateCharge.elevenTax : elevenTax;
          twelveTax = (twelveTax > campaing[0].price.lateCharge.twelveTax) ? campaing[0].price.lateCharge.twelveTax : twelveTax;
      });

      this.trainingPackage.twoTax= twoTax;
      this.trainingPackage.threeTax= threeTax;
      this.trainingPackage.fourTax= fourTax;
      this.trainingPackage.fiveTax= fiveTax;
      this.trainingPackage.sixTax= sixTax;
      this.trainingPackage.sevenTax= sevenTax;
      this.trainingPackage.eightTax= eightTax;
      this.trainingPackage.nineTax= nineTax;
      this.trainingPackage.tenTax= tenTax;
      this.trainingPackage.elevenTax= elevenTax;
      this.trainingPackage.twelveTax= twelveTax;

      this.trainingPackage.selectedCampaign.month = month;
      this.trainingPackage.selectedCampaign.freezeLesson = freezeLesson;
      this.trainingPackage.selectedCampaign.missed = missed;
      this.trainingPackage.selectedCampaign.postpone = postpone;
      this.trainingPackage.selectedCampaign.teacherChange = teacherChange;

      this.trainingPackage.price = this.trainingPackage.package.price.baseFare;
      this.trainingPackage.discount = (this.trainingPackage.price * discountRate).toFixed(2);
      this.trainingPackage.totalPrice = (this.trainingPackage.price - this.trainingPackage.discount).toFixed(2);
      
      this.trainingPackage.taxRate = this.trainingPackage.package.price.taxRate;
      this.trainingPackage.taxAmount = (this.trainingPackage.totalPrice * this.trainingPackage.package.price.taxRate).toFixed(2);
      this.trainingPackage.totalPrice = (parseFloat(this.trainingPackage.totalPrice) + parseFloat(this.trainingPackage.taxAmount)).toFixed(2);

      let taxes = [];
      this.trainingPackage.selectedTaxe = {taxeNumber:1,title:"Tek Çekim",lateCharge:0,taxePrice:this.trainingPackage.totalPrice,totalPrice:this.trainingPackage.totalPrice};
      taxes.push({taxeNumber:1,title:"Tek Çekim",lateCharge:0,taxePrice:this.trainingPackage.totalPrice,totalPrice:this.trainingPackage.totalPrice});
      
      for(let i=2;i<=12;i++){
        let tax = 0;
        switch (i) {
          case 2:
            tax = this.trainingPackage.twoTax;
            break;
          case 3:
            tax = this.trainingPackage.threeTax;
            break;
          case 4:
            tax = this.trainingPackage.fourTax;
            break;
          case 5:
            tax = this.trainingPackage.fiveTax;
            break;
          case 6:
            tax = this.trainingPackage.sixTax;
            break;
          case 7:
            tax = this.trainingPackage.sevenTax;
            break;
          case 8:
            tax = this.trainingPackage.eightTax;
            break;
          case 9:
            tax = this.trainingPackage.nineTax;
            break;
          case 10:
            tax = this.trainingPackage.tenTax;
            break;
          case 11:
            tax = this.trainingPackage.elevenTax;
            break;
          case 12:
            tax = this.trainingPackage.twelveTax;
            break;
        }

        taxes.push({
          taxeNumber:i,
          title:i+" Taksit",
          lateCharge:(parseFloat(this.trainingPackage.totalPrice * tax)).toFixed(2),
          taxePrice:((parseFloat(this.trainingPackage.totalPrice) + parseFloat(this.trainingPackage.totalPrice * tax))/i).toFixed(2),
          totalPrice:(parseFloat(this.trainingPackage.totalPrice) + parseFloat(this.trainingPackage.totalPrice * tax)).toFixed(2)
        });
      }

      this.trainingPackage.taxes=taxes;

    },
    SaveCancelMemberForm(form){
        this.modalSubmitLoading = true;
        let id = form.CancelMemberFormId;
        let why = form.CancelMemberFormCancelOption;
        let content = form.CancelMemberFormContent;
        this.MEMBER_FORMCANCEL({
          id:id,
          why:why,
          content:content
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            window.$("#CancelModal").modal('hide');
            this.$refs.CancelMemberForm.setFieldValue('CancelMemberFormId','');
            this.$refs.CancelMemberForm.setFieldValue('CancelMemberFormCancelOption','');
            this.$refs.CancelMemberForm.setFieldValue('CancelMemberFormContent','');
            let message = "Başarıyla iptal edildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
            
            this.items.splice(this.selectedItemIndex,1);
            //this.GetMemberFormList(this.currentPage);
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
          });
    },
    SavePrepareMemberForm(form){
        this.modalSubmitLoading = true;
        let id = form.PrepareMemberFormId;
        let why = form.PrepareMemberFormOption;
        let content = form.PrepareMemberFormContent;
        this.MEMBER_FORMPREPARE({
          id:id,
          why:why,
          prepareDate:this.prepareDate,
          content:content
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            window.$("#PrepareModal").modal('hide');
            this.prepareDate=new Date(new Date().setDate(new Date().getDate()+1));
            this.$refs.PrepareMemberForm.setFieldValue('PrepareMemberFormId','');
            this.$refs.PrepareMemberForm.setFieldValue('PrepareMemberFormOption','');
            //this.$refs.PrepareMemberForm.setFieldValue('PrepareMemberFormDate','');
            this.$refs.PrepareMemberForm.setFieldValue('PrepareMemberFormContent','');
            let message = "Başarıyla ertelendi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
            
            this.items.splice(this.selectedItemIndex,1);
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
        });
    },
    CreateDemoLesson(){
        if(!confirm('Demo ders oluşturulsun mu?'))  
          return;

        this.demoLessonData.demoLessonSendLoading=true;
        this.TRAINING_CREATEDEMOLESSON ({
          memberId:this.demoLessonData.memberId,
          teacherId:this.demoLessonData.teacherId,
          skypeAddress:this.demoLessonData.skypeAddress,
          lessonDate:this.demoLessonData.lessonDate,
          lessonTime:this.demoLessonData.lessonTime,
          lessonDuration:this.demoLessonData.lessonDuration,
          password:this.demoLessonData.password
          }
        )
        .then(()=>{
            this.demoLessonData.demoLessonSendLoading=false;

            this.demoLessonData.activeTeacherList=[];
            this.demoLessonData.memberId="";
            this.demoLessonData.teacherId="";
            this.demoLessonData.skypeAddress="";
            this.demoLessonData.lessonDate="";
            this.demoLessonData.lessonTime="";
            this.demoLessonData.lessonDuration=0;
            this.demoLessonData.password="";

            let message = "Demo ders başarıyla oluşturuldu.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.demoLessonData.demoLessonSendLoading=false;
        });
    },
    SaveFormRegister(form){
       this.modalSubmitLoading = true;
        let name = form.FormName;
        let surname = form.FormSurname;
        let phoneCountryCode = form.FormPhoneCountryCode;
        let phoneNumber = form.FormPhoneNumber;
        let eMail = form.FormEMail;
        let utmSource = form.FormUtmSource;
        let utmContent = form.FormUtmContent;
        this.MEMBER_REGISTERFORM({
          name:name,
          surname:surname,
          phoneCountryCode:phoneCountryCode,
          phoneNumber:phoneNumber,
          eMail:eMail,
          utmSource:utmSource,
          utmContent:utmContent
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            this.$refs.FormRegister.resetForm();
            let message = "Başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
        });
    },
    SaveNoteMemberForm(form){
        this.modalSubmitLoading = true;
        let id = this.note.memberId;
        let content = form.NoteMemberFormContent;
        this.MEMBER_SAVENOTE({
          memberId:id,
          content:content
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            this.$refs.NoteMemberForm.setFieldValue('NoteMemberFormContent','');
            let message = "Başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });

            this.GetMemberNote(1);
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
        });
    },
    SaveInfoMemberForm(form){
        this.modalSubmitLoading = true;
        let id = form.InfoMemberFormId;
        let name = form.InfoMemberFormName;
        let surname = form.InfoMemberFormSurname;
        let gender = form.InfoMemberFormGenderOption;
        this.MEMBER_INFOSAVE({
          memberId:id,
          name:name,
          surname:surname,
          gender:gender
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            window.$("#UpdateInfoModal").modal('hide');
            let message = "Bilgiler başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });

            this.GetMemberFormList(this.currentPage);
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
          });
    },
    SaveContactEmailMemberForm(form){
        this.modalSubmitLoading = true;
        let id = form.ContactEmailMemberFormId;
        let eMail = form.ContactEmailMemberFormEmail;
        this.MEMBER_EMAILSAVE({
          memberId:id,
          eMail:eMail,
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            let message = "E-posta bilgisi başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
            this.GetMemberFormList(this.currentPage);
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
          });
    },
    SaveContactPhoneMemberForm(form){
        this.modalSubmitLoading = true;
        let id = form.ContactPhoneMemberFormId;
        let phoneCode = form.ContactPhoneMemberFormPhoneCode;
        let phoneNumber = form.ContactPhoneMemberFormPhone;
        this.MEMBER_PHONESAVE({
          memberId:id,
          phoneCode:phoneCode,
          phoneNumber:phoneNumber,
          }
        )
        .then(()=>{
            this.modalSubmitLoading=false;
            let message = "Telefon bilgisi başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
              });
            this.GetMemberFormList(this.currentPage);
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.modalSubmitLoading=false;
          });
    },
    SaveUserStudentOperatorMemberForm(form){
      this.modalSubmitLoading = true;
      let id = form.UserStudentOperatorMemberFormId;
      let studentOperator = form.UserStudentOperatorOption;
      this.MEMBER_STUDENTOPERATORSAVE({
        memberId:id,
        userId:studentOperator,
        }
      )
      .then(()=>{
          this.modalSubmitLoading=false;
          let message = "Öğrenci temsilcisi başarıyla kaydedildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.GetMemberFormList(this.currentPage);
      })
      .catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading=false;
        });
    },
    SaveUserSaleMemberForm(form){
      this.modalSubmitLoading = true;
      let id = form.UserSaleMemberFormId;
      let sale = form.UserSaleOption;
      this.MEMBER_SALESAVE({
        memberId:id,
        userId:sale,
        }
      )
      .then(()=>{
          this.modalSubmitLoading=false;
          let message = "Satış temsilcisi başarıyla kaydedildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.GetMemberFormList(this.currentPage);
      })
      .catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading=false;
        });
    },
    SaveReferenceNumber(form){
      this.modalSubmitLoading = true;
      let referenceNumber = form.ReferenceNumber;
      let memberId = this.selectedMemberId;
      this.MEMBER_REFERENCENUMBERSAVE({
        memberId:memberId,
        referenceNumber:referenceNumber,
        }
      )
      .then(()=>{
          this.modalSubmitLoading=false;
          let message = "Referans numarası başarıyla kaydedildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.GetMemberFormList(this.currentPage);
      })
      .catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading=false;
        });
    },
    SaveUserFormOperatorMemberForm(form){
      this.modalSubmitLoading = true;
      let id = form.UserFormOperatorMemberFormId;
      let formOperator = form.UserFormOperatorOption;
      this.MEMBER_FORMOPERATORSAVE({
        memberId:id,
        userId:formOperator,
        }
      )
      .then(()=>{
          this.modalSubmitLoading=false;
          let message = "Form temsilcisi başarıyla kaydedildi.";
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.GetMemberFormList(this.currentPage);
      })
      .catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading=false;
        });
    },
    SubmitPayCreditCardForm(form){
      let formThis = this;
      formThis.modalSubmitLoading=true;

      if(this.trainingPackage.selectBill.id=='')
      {
         alert('Fatura bilgisi seçmelisiniz.');
         formThis.modalSubmitLoading=false;
         return; 
      }

      let payTotalPrice =formThis.paymentInfo.payTotalPrice;
      if(!confirm(parseFloat(payTotalPrice).toFixed(2) + ' TL kredi kartından çekim yapmak istiyor musunuz?'))
      {
        formThis.modalSubmitLoading=false;
        return;
      }
      let model = {
          platform: 3,
          form:{
            memberId:this.selectedMemberId
          },
          invoice:{
            id:this.trainingPackage.selectBill.id
          },
          productInfo: {
              productId: this.trainingPackage.selected.packageId,
              campaigns: this.GetCampaingsIdList
          },
          payment: {
              paymentType: 1,
              cardNumber: form.CardNumber,
              cardHolder: form.CardHolder,
              cardExpireDate: form.CardExpireDate,
              cardCvv: form.CardCvv,
              installment: form.CardPayTaxeNumber,
              isThreeD: form.Pay3DChk,
              amount:payTotalPrice,
              currency:3
          }
      }

      this.PAYMENTINTERNAL(model)
      .then((result)=>{
          let non3DUrl = formThis.paymentUrl + '?q=' + result.data.paymentId;
          window.$('#PaymentModal').modal({backdrop: 'static',keyboard: false, show: true});
          window.$('#PaymentIFrame').attr('src',non3DUrl);  
      })
      .catch(error=>{
        if(formThis.IsShowErrorMessage(error)){
          formThis.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            formThis.modalSubmitLoading=false;
      });
    },
    SubmitPayMoneyOrderForm(){
      this.modalSubmitLoading=true;
      let formThis = this;

      if(this.trainingPackage.selectBill.id=='')
      {
         alert('Fatura bilgisi seçmelisiniz.');
         formThis.modalSubmitLoading=false;
         return; 
      }

      let payTotalPrice = this.paymentInfo.payTotalPrice;
      if(!confirm(parseFloat(payTotalPrice).toFixed(2) + ' TL havale kaydı yapmak istiyor musunuz?'))
      {
        formThis.modalSubmitLoading=false;
        return;
      }
      let model = {
          platform: 3,
          form:{
            memberId:this.selectedMemberId
          },
          invoice:{
            id:this.trainingPackage.selectBill.id
          },
          productInfo: {
              productId: this.trainingPackage.selected.packageId,
              campaigns: this.GetCampaingsIdList
          },
          payment: {
              paymentType: 2,
              amount:0,
              currency:3
          }
      }

      this.PAYMENTINTERNAL(model)
      .then((result)=>{
        formThis.paymentInfo.purchasedProductId=result.data.purchasedProductId;
        formThis.modalSubmitLoading=false;
        let message = 'Ödeme işlemi başarıyla gerçekleşti.'
        formThis.$toast.success(message,{
          position : 'top',
          duration : 3000
        });

        formThis.GetFreePayments() 
      })
      .catch(error=>{
        if(formThis.IsShowErrorMessage(error)){
          formThis.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            formThis.modalSubmitLoading=false;
      });
    },
    paymentModalClose(){
      window.$('#PaymentIFrame').attr('src',''); 
      window.$('#PaymentModal').modal('hide');
      this.modalSubmitLoading=false;
      this.GetFreePayments();
    },
    onClickStep: function(step) {

      if(step==1){
        this.stepper.currentStep++;
        if(this.stepper.currentStep>this.stepper.maxStep)
        {
          this.stepper.currentStep=this.stepper.maxStep;
        }
      }else{
        this.stepper.currentStep--;
        if(this.stepper.currentStep<1)
        {
          this.stepper.currentStep=1;
        }
      }

      switch (this.stepper.currentStep) {
        case 1:
          this.stepper.stepBlock1=true;
          this.stepper.stepBlock2=false;
          this.stepper.stepBlock3=false;
          this.stepper.stepBlock4=false;
          this.stepper.progressValue=0;
          break;
        case 2:
          this.paymentInfo.payTotalPrice = parseFloat((this.trainingPackage.selectedTaxe)?this.trainingPackage.selectedTaxe.totalPrice:0).toFixed(2);
          this.paymentInfo.payLateCharge = parseFloat((this.trainingPackage.selectedTaxe)?this.trainingPackage.selectedTaxe.lateCharge:0).toFixed(2);
          this.paymentInfo.payTaxeNumber = (this.trainingPackage.selectedTaxe)?this.trainingPackage.selectedTaxe.taxeNumber:0;
          this.$refs.PayCreditCardForm.setFieldValue('CardPayTaxeNumber',this.paymentInfo.payTaxeNumber);

          this.stepper.stepBlock1=false;
          this.stepper.stepBlock2=true;
          this.stepper.stepBlock3=false;
          this.stepper.stepBlock4=false;
          this.stepper.progressValue=33;
          this.GetFreePayments();
          this.GetBillInfos();
          break;
        case 3:
          this.GetTeacherTimes();
          this.stepper.stepBlock1=false;
          this.stepper.stepBlock2=false;
          this.stepper.stepBlock3=true;
          this.stepper.stepBlock4=false;
          this.stepper.progressValue=66;
          break;
        case 4:
          this.stepper.stepBlock1=false;
          this.stepper.stepBlock2=false;
          this.stepper.stepBlock3=false;
          this.stepper.stepBlock4=true;
          this.stepper.progressValue=100;
          break;
        default:
          break;
      }
    },
    PackageInit(){
      this.trainingPackage.selectedTaxe = {
        taxeNumber:1,
        title:"Tek Çekim",
        lateCharge:0,
        totalPrice:0
      };

      this.selectedTeacher={};

      this.trainingPackage={
        package:{},
        selected :{
          packageId:'',
          campaings:[]
        },
        selectedTraining:{
            freezeLesson:0,
            missed:0,
            postpone:0,
            teacherChange:0
        },
        selectedCampaign:{
            freezeLesson:0,
            missed:0,
            postpone:0,
            teacherChange:0
        },
        price :0,
        discount:0,
        totalPrice:0,
        twoTax: 0,
        threeTax: 0,
        fourTax: 0,
        fiveTax: 0,
        sixTax: 0,
        sevenTax: 0,
        eightTax: 0,
        nineTax: 0,
        tenTax: 0,
        elevenTax: 0,
        twelveTax: 0,
        taxes :[],
        billInfos:[],
        selectBill:{
          id:'',
          title: '',
          taxOffice: '',
          taxNumber: '',
          address: '',
          country: '',
          city: '',
          district: '',
          postCode: '',
        }
      }

      this.freePayments=[];
      this.paymentInfo={
        payTaxeNumber:1,
        payLateCharge:0,
        payTotalPrice:0,
        isPartialPay:false,
        paymentIds:[],
        purchasedProductId:null
      };
      this.scheduleStartDate=null;
      this.bookWithLevel=[];

      this.$refs.PayCreditCardForm.resetForm();
      this.$refs.PayCreditCardForm.setFieldValue('CardHolder','');
      this.$refs.PayCreditCardForm.setFieldValue('CardNumber','');
      this.$refs.PayCreditCardForm.setFieldValue('CardCvv','');
      this.$refs.PayCreditCardForm.setFieldValue('CardExpireDate','');
    },
    SelectedTeacherTime(value){
      if(value){
        this.selectedTeacher={
          id:value.id,
          name:value.name,
          time:value.time.substring(0,2)+':'+value.time.substring(2,4),
          showTime:value.showTime
        };
      }else{
        this.selectedTeacher={
          id:'00000000-0000-0000-0000-000000000000',
          name:'Teacher Free',
          time:'00:00',
          showTime:'00:00'
        };
      }
    },
    SubmitComplatedForm(form){
      let packageId = this.trainingPackage.selected.packageId;
      let currency = 3;
      let basePrice = this.trainingPackage.price;
      let discountPrice = this.trainingPackage.discount;
      let lateChargePrice = this.paymentInfo.payLateCharge;
      let taxPrice = this.trainingPackage.taxAmount;
      let taxRate = this.trainingPackage.taxRate;
      let campaings = this.GetCampaingsIdList;
      let payments = this.paymentInfo.paymentIds;
      let memberId = this.selectedMemberId;
      let teacherId = this.selectedTeacher.id;
      let beginDate = this.scheduleStartDate;
      let lessonTime = this.selectedTeacher.time;
      let month = this.trainingPackage.selectedTraining.month + this.trainingPackage.selectedCampaign.month;
      let weekDay = this.trainingPackage.package.weekDay;
      let duration = this.trainingPackage.package.duration;

      let postpone = this.trainingPackage.selectedTraining.postpone + this.trainingPackage.selectedCampaign.postpone;
      let teacherChange = this.trainingPackage.selectedTraining.teacherChange + this.trainingPackage.selectedCampaign.teacherChange;
      let missed = 0;//this.trainingPackage.selectedTraining.missed + this.trainingPackage.selectedCampaign.missed;
      let freezeLesson = this.trainingPackage.selectedTraining.freezeLesson + this.trainingPackage.selectedCampaign.freezeLesson;

      let rights = '{"postpone":'+postpone+',"missed":'+missed+',"teacherChange":'+teacherChange+',"freezeLesson":'+freezeLesson+'}';
      let contactType=3;
      let contactData=form.SkypeAddress;
      let firstLessonIsLevelTest = (form.FirstLessonIsLevelTest)?true:false;
      let bookName = parseInt(form.StartBookLesson.split('#')[0]);
      let bookLevel = parseInt(form.StartBookLesson.split('#')[1]);

      if(confirm('Öğrenci kayıt işlemini tamamlamak istiyor musunuz?')){
        this.modalSubmitLoading = true;
        this.TRAINING_CREATE(
            {
            packageId:packageId,
            currency:currency,
            basePrice:basePrice,
            discountPrice:discountPrice,
            lateChargePrice :lateChargePrice,
            taxPrice:taxPrice,
            taxRate:taxRate,
            campaings:campaings,
            memberId : memberId,
            teacherId:teacherId,
            beginDate:beginDate,
            lessonTime:lessonTime,
            month:month,
            weekDay:weekDay,
            duration:duration,
            rights:rights,
            contactType:contactType,
            contactData:contactData,
            bookName:bookName,
            bookLevel:bookLevel,
            payments:payments,
            firstLessonIsLevelTest:firstLessonIsLevelTest
          }
        )
        .then(()=>{
          let message = 'Öğrenci kayıt işlemi başarıyla gerçekleşti.'
          this.$toast.success(message,{
                position : 'top',
                duration : 3000
            });
          this.modalSubmitLoading = false;
          this.GetFreePayments();
          let removeIndex = this.items.findIndex((x)=>x.id===memberId);
          this.items.splice(removeIndex, 1);
          window.$("#PackageModal").modal('hide');
        })
        .catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.modalSubmitLoading = false;
        });
      }

    },

    GetReasonForCancellation() {
      this.MEMBER_GETREASONFORCANCELLATION()
      .then((res)=>{
            this.reasonForCancellation = res.data;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    GetReasonForDelay() {
      this.MEMBER_GETREASONFORDELAY()
      .then((res)=>{
        this.reasonForDelay = res.data;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    GetPaymentInfo(lastPayment){
        if(!lastPayment)
          return "";
        
        let paymentText = '';

        if(lastPayment.isSuccess)
        {paymentText += '<div class="alert alert-success mb-1"><i class="fas fa-check"></i>&nbsp;Başarılı Ödeme | ' + this.DateFormat(lastPayment.date) ;}
        else
        {paymentText += '<div class="alert alert-danger mb-1"><i class="fas fa-times"></i>&nbsp;Başarısız Ödeme | ' + this.DateFormat(lastPayment.date) ;}

        if(lastPayment.month>0){
          paymentText +=' - <strong>' + lastPayment.totalPrice.toFixed(2) + '₺ </strong> ('+lastPayment.installment+' taksit) - ' + lastPayment.month + ' Ay ' + lastPayment.duration + ' Dakika (Haftada ' + lastPayment.weekDay +' gün)';
        }else{
          paymentText += ' - <strong>' + lastPayment.totalPrice.toFixed(2) + '₺ </strong> ('+lastPayment.installment+' taksit) Kendini Dene (Hafta içi 5 gün ' + lastPayment.duration + ' Dakika)';
        }
        paymentText +='<br>Fatura Bilgileri : ' + lastPayment.invoiceTitle + ' | ' + lastPayment.invoiceTaxNumber + '/' +lastPayment.invoiceTaxOffice+ ' | ' + lastPayment.invoiceAddress + '</div>';


        return paymentText;
    },
    GetTestInfo(lastTest){
      if(!lastTest)
          return "";
        let level = '';
        let testText = '';
        if (lastTest.correctNumber>=0 && lastTest.correctNumber <= 7)
            level = 'A1';
        else if (lastTest.correctNumber >= 8 && lastTest.correctNumber <= 14)
            level = 'A2';
        else if (lastTest.correctNumber >= 15 && lastTest.correctNumber <= 21)
            level = 'B1';
        else if (lastTest.correctNumber >= 22 && lastTest.correctNumber <= 25)
            level = 'B2';
        else if (lastTest.correctNumber >= 26 && lastTest.correctNumber <= 28)
            level = 'C1';
        else if (lastTest.correctNumber >= 29 && lastTest.correctNumber <= 30)
            level = 'C2';
            
        testText = '<div class="alert alert-info mb-1">'+this.DateFormat(this.CalculateDateTimeByOffset(lastTest.beginDate))+ ' |  <b>Doğru Cevap :</b> '+lastTest.correctNumber+' | <b>Yanlış Cevap :</b> '+lastTest.inCorrectNumber +' | <b>Seviye :</b> '+ level+'</div>';
        return testText;

    }
  },
  mounted: function() {

      this.GetReasonForCancellation();
      this.GetReasonForDelay();
      this.GetOperationUsers('','','',true);

      let t = this;
      let options = locale
      window.$('#ScheduleStartDate').daterangepicker(
      {
          singleDatePicker: true,
          minDate:new Date(),
          locale : options,
          onSelect: function(dateText) { 
            t.scheduleStartDate = dateText.format('YYYY-MM-DD');
          }
      },function(start) {
          t.scheduleStartDate=start.format('YYYY-MM-DD');
      });
  },
  watch:{
    isPartialPay:function(){
      if(!this.paymentInfo.isPartialPay){
          this.paymentInfo.payTotalPrice = parseFloat((this.trainingPackage.selectedTaxe)?this.trainingPackage.selectedTaxe.totalPrice:0).toFixed(2);
          this.paymentInfo.payLateCharge = parseFloat((this.trainingPackage.selectedTaxe)?this.trainingPackage.selectedTaxe.lateCharge:0).toFixed(2);
          this.paymentInfo.payTaxeNumber = (this.trainingPackage.selectedTaxe)?this.trainingPackage.selectedTaxe.taxeNumber:0;
      }
    },
    "mailSendData.id":{
      handler(val){
        let selected = this.mailTemplates.find(x=>x.id==val);
        if(selected){
          let parameter = {
              name: this.openMemberInfo.name,
              surname:this.openMemberInfo.surname,
              year: new Date().getFullYear()
          };

          this.mailSendData.subject=TextConvert(selected.subject,parameter);
          this.mailSendData.content=TextConvert(selected.body,parameter);
        }else if(val==99999){
          this.mailSendData.subject="Lengedu İngilizce Eğitim";
          let htmlData = GetDefaultMailTemplate();
          let parameter = {
              name: this.openMemberInfo.name,
              surname:this.openMemberInfo.surname,
              year: new Date().getFullYear()
          };
          this.mailSendData.content=TextConvert(htmlData,parameter);
        }else {
          this.mailSendData.subject='';
          this.mailSendData.content='';
        }
     },
     deep: true
    },
    "smsSendData.id":{
      handler(val){
        let selected = this.smsTemplates.find(x=>x.id==val);
        if(selected){
          let parameter = {
              name: this.openMemberInfo.name,
              surname:this.openMemberInfo.surname,
          };

          this.smsSendData.content=TextConvert(selected.body,parameter);
        }else if(val==99999){
          let htmlData = "Merhaba %NAME% %SURNAME%";
          let parameter = {
              name: this.openMemberInfo.name,
              surname:this.openMemberInfo.surname,
              year: new Date().getFullYear()
          };
          this.smsSendData.content=TextConvert(htmlData,parameter);
        }else{
          this.smsSendData.content='';
        }
     },
     deep: true
    }
  }
};
</script>
<style scoped>
  .error-feedback { color: red;}
  .pointer:hover{cursor: pointer;}
</style>