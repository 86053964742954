<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="/" class="brand-link">
      <!-- <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8"> -->
      <span class="brand-text font-weight-light">Lengedu</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item" v-if="IsPermision(PermissionCodes.FORM13)">
            <router-link :to="{path:'/panel/member-form'}" class="nav-link">
              <i class="nav-icon fas fa-file-alt"></i>
              <p>Form İşlemleri</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="IsPermision(PermissionCodes.STUDENT14)">
            <router-link :to="{path:'/panel/member-student'}" class="nav-link">
              <i class="nav-icon fas fa-user-graduate"></i>
              <p>Öğrenci İşlemleri</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{path:'/panel/task'}" class="nav-link">
              <i class="nav-icon fas fa-tasks"></i>
              <p>Görev / Hatırlatma</p>
            </router-link>
          </li>
          <!-- <li class="nav-item" v-if="isAdmin">
            <router-link :to="{path:'/panel/ticket'}" class="nav-link">
              <i class="nav-icon fas fa-life-ring"></i>
              <p>Öğrenci Destek</p>
            </router-link>
          </li> -->
          <li class="nav-item" v-if="IsPermision(PermissionCodes.USER12)">
            <router-link :to="{path:'/panel/user'}" class="nav-link">
              <i class="nav-icon fas fa-user"></i>
              <p>Kullanıcı İşlemleri</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="IsPermision(PermissionCodes.TEACHER11)">
            <router-link :to="{path:'/panel/teacher'}" class="nav-link">
              <i class="nav-icon fas fa-user"></i>
              <p>Öğretmen İşlemleri</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="IsPermision(PermissionCodes.REPORT2)">
            <router-link :to="{path:'/panel/reports'}" class="nav-link">
              <i class="nav-icon fas fa-database"></i>
              <p>Raporlar</p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import {mapGetters} from "vuex";
import permissionMixins from "../mixins/permissons-mixin.js";
export default {
  name: "SideBar",
  computed:{
    ...mapGetters(["IS_ADMIN","IS_OPERATOR"]),
    isAdmin(){
      return this.IS_ADMIN;
    },
    isOperator(){
      return this.IS_OPERATOR;
    }
  },
  mixins:[permissionMixins],
};
</script>