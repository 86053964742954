import {mapGetters} from "vuex";
import {PERMISSONCODES} from "../permisonCode.js";
const permissionMixins= {
    computed:{
        ...mapGetters(["PERMISSIONS","IS_ADMIN"]),
        PermissionCodes(){
            return PERMISSONCODES;
        }
    },
    methods:{
        IsPermision(value){
            let result = false;
            if(this.IS_ADMIN){
                result = true;
                return result;
            }
            if(this.PERMISSIONS){
                for (let i = 0; i < this.PERMISSIONS.length; i++) {
                    if(this.PERMISSIONS[i].toLowerCase()===value.toLowerCase()){
                        result = true;
                        break;
                    }
                }
            }
            return result;
        }
    }
}
export default permissionMixins