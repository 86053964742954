import {service} from "../helpers/request"

function TeacherPaymentReport(model){
    return service({
        url:'/Report/TeacherPaymentReport',
        method:'post',
        data:{
            beginDate: model.beginDate,
            endDate : model.endDate,
            teacherId:model.teacherId
        }
    })
}
function TeacherWithStudentReport(){
    return service({
        url:'/Report/TeacherWithStudentReport',
        method:'post',
        data:{}
    })
}

function TransactionReport(model){
    return service({
        url:'/Report/TransactionReport',
        method:'post',
        data:{
            userId:model.userId,
            beginDate:model.beginDate,
            endDate:model.endDate
        }
    })
}

function PaymentReport(model){
    return service({
        url:'/Report/PaymentReport',
        method:'post',
        data:{
            beginDate:model.beginDate,
            endDate:model.endDate
        }
    })
}

function FormReport(model){
    return service({
        url:'/Report/FormReport',
        method:'post',
        data:{
            beginDate:model.beginDate,
            endDate:model.endDate
        }
    })
}

export {TeacherPaymentReport,TeacherWithStudentReport,TransactionReport,PaymentReport,FormReport};
