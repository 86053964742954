<template>
  <div class="modal fade" id="UserProfileModal" tabindex="1" role="dialog">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <ul class="nav nav-tabs justify-content-end mt-1" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link text-secondary" :class="{'active':tabPermissionVisible}" href="javascript:;" @click="OpenTab('TabPermission')"><i class="fas fa-user-check"></i> Yetkiler</a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-secondary" :class="{'active':tabTransactionVisible}" href="javascript:;" @click="OpenTab('TabTransaction')">Log</a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-secondary" href="javascript:;" @click="CloseUserProfileModal()"><i class="fas fa-times"></i></a>
              </li>
          </ul>  
          <div class="modal-body pt-0">
            <div class="row">
              <div class="col-4">
                <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
                <div v-if="!isLoading">
                  <div class="clearfix mb-2 mt-3">
                    <img src="@/assets/default.png" class="rounded-circle float-left mr-3" style="width: 75px; background-color: #CCC;" alt="...">
                    <div class="float-left">
                      <div><small v-html="RoleText"></small></div>
                      <h3 class="text-capitalize mb-0 mt-0" v-html="NameSurname"></h3>
                      <div><small v-html="UserId"></small></div>
                    </div>
                  </div>
                  <div class="mb-2" v-if="Gender!==null && Gender!==0"><div class="font-weight-bold"><i class="fas fa-male"></i><i class="fas fa-female"></i> Cinsiyet</div><div v-html="Gender"></div></div>
                  <div class="mb-2" v-if="Birthday!==null"><div class="font-weight-bold"><i class="fas fa-calendar-alt"></i> Doğum Tarihi</div><div v-html="Birthday"></div></div>
                  <div class="mb-2"><div class="font-weight-bold">Durum</div><div v-html="Status"></div></div>
                  <div class="mb-2">
                    <a href="javascript:;" class="btn btn-sm btn-primary mb-1" v-if="role==4" @click="UserDoActiveDistrubuteForBaseSale()">Temel Satışçı Listesi Kuyruğuna Ekle</a>
                    <a href="javascript:;" class="btn btn-sm btn-primary mb-1" v-if="role==4" @click="UserDoActiveDistrubuteForBaseTestSale()">Seviye Tespit Satışçı Listesi Kuyruğuna Ekle</a>
                    <a href="javascript:;" class="btn btn-sm btn-primary mb-1" v-if="role==2" @click="UserDoActiveDistrubuteForBaseOperator()">Öğrenci Operatorı Listesi Kuyruğuna Ekle</a>

                  </div>
                </div>
              </div>
              <div class="col-8 border border-top-0">
                <div class="tab-content" id="MyProfileModalNav">
                  <TabPermission :UserId="UserId" :Visible="tabPermissionVisible"></TabPermission>
                  <TabTransaction :UserId="UserId" :Visible="tabTransactionVisible"></TabTransaction>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {DateFormat} from "../../helpers/date-convert-helper";
import TabPermission from "./TabPermission.vue";
import TabTransaction from "./TabTransaction.vue";
export default {
  name: 'UserProfileModal',
  components: { 
    TabPermission,
    TabTransaction
  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    UserId:{
      type:String,
      default:null
    }
  },
  computed:{
    
    NameSurname:function(){
        if(this.fullName!==null){
          return this.fullName;
      }
      else
        return '';
    },
    Birthday:function(){
      if(this.birthday!==null){
        return DateFormat(this.birthday,'dd.mm.yyyy');
      }
      else
        return '';
    },
    Gender:function(){
      if(this.gender!==null){
        return this.gender;
      }
      else
        return '';
    },
    RoleText:function(){
      if(this.roleText!==null){
        return this.roleText;
      }
      else
        return '';
    },
    Status:function(){
      if(this.status!==null){
        return this.status;
      }
      else
        return '';
    },
  },
  data() {
    return {
      id : null,
      fullName: null,
      birthday: null,
      gender: null,
      role: null,
      roleText:null,
      status: null,

      isLoading:true,
      isDistrubuteLoading:false,

      tabPermissionVisible:false,
      tabTransactionVisible:false,
    }
  },
  methods:{
    ...mapActions(["USERPROFILE_GETUSER","USER_ACTIVEDISTRUBUTEFORBASEOPERATOR","USER_ACTIVEDISTRUBUTEFORBASESALE","USER_ACTIVEDISTRUBUTEFORBASETESTSALE"]),
    GetProfile(){
      this.isLoading=true;
      this.USERPROFILE_GETUSER({id:this.UserId})
      .then((res)=>{
        this.isLoading=false;
        
        this.id = res.data.id;
        this.fullName = res.data.fullName;
        this.birthday= res.data.birthday;
        this.gender = res.data.gender;
        this.role = res.data.role;
        this.roleText = res.data.roleText;
        this.status = res.data.status;
      })
      .catch(error=>{
        this.isLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    UserDoActiveDistrubuteForBaseSale(){
      this.isDistrubuteLoading = true;
      let userId = this.UserId;
      if(!confirm('Kullanıcıyı "Temel Satışçı Listesi" kuyruğuna eklemek istiyor musunuz?')){
        this.isDistrubuteLoading = false;
        return;
      } 
      this.USER_ACTIVEDISTRUBUTEFORBASESALE({userId})
      .then((res)=>{
        this.isDistrubuteLoading = false;
        this.$toast.success(res.data.description,{
            position : 'top',
            duration:3000
        });
      })
      .catch((error)=>{
          this.isDistrubuteLoading = false;
          this.$toast.error(error,{
            position : 'top',
            duration:3000
          });
      });
    },
    UserDoActiveDistrubuteForBaseTestSale(){
      this.isDistrubuteLoading = true;
      let userId = this.UserId;
      if(!confirm('Kullanıcıyı "Seviye Tespit Satışçı Listesi" kuyruğuna eklemek istiyor musunuz?')){
        this.isDistrubuteLoading = false;
        return;
      } 
      this.USER_ACTIVEDISTRUBUTEFORBASETESTSALE({userId})
      .then((res)=>{
        this.isDistrubuteLoading = false;
        this.$toast.success(res.data.description,{
            position : 'top',
            duration:3000
        });
      })
      .catch((error)=>{
          this.isDistrubuteLoading = false;
          this.$toast.error(error,{
            position : 'top',
            duration:3000
          });
      });
    },
    UserDoActiveDistrubuteForBaseOperator(){
      this.isDistrubuteLoading = true;
      let userId = this.UserId;
      if(!confirm('Kullanıcıyı "Öğrenci Operator Listesi" kuyruğuna eklemek istiyor musunuz?')){
        this.isDistrubuteLoading = false;
        return;
      } 
      this.USER_ACTIVEDISTRUBUTEFORBASEOPERATOR({userId})
      .then((res)=>{
        this.isDistrubuteLoading = false;
        this.$toast.success(res.data.description,{
            position : 'top',
            duration:3000
        });
      })
      .catch((error)=>{
          this.isDistrubuteLoading = false;
          this.$toast.error(error,{
            position : 'top',
            duration:3000
          });
      });
    },
    CloseUserProfileModal(){
      window.$("#UserProfileModal").modal('hide');
      this.$emit("CloseUserProfileModal", false);
    },
    OpenTab(value){
      this.tabPermissionVisible=false;
      this.tabTransactionVisible=false;
      if(value==='TabPermission'){
        this.tabPermissionVisible=true;
      }else if(value==='TabTransaction'){
        this.tabTransactionVisible=true;
      }
    },
  },
  watch: {
    Visible(value){
      if(value){
        this.GetProfile();
        this.OpenTab('TabTransaction');
        window.$("#UserProfileModal").modal({backdrop: 'static',keyboard: false, show: true});
      }else{
        this.OpenTab('TabPermission');
      }
    }
  },
  mounted: function() {
  }
  
}
</script>