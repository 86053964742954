function ParsePhoneNumber(value){
    try {
        
        let code="";
        let number="";
        
        if(value.indexOf("-")<0){
            code="0090";
            number=value;
        }
        
        let phoneArr = value.split('-');

        code=phoneArr[0];
        number=phoneArr[1];

        return {
            code:code,
            number:number
        };     

    } catch (error) {
        return {
            code:"0090",
            number:value
        };
    }
}

export {ParsePhoneNumber}