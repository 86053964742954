<template>
   <router-view />
</template>

<script>
export default {
  name: 'App',
  computde:{},
  methods:{}
}
</script>
<style>
  @import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
</style>
