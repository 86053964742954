function IsShowErrorMessage(error){
    try {
        if(error==='Request failed with status code 401')
            return false;
            
        return true;
    } catch (error) {
       return false;
    }
}
export {IsShowErrorMessage}