<template>
  <div style="min-height: 500px;" class="tab-pane p-2 fade overflow-auto" :class="showTab">
    <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
    <div v-else>
      <div v-if="tests.length==0" class="text-center mt-5">Herhangi bir teste katılmadı.</div>
      <div class="row mb-2 pb-2 border-bottom"  v-for="t in tests" :key="t">
        <div class="col-7">
            <div v-html="t.title"></div>
            <span class="badge badge-primary" v-if="t.categoryName!=''" v-html="t.categoryName"></span>
        </div>
        <div class="col-3">
          <i class="fas fa-clone"></i> {{ t.answerList.length }} | 
          <i class="fas fa-check-circle"></i> {{ AnswerAvg(t.answerList,'correct') }} | 
          <i class="fas fa-times-circle"></i> {{ AnswerAvg(t.answerList,'incorrect') }}
        </div>
        <div class="col-2 text-center">
          <button type="button" class="btn btn-sm btn-primary" @click="()=>{showTestDetail='detail'+t.id}">Sonuçlar</button>
        </div>
        <div class="col-12 border p-2 mt-2" v-if="t.answerList.length>0 && showTestDetail=='detail'+t.id" >
          <table class="table table-sm table-hover">
            <thead>
              <tr>
                <th>Başlangış</th>
                <th>Bitiş</th>
                <th class="text-right">Doğru | Yanlış</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="a in t.answerList" :key="a">
                <td>{{ DateViewFormat(a.beginDate) }}</td>
                <td>{{ DateViewFormat(a.finishDate) }}</td>
                <td class="text-right">
                  <i class="fas fa-check-circle"></i> {{ a.correctNumber }} | 
                  <i class="fas fa-times-circle"></i> {{ a.inCorrectNumber }}
                </td>
                <td class="text-right">
                  <button class="btn btn-sm btn-warning" @click="OpenCloseTestDetailModal(true,t.questions,a.answers)"><i class="fas fa-eye"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <TestDetailModal :Visible="testDetailModalVisible" :Questions="testDetailModalQuestions" :Answers="testDetailModalAnswers" @CloseTestDetailModal="OpenCloseTestDetailModal($event,null,null)"></TestDetailModal>
</template>
<style scoped>

</style>
<script>
import {mapActions} from "vuex";
import {DateFormat,CalculateDateTime} from "../../helpers/date-convert-helper";
import TestDetailModal from "../../components/TestDetailModal.vue";
export default {
  name: 'TabStatistic',
  components: {
    TestDetailModal
    },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      showTestDetail:'',
      tests:[],
      testDetailModalVisible:false,
      testDetailModalQuestions:null,
      testDetailModalAnswers:null,
      isLoading:true
    }
  },
  computed:{

  },
  methods:{
    ...mapActions(["MEMBERPROFILE_TABSTATISTICINIT"]),
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    DateViewFormat(value){
      return DateFormat(this.CalculateDateTimeByOffset(value),'dd.mm.yyyy hh:mm:ss');
    },
    AnswerAvg(answers,type){
      if(answers && answers.length>0){
        let totalAnswer = answers.length;
        let totalCorrect = 0;
        let totalInCorrect = 0;
        answers.forEach(i => {
          totalCorrect +=i.correctNumber;
          totalInCorrect +=i.inCorrectNumber;
        });

        if(type=='correct'){
          return (totalCorrect / totalAnswer).toFixed(0);
        }else if(type=='incorrect'){
          return (totalInCorrect / totalAnswer).toFixed(0);
        }
      }
      return 0;
    },
    OpenCloseTestDetailModal(value,questions,answers){
      this.testDetailModalVisible=value;
      this.testDetailModalQuestions=JSON.parse(questions);
      this.testDetailModalAnswers = JSON.parse(answers);
    },
    GetInitData(){
      this.isLoading=true;
      this.MEMBERPROFILE_TABSTATISTICINIT({id:this.MemberId})
        .then((res)=>{
          this.isLoading=false;
          this.tests = res.data;
        })
        .catch(error=>{
          this.isLoading=false;
          if(this.IsShowErrorMessage(error)){
            this.$toast.error(error,{
                position : 'top',
                duration : 3000
            });
          }
        });
    },
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData();
      }else{
        this.showTab='';
        this.tests=[];
      }
    }
  },
  mounted: function() {

  }

}
</script>