<template>
  <div class="modal fade" v-bind:id="ModalName" tabindex="2" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">3D Ödeme İşlemi</h5>
            <button type="button" class="close" @click="CloseModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <div class="p-2">
              <div class="alert alert-warning mb-0 text-sm">İşlem sonucu görüntülenmeden ekranı kapatmayınız. Aksi taktirde ödeme işlemi <i><u>yarım kalacak ve POS ekranından kontrol etmeniz gerekecektir.</u></i> </div>
            </div>
            <iframe id="PaymentIFrame" v-bind:src="ThreeUrl" width="100%" height="450" style="border:none"></iframe>
          </div>
        </div>
      </div>
  </div>
</template>
<style scoped>
  
</style>
<script>
export default {
  name: 'PaymentThreeDModal',
  components: {  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    MemberId:{
      type:String,
      default:null
    },
    ThreeUrl:{
      type:String,
      default:null
    },
    ModalName:{
      type:String,
      default:'PaymentThreeDModal'
    }
  },
  data() {
    return {}
  },
  computed:{
  },
  methods:{
      CloseModal(){
        window.$("#"+this.ModalName).modal('hide');
        this.$emit("CloseModal", false);
      },
  },
  watch: {
    Visible(value){
      if(value){
        window.$("#"+this.ModalName).modal({backdrop: 'static',keyboard: false, show: true});
      }
    }
  },
  mounted: function() {
  }
  
}
</script>