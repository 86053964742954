<template>
  <div style="min-height: 500px;" class="tab-pane fade p-2" :class="showTab">
    <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
    <div v-else>
      <div v-if="histories.length==0" class="text-center mt-5">Herhangi bir kayıt bulunamadı.</div>
      <div class="overflow-auto mb-1" style="height:500px;">
        <table class="table">
          <thead>
            <tr>
              <th>Tarih</th>
              <th>Source</th>
              <th>Campaign</th>
              <th>Content</th>
              <th>Medium</th>
              <th>Term</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="h in histories" :key="h">
              <td>{{ DateFormat(CalculateDateTimeByOffset(h.transactionDate)) }}</td>
              <td>{{ h.utmSource }}</td>
              <td>{{ h.utmCampaign }}</td>
              <td>{{ h.utmContent }}</td>
              <td>{{ h.utmMedium }}</td>
              <td>{{ h.utmTerm }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
import {DateFormat,CalculateDateTime} from "../../helpers/date-convert-helper";
export default {
  name: 'TabFormHistory',
  components: { },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      histories:[],
      isLoading:false,
    }
  },
  computed:{},
  methods:{
    ...mapActions(["MEMBERPROFILE_FORMHISTORY"]),
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    DateFormat(value){
      return DateFormat(value,'dd.mm.yyyy hh:MM:ss');
    },
    GetInitData(){
      this.isLoading=true;
      this.MEMBERPROFILE_FORMHISTORY({id:this.MemberId})
        .then((res)=>{
          this.isLoading=false;
          this.histories = res.data;
        })
        .catch(error=>{
            this.isLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
          });
    },
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData();
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  }
  
}
</script>