<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
      <!-- <li class="nav-item d-none d-sm-inline-block">
        <router-link :to="{path:'/'}" class="nav-link">
          Anasayfa
        </router-link>
      </li> -->
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>

    <li class="nav-item ">
          <a class="nav-link" role="button">
              <i class="fas fa-user"></i>
               {{getFullName}}
          </a>
      </li>
      <li class="nav-item ">
          <a class="nav-link" @click="logout" role="button">
              <i class="fas fa-sign-out-alt"></i>
          </a>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
  
</template>

<script>
import {mapActions, mapGetters} from "vuex"

 export default {
   name: "NavBar",
   computed: {
      ...mapGetters(["FULLNAME"]),
      getFullName() {
        return this.FULLNAME;
      },
   },
   methods:{
    ...mapActions(["LOGOUT"]),
    logout(){
      this.LOGOUT()
      .then(()=>{
          this.$router.push("/login");
      })
      .catch((error)=>{
          this.$toast.error(error,{
            position : 'top',
            duration:3000
          });
      })
     }
   },
  //  beforeCreate:function(){
  //     this.$store.commit('initialiseStore');
  // }
};
</script>