<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Eğitmen Raporu</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{path:'/panel/reports'}">Raprolar</router-link>
              </li>
              <li class="breadcrumb-item active">Eğitmen Raporu</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">        
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 col-md-7 col-lg-8">
                    <div class="float-left">
                        <v-date-picker v-model="rangeDate" mode="date" is-range>
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="input-group input-group-sm">      
                                <input
                                  :value="inputValue.start"
                                  v-on="inputEvents.start"
                                  :disabled="isDataLoading"
                                  class="form-control form-control-sm" 
                                  style="width:80px"
                                />
                                <input
                                  :value="inputValue.end"
                                  v-on="inputEvents.end"
                                  :disabled="isDataLoading"
                                  class="form-control form-control-sm"
                                  style="width:80px"
                                />
                                <select class="form-control form-control-sm" v-model="selectedTeacher" :disabled="isDataLoading" style="width: 250px;">
                                  <option v-for="t in teacherList" :key="t" v-bind:value="t.value">{{t.label}}</option>
                                </select>
                                <span class="input-group-append">
                                  <button type="button" class="btn btn-sm btn-default btn-flat" @click="GetReport()" :disabled="isDataLoading">Listele</button>
                                </span>
                            </div>    
                          </template>
                        </v-date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body table-responsive p-0 overflow-scroll" style="height:500px;">
                <table class="table">
                  <thead>
                  <tr>
                    <th>Eğitmen</th>
                    <th class="text-center">Tarih</th>
                    <th>Açıklama</th>
                    <th class="text-center">Süre</th>
                    <th class="text-center">Durum</th>
                    <th class="text-center">Alacak</th>
                    <th class="text-center">Borç</th>
                  </tr>
                  </thead>
                  <tbody v-if="reportList.length>0">
                    <tr v-for="r in reportList" :key="r">
                      <td v-html="r.teacher" class="text-capitalize"></td>
                      <td v-html="r.date" class="text-center"></td>
                      <td v-html="r.description" class="text-capitalize"></td>
                      <td v-html="r.duration+'dk'" class="text-center"></td>
                      <td v-html="r.status" class="text-center" :class="{'text-danger':r.status=='Katılmadı'}"></td>
                      <td v-html="r.creditAmount.toFixed(2)+'₺'" class="text-center"></td>
                      <td v-html="r.debitAmount.toFixed(2)+'₺'" class="text-center" :class="{'text-danger':r.debitAmount==0}"></td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="isDataLoading">
                    <tr>
                      <td colspan="7" class="text-center">Lütfen bekleyin.</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="text-center">Kayıt bulunamadı</td>
                    </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th colspan="4">
                      <span class="text-success">Katıldı : ({{TotalComplated}} Adet) {{TotalMinuteComplated}}dk</span> | <span class="text-danger">Katılmadı : ({{TotalCancel}} Adet) {{TotalMinuteCancel}}dk</span>
                    </th>
                    <th class="text-right">Toplam :</th>
                    <th class="text-center">{{TotalCredit.toFixed(2)}}₺</th>
                    <th class="text-center">{{TotalDebit.toFixed(2)}}₺</th>
                  </tr>
                  </tfoot>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import {DateFormat} from "../../../helpers/date-convert-helper";
export default {
  name: "TeacherReport",
  components: {
  },
  data(){
    return{
      rangeDate:{
        start: new Date(),
        end: new Date()
      },
      isDataLoadingForTeacher:false,
      isDataLoading:false,
      teacherList:[],
      selectedTeacher:"00000000-0000-0000-0000-000000000000",
      reportList:[]
    }
  },
  computed:{
    TotalComplated:function(){
        if(this.reportList.length==0)
          return 0;

        let c = this.reportList.filter(x=>x.status=='Katıldı');
        return c.length;
    },

    TotalMinuteComplated:function(){
        if(this.reportList.length==0)
          return 0;

        let c = this.reportList.filter(x=>x.status=='Katıldı');
        return c.reduce((partialSum, a) => partialSum + a.duration, 0);
    },
    TotalCancel:function(){
        if(this.reportList.length==0)
          return 0;

        let c = this.reportList.filter(x=>x.status=='Katılmadı');
        return c.length;
    },

    TotalMinuteCancel:function(){
        if(this.reportList.length==0)
          return 0;

        let c = this.reportList.filter(x=>x.status=='Katılmadı');
        return c.reduce((partialSum, a) => partialSum + a.duration, 0);
    },

    TotalDebit:function(){
        if(this.reportList.length==0)
         return 0;

        return this.reportList.reduce((partialSum, a) => partialSum + a.debitAmount, 0);
    },
    TotalCredit:function(){
        if(this.reportList.length==0)
         return 0;

        return this.reportList.reduce((partialSum, a) => partialSum + a.creditAmount, 0);
    }
  },
  watch:{
  },
  methods: {
    ...mapActions(["TEACHER_ACTIVELIST","TEACHERPAYMENTREPORT","TEACHER_INCLUDEDTEACHERLIST"]),
    
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    ReportDateFormat(value){
      return DateFormat(value,'yyyy-mm-dd');
    },
    ReportViewDateFormat(value){
      return DateFormat(value,'dd.mm.yyyy');
    },
    GetStatus(value){
        if(value==1){
          return 'Katılmadı';
        }else{
          return 'Katıldı'
        }
    },
    GetReport(){
      let mainThis = this;
      mainThis.isDataLoading = true;
      mainThis.reportList = [];

      mainThis.TEACHERPAYMENTREPORT({
        teacherId:mainThis.selectedTeacher,
        beginDate:mainThis.ReportDateFormat(mainThis.rangeDate.start),
        endDate:mainThis.ReportDateFormat(mainThis.rangeDate.end)
      })
      .then((res)=>{
        res.data.forEach(t => {
          let nickname = t.nickname!=null?' ('+t.nickname+')':'';
          let teacher = t.name+' '+t.surname + nickname;
          t.paymentDetail.forEach(d=>{
              mainThis.reportList.push({
                  teacher:teacher,
                  date:mainThis.ReportViewDateFormat(d.date),
                  description:d.description,
                  duration : d.duration,
                  status : mainThis.GetStatus(d.status),
                  creditAmount : d.price,
                  debitAmount : d.isPayment?d.price:0.00,
              });
          });
          
        });
        mainThis.isDataLoading = false;
        }).catch(error=>{
          mainThis.isDataLoading = false;
           if(this.IsShowErrorMessage(error)){
             this.$toast.error(error,{
                 position : 'top',
                 duration : 3000
             });
           }
        });
    },

    GetActiveTeacher(){
      let mainThis = this;
      mainThis.isDataLoading = true;
      mainThis.teacherList = [];
      mainThis.teacherList.push({label:"Hepsi",value:"00000000-0000-0000-0000-000000000000"});
      mainThis.TEACHER_INCLUDEDTEACHERLIST()
      .then((res)=>{
        res.data.forEach(t => {
          let nicname = t.nickname!=null?' ('+t.nickname+')':'';
          mainThis.teacherList.push({label:t.name+' '+t.surname + nicname,value:t.id});
        });
        mainThis.isDataLoading = false;
      }).catch(error=>{
          mainThis.isDataLoading = false;
           if(this.IsShowErrorMessage(error)){
             this.$toast.error(error,{
                 position : 'top',
                 duration : 3000
             });
           }
        });
    },
  },
  mounted: function() {
    this.GetActiveTeacher();
  }
};
</script>
<style scoped>
</style>