<template>
  <div class="modal fade" v-bind:id="ModalName" tabindex="2" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Hatırlatma / Görev Ekleme</h5>
            <button type="button" class="close" @click="CloseTaskModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-sm-12 col-md-12 col-lg-7">
                <label>Görev / Hatırlatma Tipi</label>
                <select class="form-control" v-model="category">
                  <option v-for="c in categoryList" :key="c" v-bind:value="c.value">{{c.label}}</option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-5">
                <label>Tarih</label>
                <v-date-picker v-model="taskDate" mode="datetime" :min-date="new Date()" is24hr>
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                      </div>
                      <input
                          :value="inputValue"
                          v-on="inputEvents"
                          class="form-control"
                        />
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <div class="form-check mt-2">
                  <input type="checkbox" class="form-check-input" v-bind:id="'isSetReminder'" v-model="isSetReminder">
                  <label class="form-check-label" v-bind:for="'isSetReminder'">Hatırlatma alarmı kur.</label>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <label>Görevin kurulacağı kullanıcı</label>
                <select class="form-control" v-model="toUser">
                  <option v-for="u in userList" :key="u" v-bind:value="u.value">{{u.label}}</option>
                </select>
              </div>
            </div>
            <div class="row" v-if="taskDescriptionVisible">
              <div class="col-12">
                <label>Açıklama</label>
                <input type="text" class="form-control" v-model="content" placeholder="Açıklama" maxlength="300">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="CreateTask()" :disabled="submitLoading">
                <span v-show="submitLoading" class="spinner-border spinner-border-sm" ></span>
                <span class="ml-2" >Oluştur</span>
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {taskCategories} from "../helpers/const-data.js";
import {IsShowErrorMessage} from "../helpers/error-message-helper";
import {GetRoleText} from "../helpers/enum-convert-helper.js";
import {DateFormat} from "../helpers/date-convert-helper";
export default {
  name: 'TaskModal',
  components: {  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    MemberId:{
      type:String,
      default:null
    },
    ModalName:{
      type:String,
      default:'TaskModal'
    }
  },
  data() {
    return {
      category:0,
      isSetReminder:false,
      content:'',
      taskDate:new Date(),
      toUser:'',
      userList:[],
      submitLoading:false
    }
  },
  computed:{
    taskDescriptionVisible:function(){
      return this.category==0;
    },
    categoryList:function(){
      return taskCategories;
    }
  },
  methods:{
      ...mapActions(["MEMBER_GETOPERATIONUSERS","CREATE_TASK"]),
      IsShowErrorMessage(value){
        return IsShowErrorMessage(value);
      },
      TaskDateFormat(value){
        return DateFormat(value,'yyyy-mm-dd hh:MM:ss');
      },
      CloseTaskModal(){
        window.$("#"+this.ModalName).modal('hide');
        this.$emit("CloseTaskModal", false);
      },
      GetUsers() {
        this.MEMBER_GETOPERATIONUSERS()
        .then((res)=>{
            this.userList=[];
            for (let i = 0; i < res.data.length; i++) {
              this.userList.push({
                value:res.data[i].id,
                label:res.data[i].fullName + ' ('+GetRoleText(res.data[i].role)+')'
              });
            }
            this.toUser = res.data[0].id;
          }).catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
          });
      },
      CreateTask(){
        this.submitLoading=true;
        this.CREATE_TASK({
          category:this.category,
          taskDate:this.TaskDateFormat(this.taskDate).toString(),
          content:this.content,
          toUser:this.toUser,
          memberId:this.MemberId,
          isSetReminder:this.isSetReminder
          }
        )
        .then(()=>{
            let message = "Görev / Hatırlatma başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
            });
            this.submitLoading=false;
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.submitLoading=false;
          });
      }
  },
  watch: {
    Visible(value){
      if(value){
        this.category=0;
        this.isSetReminder=false;
        this.content='';
        this.taskDate=new Date();
        this.toUser='';

        window.$("#"+this.ModalName).modal({backdrop: 'static',keyboard: false, show: true});
      }
    }
  },
  mounted: function() {
    this.GetUsers();
  }
  
}
</script>