import {service} from "../helpers/request"

function AccessToken(username,password){
    return service({
        url:'/Login/AccessToken',
        method:'post',
        data:{
            UserName:username,
            Password:password,
            PanelType : 1
        }
    })
}
function ForgotPassword(username){
    return service({
        url:'/Login/ForgotPassword',
        method:'post',
        data:{
            UserName:username,
            PanelType : 1
        }
    })
}

function RecoverPassword(secureCode,password){
    return service({
        url:'/Login/SavePassword',
        method:'post',
        data:{
            SecureCode:secureCode,
            Password:password,
            PanelType : 1
        }
    })
}

export {AccessToken,ForgotPassword,RecoverPassword};
