<template>
  <div style="min-height: 500px;" class="tab-pane fade p-2" :class="showTab">
    <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
    <div v-else>
      <div class="mb-2 text-right">
        <a class="btn btn-primary btn-sm" href="javascript:;" @click="OpenCloseTaskModal(true)">Yeni Görev Oluştur</a>
      </div>
      <div v-if="tasks.length==0" class="text-center mt-5">Herhangi bir görev bulunamadı.</div>
      <div class="overflow-auto mb-1" style="height:500px;">
        <div class="row border-bottom p-1 bt-1" v-for="t in tasks" :key="t">
          <div class="col-sm-12 col-md-2 col-lg-2 text-center">
              <div class="h6 m-0">{{ TaskDateListFormat(CalculateDateTimeByOffset(t.taskDate)) }}</div>
              <div class="h4 m-0">{{ TaskTimeListFormat(CalculateDateTimeByOffset(t.taskDate)) }}</div>
              <span class="badge bg-success" v-if="t.isComplated">Tamamlandı</span>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div v-if="t.category!=''"><span class="badge bg-warning">{{ TaskCategory(t.category) }}</span></div>
            <div v-if="t.content!=''"><em>{{ t.content }}</em></div>
            <div class="text-black-50 text-sm">{{t.creationUser}} | {{CreationDateFormat(CalculateDateTimeByOffset(t.creationDate))}}</div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3 text-right">
            <div class="text-sm">{{ t.toUserNameSurname }}</div>
          </div>
          <div class="col-sm-12 col-md-1 col-lg-1 text-right"><button class="btn btn-primary btn-sm" v-if="!t.isComplated"  @click="OpenCloseTaskTransactionModal(true,t.id,t.category,t.content,t.member)">İşlem Yap</button></div>
        </div>
      </div>
    </div>
  </div>
  <TaskModal :ModalName="'TaskModalInProfile'" :Visible="taskModalVisible" :MemberId="MemberId" @CloseTaskModal="OpenCloseTaskModal($event)"></TaskModal>
  <TaskTransactionModal :ModalName="'TaskTransactionModalInProfile'" :Visible="taskTransactionModalVisible" :TaskId="taskModal.taskId" :Category="taskModal.category" :Content="taskModal.content" :Member="taskModal.member" @CloseTaskModal="OpenCloseTaskTransactionModal($event,0,0,null,null)"></TaskTransactionModal>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {GetTaskCategoryText} from "../../helpers/enum-convert-helper.js";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
import {DateFormat,CalculateDateTime} from "../../helpers/date-convert-helper";
import TaskModal from "./../TaskModal.vue";
import TaskTransactionModal from "./../TaskTransactionModal.vue";
export default {
  name: 'TabTask',
  components: { 
    TaskModal,
    TaskTransactionModal
    },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      tasks:[],
      isLoading:false,
      taskModal:{
        taskId:0,
        category:0,
        content:'',
        member:''
      },
      taskModalVisible:false,
      taskTransactionModalVisible:false,
    }
  },
  computed:{
    
  },
  methods:{
    ...mapActions(["MEMBERPROFILE_TABTASKINIT"]),
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    TaskCategory(value){
      return GetTaskCategoryText(value);
    },
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    CreationDateFormat(value){
      return DateFormat(value,'dd.mm.yyyy hh:MM:ss');
    },
    TaskDateListFormat(value){
      return DateFormat(value,'dd mm');
    },
    TaskTimeListFormat(value){
      return DateFormat(value,'hh MM');
    },
    GetInitData(){
      this.isLoading=true;
      this.MEMBERPROFILE_TABTASKINIT({id:this.MemberId})
        .then((res)=>{
          this.isLoading=false;
          this.tasks = res.data;
        })
        .catch(error=>{
            this.isLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
          });
    },
    OpenCloseTaskModal(value){
      this.taskModalVisible=value;
    },
    OpenCloseTaskTransactionModal(value,taskId,category,content,member){
      this.taskModal.taskId=taskId;
      this.taskModal.category=category;
      this.taskModal.content=content;
      this.taskModal.member=member;
      this.taskTransactionModalVisible=value;
    },
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData();
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  }
  
}
</script>