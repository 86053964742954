import { 
    GetStudentsWithClassToday,
    GetActiveStudents,
    GetLessonsForResult,
    SaveLessonsResult,
    GetAvailableHours,
    SaveAvailableHours
 } from "../services/teacher.service";

const teacher_module = {
    state:{},
    getters:{},
    mutations:{},
    actions:{
        TEACHER_GETSTUDENTSWITHCLASSTODAY : () =>{
            return new Promise((resolve,reject)=>{
                GetStudentsWithClassToday()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_GETACTIVESTUDENTS : () =>{
            return new Promise((resolve,reject)=>{
                GetActiveStudents()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_GETAVAILABLEHOURS : () =>{
            return new Promise((resolve,reject)=>{
                GetAvailableHours()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_GETLESSONSFORRESULT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetLessonsForResult(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_SAVELESSONSRESULT : (context,model) =>{
            return new Promise((resolve,reject)=>{
                SaveLessonsResult(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TEACHER_SAVEAVAILABLEHOURS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                SaveAvailableHours(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
    }
}

export default teacher_module;