<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="/" class="brand-link">
      <img src="../assets/logo.png" alt="Lengedu Logo" class="brand-image img-circle bg-white" style="opacity: .8">
      <span class="brand-text font-weight-light">Lengedu</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item">
            <router-link :to="{path:'/teacher/home'}" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>Dashboard</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{path:'/teacher/students'}" class="nav-link">
              <i class="nav-icon fas fa-phone-alt"></i>
              <p>Günlük Öğrenci Listesi</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{path:'/teacher/availablehours'}" class="nav-link">
              <i class="nav-icon fas fa-clock"></i>
              <p>Çalışma Saatleri</p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>

export default {
  name: "SideBar",
};
</script>