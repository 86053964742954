<template>
  <div class="modal fade" id="TestDetailModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Test Sonucu</h5>
            <button type="button" class="close" @click="CloseTestDetailModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <div class="p-3 border-bottom" v-for="q in Questions" :key="q" :style="{'background-color': q.right==userAnswer(q.id)?'#8bc34a':'#fff'}">
                <h3 class="text-center">{{q.id}}. Soru <i class="far" :class="{'fa-check-circle':q.right==userAnswer(q.id),'fa-times-circle':q.right!=userAnswer(q.id)}"></i></h3>
                <p>{{q.question}}</p>
                <div><strong>A)</strong> {{q.answers[0]}}</div>
                <div><strong>B)</strong> {{q.answers[1]}}</div>
                <div><strong>C)</strong> {{q.answers[2]}}</div>
                <div><strong>D)</strong> {{q.answers[3]}}</div>
                <div><strong>Doğru Cevap: </strong> {{q.answers[q.right]}} <span v-if="userAnswer(q.id)!=null"> | <strong>Öğrencinin Cevabı: </strong> {{q.answers[userAnswer(q.id)]}}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
export default {
  name: 'TestDetailModal',
  components: {  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    Questions:{
      type:Array,
      default:null
    },
    Answers:{
      type:Array,
      default:null
    }
  },
  data() {
    return {

    }
  },
  computed:{
   
  },
  methods:{
      CloseTestDetailModal(){
        window.$("#TestDetailModal").modal('hide');
        this.$emit("CloseTestDetailModal", false);
      },
      userAnswer(id){
          var findAnswer = this.Answers.find(x=>x.Id==id);
          if(findAnswer){
              return findAnswer.Answer;
          }
          return null;
      }
  },
  watch: {
    Visible(value){
      if(value){
        window.$("#TestDetailModal").modal({backdrop: 'static',keyboard: false, show: true});
      }
    }
  },
  mounted: function() {
   
  }
  
}
</script>