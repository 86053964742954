<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <select class="form-control form-control-sm" v-model="filterTime" @click="ApplyFilter()">
          <option value="" selected>Tüm Saatler</option>
          <option v-for="h in filterHours" :key="h" v-bind:value="h.key">{{ h.title }}</option>
        </select>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <select class="form-control form-control-sm" v-model="filterTeacher" @click="ApplyFilter()">
          <option value="" selected>Tüm Eğitmenler</option>
          <option v-for="t in teacherTimes" :key="t" v-bind:value="t.teacherId">{{ t.name+' '+t.surname+' ('+t.nickname+')' }}</option>
        </select>
      </div>
    </div>
    <div class="overflow-auto mb-1" style="height:400px;">
      <table border="1" :style="{'width':tableWidth}">
        <tr>
          <td class="text-center" height="80" style="width:85px;"><a href="javascript:;" @click="Selected()"><small>Free Teacher<br>Seç</small></a></td>
          <td rowspan="2" style="vertical-align:top;">
            <table border="1" width="100%">
              <tr>
                <td v-for="t in teacherTimes" :key="t" height="80" style="width:210px;" class="overTeacher text-center teacher" v-bind:id="'t'+t.teacherId">
                  <div style="height:50px;">
                    <small>{{t.name}} {{t.surname}}</small>
                    <div v-if="t.nickname && t.nickname.length>0"><small>({{ t.nickname }})</small></div>
                  </div>
                  <table border="1" width="100%">
                    <tr>
                      <td width="40%" class="text-center">&nbsp;</td>
                      <td width="30%" class="text-center"><small>2 Gün</small></td>
                      <td width="30%" class="text-center"><small>3 Gün</small></td>
                    </tr>
                  </table>
                  <table border="1" width="100%">
                    <tr v-for="m in t.availableHours" :key="m" v-bind:class="'times c'+m.key.substring(0,2)">
                      <td width="40%" class="text-center">
                        <a href="javascript:;" @click="Selected({id:t.teacherId,name:t.name+' ' + t.surname+' ('+t.nickname+')',time:m.key,showTime:m.title})" v-if="SelectLinkVisible(m.isAvailable,m.w2,m.w3)">Seç</a>
                        <span v-else>&nbsp;</span>
                      </td>
                      <td width="30%" class="text-center" v-bind:title="t.name+' ' + t.surname +' ('+m.title+')'+((m.w2Title!='')?' /'+m.w2Title:'')" >
                        <i class="fas fa-times-circle text-danger" v-if="!m.isAvailable"></i>
                        <i class="fas fa-check-circle text-success" v-else-if="m.w2"></i>
                        <span v-else-if="!m.w2">&nbsp;</span>
                      </td>
                      <td width="30%" class="text-center" v-bind:title="t.name+' ' + t.surname +' ('+m.title+')'+((m.w3Title!='')?' /'+m.w3Title:'')" >
                        <i class="fas fa-times-circle text-danger" v-if="!m.isAvailable"></i>
                        <i class="fas fa-check-circle text-success" v-else-if="m.w3"></i>
                        <span v-else-if="!m.w3">&nbsp;</span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td style="vertical-align:top;">
            <table border="1" width="100%">
              <tr v-for="m in enableHourMinutes" :key="m" v-bind:class="'times c'+m.key.substring(0,2)">
                <td class="text-center">{{m.title}}</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<style scoped>
  .overTeacher:hover {
    background-color: rgb(255, 254, 189);
  }
</style>
<script>
import {CalculateTime} from "../helpers/date-convert-helper";
export default {
  name: 'TeacherWorkTimes',
  components: {  },
  props: {
    Times: {
      type: [Array, Object]
    },
    TrianingWeekDay:{
      type:Number,
      default:5
    }
  },
  data() {
    return {
      enableHours:['2100','0400','0500','0600','0700','0800','0900','1000','1100','1200','1300','1400','1500','1600','1700','1800','1900','2000'],
      enableHourMinutes:[],
      teacherTimes:[],
      filterTeacher:'',
      filterTime:''
    }
  },
  computed:{
    tableWidth(){
      let width=210;
      let teacherCount=0;
      if(this.teacherTimes && this.filterTeacher==''){
        teacherCount = (150 * this.teacherTimes.length);
      }
      return  (width + teacherCount) +'px';
    },
    filterHours(){
      let hours = [];
      if(this.enableHours){
        for(let i=0;i<this.enableHours.length;i++){
          hours.push({key:this.enableHours[i],title:this.CalculateTimeByOffset(this.enableHours[i].substring(0,2)+':'+this.enableHours[i].substring(2,4))})
        }
      }
      return hours;
    }
  },
  methods:{
    SelectLinkVisible(isAvailable,w2,w3){
        if(!isAvailable){
          return false;
        }

        if(this.TrianingWeekDay==5 && (!w3 && !w2)){
          return true;
        }
          
        if(this.TrianingWeekDay==3 && !w3 ){
          return true;
        }

        if(this.TrianingWeekDay==2 && !w2 ){
          return true;
        }
    },
    CalculateTimeByOffset(value){
        return CalculateTime(value);
    },
    FormatTime(value){
      if(value.length!=8)
        return '';
      
      let startTime = this.CalculateTimeByOffset(value.substring(0,4).substring(0,2)+':'+value.substring(0,4).substring(2,4));
      let finishTime = this.CalculateTimeByOffset(value.substring(4,8).substring(0,2)+':'+value.substring(4,8).substring(2,4));
      return startTime + '-' + finishTime;
    },
    MapsTime(){
      this.teacherTimes=[];
      this.enableHourMinutes=[];
      let teachers = this.Times;
        for(let i=0;i<teachers.length;i++){
          let teacherTime = {
            teacherId:teachers[i].teacherId,
            name:teachers[i].name,
            surname:teachers[i].surname,
            nickname:teachers[i].nickname,
            availableHours:[],
          };
          
          let format = teachers[i].availableHours.map((x)=>({key:x.timeKey,title:this.FormatTime(x.timeKey),isAvailable:x.isAvailable,hasTwoDaysPerWeek:x.hasTwoDaysPerWeek,hasThreeDaysPerWeek:x.hasThreeDaysPerWeek,memberInfoTwoDaysPerWeek:x.memberInfoTwoDaysPerWeek,memberInfoThreeDaysPerWeek:x.memberInfoThreeDaysPerWeek}));
          for(let j=0;j<this.enableHours.length;j++){
            let m = format.filter(x=>x.key.substring(0,2)===this.enableHours[j].substring(0,2));
            for(let k=0;k<m.length;k++){
                teacherTime.availableHours.push({key:m[k].key,title:m[k].title,isAvailable:m[k].isAvailable,w2:m[k].hasTwoDaysPerWeek,w3:m[k].hasThreeDaysPerWeek,w2Title:m[k].memberInfoTwoDaysPerWeek,w3Title:m[k].memberInfoThreeDaysPerWeek});
                if(i==0){
                  this.enableHourMinutes.push({title:m[k].title,key:m[k].key});
                }
            }
          }
          this.teacherTimes.push(teacherTime);
        }
    },
    Selected(teacher){
      let data;
      if(teacher){
        data = {
          id:teacher.id,
          name:teacher.name,
          time:teacher.time.substring(0,4),
          showTime:teacher.showTime.split('-')[0]
        };
      }
      this.$emit("SelectedTeacherTime", data);
    },
    ApplyFilter(){
      if(this.filterTeacher==''){
        window.$('.teacher').show();
      }else{
        let id = this.filterTeacher;
        window.$('.teacher').hide();
        window.$('#t'+id).show();
      }

      if(this.filterTime==''){
        window.$('.times').show();
      }else{
        let time= this.filterTime.substring(0,2);
        window.$('.times').hide();
        window.$('.c'+time).show();
      }

    }
  },
  watch: {
    Times(value) {
      if(value)
        this.MapsTime();
    }
  }
  
}
</script>