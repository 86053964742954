<template>
<body class="hold-transition sidebar-mini">
  <div class="wrapper">
    <NavBar></NavBar>
    <SideBar></SideBar>
    <div class="content-wrapper"><router-view /></div>
    <Footer></Footer>
  </div>
</body>
</template>

<script>
import Footer from "./Footer.vue";
import SideBar from "./SideBar.vue";
import NavBar from "./NavBar.vue";

export default {
  name: "Panel",
  data(){
    return{
      content:"",
    }
  },
  components:{
    Footer,
    SideBar,
    NavBar
  }
};
</script>
