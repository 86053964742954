import axios from "axios";
import router from "../router";
import {store} from "../store/index";
import {SECRET_KEY} from "../settings";
import CryptoJS from 'crypto-js';
const service = axios.create({
    baseURL : process.env.VUE_APP_ROOT_API,
    timeout:60000
})

service.interceptors.request.use(config=>{
        config.headers['Accept']='application/json';
        config.headers['Content-Type'] = 'application/json';
        let token = store.getters["TOKEN"];
        if(token!=''){
            config.headers['Authorization'] = 'Bearer '+token;
        }

        let lang = store.getters["LANGUAGE"];
        if(lang!=''){
            config.headers['Accept-Language'] = lang;
        }
        return config
    },error=>{
        Promise.reject(error)
    }
)

service.interceptors.response.use(
    response=>{
        return response;
    },
    async error=>{
        const originalConfig = error.config;
        if(error.response){
            if(error.response.status===401){
                originalConfig._retry = true;
                try {
                    let token = store.getters["REFRESHTOKEN"];
                    await axios.create({
                        baseURL : process.env.VUE_APP_ROOT_API,
                        timeout:60000
                    }).post('/Login/RefreshToken', {PanelType:0,RefreshToken:token})
                    .then(res=>{
                        let userData = res.data;
                        store.commit("SET_USER",userData);
                        let userDataEncrypt = CryptoJS.AES.encrypt(JSON.stringify(userData), SECRET_KEY).toString();
                        window.localStorage.setItem('user',userDataEncrypt);
                        originalConfig.headers.token=store.getters["TOKEN"];
                        return service(originalConfig);
                    })
                    .catch(err=>{
                        if(err.response.status===401){
                            store.dispatch("LOGOUT");
                            router.push("/login");
                        }
                    });   
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }
                    return Promise.reject(_error);
                }
            }
            if (error.response.status === 403 && error.response.data) {
                return Promise.reject(error.response.data);
            }
            return Promise.reject(error);
        }else{
            return Promise.reject(error);
        }
    }
)

export {service}