const locale = {
    format: 'DD/MM/YYYY',
    separator: ' - ',
    applyLabel: 'Seç',
    cancelLabel: 'İptal',
    daysOfWeek: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu','Ct'],
    monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
    firstDay: 1
  }

export {locale}