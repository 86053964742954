<template>
  <div class="modal fade" id="MemberProfileModal" tabindex="1" role="dialog">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <ul class="nav nav-tabs justify-content-end mt-1" id="myTab" role="tablist">
              <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle text-secondary" data-toggle="dropdown" href="#" role="button" aria-expanded="false"><i class="fas fa-pencil-alt"></i> Not</a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#"><i class="fas fa-plus"></i> Ekle</a>
                  <a class="dropdown-item" href="#" data-toggle="tab" data-target="#Note"><i class="far fa-list-alt"></i> Liste</a>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link dropdown-toggle text-secondary" data-toggle="dropdown" href="#" role="button" aria-expanded="false"><i class="fas fa-tasks"></i> Görev</a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#"><i class="fas fa-plus"></i> Oluştur</a>
                  <a class="dropdown-item" href="#" data-toggle="tab" data-target="#Task"><i class="far fa-list-alt"></i> Liste</a>
                </div>
              </li> -->
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle text-secondary" :class="{'active':tabTrainingVisible}" data-toggle="dropdown" href="#" role="button" aria-expanded="false"><i class="fas fa-graduation-cap"></i> Eğitim</a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="javascript:;" @click="OpenTab('TabTrainingCreate')">Eğitim Hesabı Oluştur</a>
                  <a class="dropdown-item" href="javascript:;" @click="OpenTab('TabTraining')">Eğitim Listesi</a>
                  <!-- <a class="dropdown-item" href="javascript:;" @click="OpenTab('TabTrainingCalender')">Eğitim Takvimi</a> -->
                </div>
              </li>

              <li class="nav-item">
                <a class="nav-link text-secondary" :class="{'active':tabNoteVisible}" href="javascript:;" @click="OpenTab('TabNote')"><i class="fas fa-sticky-note"></i> Notlar</a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-secondary" :class="{'active':tabTaskVisible}" href="javascript:;" @click="OpenTab('TabTask')"><i class="fas fa-tasks"></i> Görevler</a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-secondary" :class="{'active':tabStatisticVisible}" href="javascript:;" @click="OpenTab('TabStatistic')"><i class="fas fa-chart-line"></i> İstatistik</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle text-secondary" :class="{'active':tabPaymentVisible}" data-toggle="dropdown" href="#" role="button" aria-expanded="false"><i class="fas fa-hand-holding-usd"></i> Muhasebe</a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="javascript:;" @click="OpenTab('TabPaymentCheckOut')">Ödeme Alma</a>
                  <a class="dropdown-item" href="javascript:;" @click="OpenTab('TabPayment')">Ödemeler</a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle text-secondary" :class="{'active':tabFormHistoryVisible}" data-toggle="dropdown" href="#" role="button" aria-expanded="false"><i class="fas fa-ellipsis-v"></i> Diğer</a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="javascript:;" @click="OpenTab('TabInformation')" v-if="IsPermision(PermissionCodes.STUDENT20)">Bildirimler</a>
                  <a class="dropdown-item" href="javascript:;" @click="OpenTab('TabTransaction')">İşlemler</a>
                  <a class="dropdown-item" href="javascript:;" @click="OpenTab('TabFormHistory')">Form Geçmişi</a>
                  
                </div>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link text-secondary" href="#" data-toggle="tab" data-target="#Accounting" ><i class="fas fa-coins"></i> Muhasebe</a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-secondary" href="#" data-toggle="tab" data-target="#Transaction"><i class="fas fa-history"></i> Geçmiş</a>
              </li> 
            -->
              <li class="nav-item">
                <a class="nav-link text-secondary" href="javascript:;" @click="CloseMemberProfileModal()"><i class="fas fa-times"></i></a>
              </li>
          </ul>  
          <div class="modal-body pt-0">
            <div class="row">
              <div class="col-lg-4">
                <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
                <div v-if="!isLoading">
                  <div class="clearfix mb-2 mt-3">
                    <img src="@/assets/default.png" class="rounded-circle float-left mr-3" style="width: 75px; background-color: #CCC;" alt="...">
                    <div class="float-left">
                      <h3 class="text-capitalize mb-0 mt-2" v-html="FullName"></h3>
                      <div><small v-html="MemberId"></small></div>
                    </div>
                  </div>
                  <div class="mb-2"><i class="fas fa-envelope"></i><span class="ml-2" v-html="Email"></span></div>
                  <div class="row mb-2">
                    <div class="col-6"><div class="font-weight-bold"><i class="fas fa-phone-alt"></i> Telefon</div><div v-html="Phone"></div></div>
                    <div class="col-6"><div class="font-weight-bold"><i class="fas fa-star"></i> Referans No</div><span v-html="ReferenceNumber"></span></div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <div class="font-weight-bold"><i class="fas fa-calendar-alt"></i> Form Kayıt Tarihi</div><div v-html="FormCreationDate"></div>
                    </div>
                    <div class="col-6">
                      <div class="font-weight-bold"><i class="fas fa-calendar-alt"></i> Öğrenci Kayıt Tarihi</div><div v-if="StudentCreationDate!=''" v-html="StudentCreationDate"></div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <div class="font-weight-bold"><i class="fas fa-male"></i><i class="fas fa-female"></i> Cinsiyet</div><div v-if="Gender!==null && Gender!==0" v-html="Gender"></div>
                    </div>
                    <div class="col-6">
                      <div class="font-weight-bold"><i class="fas fa-calendar-alt"></i> Doğum Tarihi</div><div v-if="Birthday!==null" v-html="Birthday"></div>
                    </div>
                  </div>
                  <div class="row mb-3 mt-3" v-if="IsStudent && IsPermision(PermissionCodes.STUDENT19)">
                    <div class="col-12">
                      <button class="btn btn-sm btn-primary" @click="handleOpenCloseMemberChangePasswordModal(true)"><i class="fas fa-key"></i> Şifre Değiştir</button>
                    </div>
                  </div>

                  <div class="mb-2">
                    <div class="text-sm border-bottom border-1" v-for="u in UtmData" :key="u">
                      <span class="text-bold">{{u.label}}</span>
                      <div>{{u.value}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 border border-top-0">
                <div class="tab-content" id="MyProfileModalNav">
                <!--
                  <div class="tab-pane fade show active" id="Note" role="tabpanel" aria-labelledby="home-tab">Notlar</div>
                  <div class="tab-pane fade" id="Task" role="tabpanel" aria-labelledby="profile-tab">Görev / Hatılratma</div> 
                -->
                  <TabTraining :MemberId="MemberId" :Visible="tabTrainingVisible" @NewPackage="handleNewPackage($event)"></TabTraining>
                  <TabStatistic :MemberId="MemberId" :Visible="tabStatisticVisible"></TabStatistic>
                  <TabPayment :MemberId="MemberId" :Visible="tabPaymentVisible"></TabPayment>
                  <TabPaymentCheckOut :MemberId="MemberId" :Visible="tabPaymentCheckOutVisible"></TabPaymentCheckOut>
                  <TabTask :MemberId="MemberId" :Visible="tabTaskVisible"></TabTask>
                  <TabNote :MemberId="MemberId" :Visible="tabNoteVisible"></TabNote>
                  <TabFormHistory :MemberId="MemberId" :Visible="tabFormHistoryVisible"></TabFormHistory>
                  <TabTransaction :MemberId="MemberId" :Visible="tabTransactionVisible"></TabTransaction>
                  <TabTrainingCreate :MemberId="MemberId" :Visible="tabTrainingCreateVisible" :AccountId="accountId" @AfterTrainingCreate="handleAfterTrainingCreate()"></TabTrainingCreate>
                  <TabTrainingCalender :MemberId="MemberId" :Visible="tabTrainingCalenderVisible"></TabTrainingCalender>
                  <TabInformation :MemberId="MemberId" :Visible="tabInformationVisible"></TabInformation>
                <!--
                  <div class="tab-pane fade" id="Accounting" role="tabpanel" aria-labelledby="contact-tab">Muhasebe</div>
                  <div class="tab-pane fade" id="Transaction" role="tabpanel" aria-labelledby="contact-tab">İşlemler</div> 
                -->
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MemberChangePasswordModal :Visible="memberChangePasswordModalVisible" :MemberId="id" @OpenCloseMemberChangePasswordModal="handleOpenCloseMemberChangePasswordModal($event)"></MemberChangePasswordModal>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import permissionMixins from "../../mixins/permissons-mixin";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
import {DateFormat,CalculateDateTime} from "../../helpers/date-convert-helper";
import {GetGenderText} from "../../helpers/enum-convert-helper";
import TabStatistic from "./TabStatistic.vue";
import TabTraining from "./TabTraining.vue";
import TabPayment from "./TabPayment.vue";
import TabPaymentCheckOut from "./TabPaymentCheckOut.vue";
import TabTask from "./TabTask.vue";
import TabNote from "./TabNote.vue";
import TabFormHistory from "./TabFormHistory.vue";
import TabTransaction from "./TabTransaction.vue";
import TabTrainingCreate from "./TabTrainingCreate.vue";
import TabTrainingCalender from "./TabTrainingCalender.vue";
import TabInformation from "./TabInformation.vue";
import MemberChangePasswordModal from "./Modals/MemberChangePasswordModal.vue";
export default {
  name: 'MemberProfileModal',
  emits:['CloseMemberProfileModal'],
  components: { 
    TabStatistic,
    TabTraining,
    TabTrainingCreate,
    TabTrainingCalender,
    TabPayment,
    TabTask,
    TabNote,
    TabPaymentCheckOut,
    TabFormHistory,
    TabTransaction,
    TabInformation,
    MemberChangePasswordModal
    },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    MemberId:{
      type:String,
      default:null
    }
  },
  data() {
    return {
      id : null,
      name: null,
      surname: null,
      eMail: null,
      phoneNumber: null,
      referenceNumber: null,
      registerStatus: 0,
      studentStatus: 0,
      formStatus: 0,
      formCreationDate: null,
      studentCreationDate: null,
      gender: null,
      birthday: null,
      utmContent : null,
      utmCampaign : null,
      utmMedium  : null,
      utmSource : null,
      utmTerm  : null,

      accountId:'',
      isLoading:true,

      tabStatisticVisible:false,
      tabTrainingVisible:false,
      tabTrainingCreateVisible:false,
      tabPaymentVisible:false,
      tabTaskVisible:false,
      tabNoteVisible:false,
      tabPaymentCheckOutVisible:false,
      tabFormHistoryVisible:false,
      tabTransactionVisible:false,
      tabTrainingCalenderVisible:false,
      tabInformationVisible : false,

      memberChangePasswordModalVisible:false
    }
  },
  computed:{
    FullName : function(){
      if(this.name!==null && this.surname!==null){
        return this.name + ' ' + this.surname;
      }
      else if(this.name!==null){
        return this.name;
      }
      else if(this.surname!==null){
        return this.surname;
      }
      else
        return '';
    },
    Email:function(){
      if(this.eMail!==null){
        return this.eMail;
      }
      else
        return '';
    },
    Phone:function(){
      if(this.phoneNumber!==null){
        return this.phoneNumber;
      }
      else
        return '';
    },
    ReferenceNumber:function(){
      if(this.referenceNumber!==null){
        return this.referenceNumber;
      }
      else
        return '';
    },
    FormCreationDate:function(){
      if(this.formCreationDate!==null){
        return DateFormat(this.CalculateDateTimeByOffset(this.formCreationDate),'yyyy-mm-dd hh:MM:ss');
      }
      else
        return '';
    },
    StudentCreationDate:function(){
      if(this.studentCreationDate!==null){
        return DateFormat(this.CalculateDateTimeByOffset(this.studentCreationDate),'yyyy-mm-dd hh:MM:ss');
      }
      else
        return '';
    },
    IsStudent:function(){
      if(this.registerStatus!==null){
        return this.registerStatus===2;
      }
      return false;
    },
    UtmData:function(){
      let utmDataArr=[];
      if(this.utmContent!=null && this.utmContent!==''){
        utmDataArr.push({label:'Utm Content',value:this.utmContent});
      }

      if(this.utmCampaign!=null && this.utmCampaign!==''){
        utmDataArr.push({label:'Utm Campaign',value:this.utmCampaign});
      }

      if(this.utmMedium!=null && this.utmMedium!==''){
        utmDataArr.push({label:'Utm Medium',value:this.utmMedium});
      }

      if(this.utmSource!=null && this.utmSource!==''){
        utmDataArr.push({label:'Utm Source',value:this.utmSource});
      }
    
      if(this.utmTerm!=null && this.utmTerm!==''){
        utmDataArr.push({label:'Utm Term',value:this.utmTerm});
      }
      return utmDataArr;
    },
    Birthday:function(){
      if(this.birthday!==null){
        return this.birthday;
      }
      return null;
    },
    Gender:function(){
      if(this.gender!==null && this.gender!==0){
        return GetGenderText(this.gender);
      }
      return null;
    },
  },
  methods:{
    ...mapActions(["MEMBERPROFILE_GETMEMBER"]),
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    GetProfile(){
      this.isLoading=true;
        this.MEMBERPROFILE_GETMEMBER({id:this.MemberId})
        .then((res)=>{
          this.isLoading=false;
          this.id = res.data.id;
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.eMail = res.data.eMail;
          this.phoneNumber = res.data.phoneNumber;
          this.referenceNumber = res.data.referenceNumber;
          this.registerStatus = res.data.registerStatus;
          this.studentStatus = res.data.studentStatus;
          this.formStatus = res.data.formStatus;
          this.formCreationDate = res.data.formCreationDate;
          this.studentCreationDate = res.data.studentCreationDate;
          this.utmContent = res.data.utmContent;
          this.utmCampaign = res.data.utmCampaign;
          this.utmMedium = res.data.utmMedium;
          this.utmSource = res.data.utmSource;
          this.utmTerm = res.data.utmTerm;
          this.gender = res.data.gender;
          this.birthday= res.data.birthday;
        })
        .catch(error=>{
          this.isLoading=false;
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
          });
    },
    CloseMemberProfileModal(){
        window.$("#MemberProfileModal").modal('hide');
        this.$emit("CloseMemberProfileModal", false);
      },
    OpenTab(value){
      this.tabStatisticVisible=false;
      this.tabTrainingVisible=false;
      this.tabTrainingCreateVisible=false;
      this.tabPaymentVisible=false;
      this.tabTaskVisible=false;
      this.tabNoteVisible=false;
      this.tabPaymentCheckOutVisible=false;
      this.tabFormHistoryVisible=false;
      this.tabTransactionVisible=false;
      this.tabTrainingCalenderVisible=false;
      this.tabInformationVisible=false;

      if(value==='TabStatistic'){
        this.tabStatisticVisible=true;
      }else if(value==='TabTraining'){
        this.tabTrainingVisible=true;
      }else if(value==='TabTrainingCreate'){
        this.tabTrainingCreateVisible=true;
      }else if(value==='TabTrainingCalender'){
        this.tabTrainingCalenderVisible=true;
      }else if(value==='TabPayment'){
        this.tabPaymentVisible=true;
      }else if(value==='TabTask'){
        this.tabTaskVisible=true;
      }else if(value==='TabNote'){
        this.tabNoteVisible=true;
      }else if(value==='TabPaymentCheckOut'){
        this.tabPaymentCheckOutVisible=true;
      }else if(value==='TabFormHistory'){
        this.tabFormHistoryVisible=true;
      }else if(value==='TabTransaction'){
        this.tabTransactionVisible=true;
      }else if(value==='TabInformation'){
        this.tabInformationVisible=true;
      }

    },
    handleNewPackage(value){
      //console.log(value);
      this.accountId=value;
      this.OpenTab('TabTrainingCreate');
    },
    handleAfterTrainingCreate(){
      this.OpenTab('TabTraining');
    },
    handleOpenCloseMemberChangePasswordModal(value){
      this.memberChangePasswordModalVisible = value;
    }
  },
  watch: {
    Visible(value){
      if(value){
        this.GetProfile();
        this.OpenTab('TabTraining');
        window.$("#MemberProfileModal").modal({backdrop: 'static',keyboard: false, show: true});
      }else{
        this.OpenTab('TabTrainingCreate');
      }
    }
  },
  mounted: function() {
  },
  mixins:[permissionMixins],
  
}
</script>