<template>
   <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Anasayfa</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item active"><a href="#">Dashboard</a></li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <AdminDashboard v-if="isAdmin"></AdminDashboard>
    <OperatorDashboard v-if="isOperator"></OperatorDashboard>
    <SaleDashboard v-if="isSales"></SaleDashboard>
    <OtherDashboard v-if="isOther"></OtherDashboard>
    
</template>

<script>
import {mapGetters} from "vuex";
import AdminDashboard from './dashboard/AdminDashboard.vue';
import OperatorDashboard from './dashboard/OperatorDashboard.vue';
import SaleDashboard from './dashboard/SaleDashboard.vue';
import OtherDashboard from './dashboard/OtherDashboard.vue';
export default {
  name: "Home",
  data(){
    return{
      content:"",
    }
  },
  components:{
    AdminDashboard,
    OperatorDashboard,
    SaleDashboard,
    OtherDashboard
  },
  computed:{
    ...mapGetters(["IS_ADMIN","IS_OPERATOR","IS_SALES"]),
    isAdmin(){
      return this.IS_ADMIN;
    },
    isOperator(){
      return this.IS_OPERATOR;
    },
    isSales(){
      return this.IS_SALES;
    },
    isOther(){
      return (!this.isAdmin && !this.isSales && !this.isOperator);
    }
  }
};
</script>
