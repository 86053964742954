<template>
  <div>
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" @click="GetPage(page-1)" v-show="pageList.length>0"><button class="page-link">&laquo;</button></li>
      <li v-for="p in firstPage" :key="p" class="page-item"  @click="GetPage(p)"><button class="page-link" >İlk Sayfa</button></li>
      <li v-for="p in pageList" :key="p" class="page-item" :class="{'active':p==page}"  @click="GetPage(p)"><button class="page-link" >{{p}}</button></li>
      <li v-for="p in lastPage" :key="p" class="page-item"  @click="GetPage(p)"><button class="page-link" >Son Sayfa</button></li>
      <li class="page-item" @click="GetPage(page+1)" v-show="pageList.length>0"><button class="page-link">&raquo;</button></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PaginationBST',
  components: {  },
  props: {
    page: {
      type: Number,
      default: 0
    },
    totalPage: {
      type: Number,
      default: 0
    },
    
  },
  data() {
    return {
      
    }
  },
  computed:{
    firstPage(){
      let pages = [];
      if(this.page>5){
        pages.push(1);
      }
      return pages;
    },
    lastPage(){
      let pages = [];
      if(this.page<this.totalPage-4){
        pages.push(this.totalPage);
      }
      return pages;
    },
    pageList(){
      let pages = [];
      if(this.page>0){
        let start = this.page-4;
        if(start<=0){
          start=1;
        }
        let end = this.page+4;
        if(end>this.totalPage)
        {
          end = this.totalPage;
        }

        for(let i=start;i<=end;i++){
          pages.push(i);
        }
      }
      return pages;
    }
  },
  methods:{
    GetPage(pageNumber){
      this.$emit("PageNumber", pageNumber);
    }
  }
}
</script>