import {service} from "../helpers/request"

function CCPayment(model){
    return service({
        url:'/Payment/CCPayment',
        method:'post',
        data:{
            memberId: model.memberId,
            paymentType : model.paymentType,
            cardHolder : model.cardHolder,
            cardNumber : model.cardNumber,
            cardCvv : model.cardCvv,
            cardExpireDate : model.cardExpireDate,
            installment : parseInt(model.installment),
            isThreeD : Boolean(model.isThreeD),
            amount : parseFloat(model.amount),
            currency : parseInt(model.currency)
        }
    })
}

function MoneyOrderPayment(model){
    return service({
        url:'/Payment/MoneyOrderPayment',
        method:'post',
        data:{
            memberId: model.memberId,
            paymentType : model.paymentType,
            bankName : model.bankName,
            amount : parseFloat(model.amount),
            currency : parseInt(model.currency)
        }
    })
}

async function PaymentInternalAsync(model) {
    return service({
        url: '/Payment/Internal',
        method: 'post',
        data: model
    });
}

function GetFreePayments(memberId){
    return service({
        url:'/Payment/GetFreePayments?memberId='+memberId,
        method:'post',
        data:{}
    })
}

async function PaymentInternal(model) {
    return service({
        url: '/Payment/PaymentInternal',
        method: 'post',
        data: model
    });
}

export {
    CCPayment,
    GetFreePayments,
    MoneyOrderPayment,
    PaymentInternalAsync,
    PaymentInternal
};
