<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Öğrenci İşlemleri</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item active">Öğrenci İşlemleri</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">        
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 col-md-8 col-lg-8">
                      <div class="float-left mr-1" style="width:150px">
                        <select class="form-control form-control-sm" v-model="mainList.filter.listType">
                          <option value="" selected></option>
                          <option value="active">Aktif Öğrenci</option>
                          <option value="findteacher">Eğitmen Arayan</option>
                          <option value="breaktime">Yedek Eğitmen</option>
                          <option value="freezeeducation">Ders Donduran</option>
                          <option value="complated">Eğitimi Tamamlayan</option>
                          <option value="inactive">Pasif Öğrenci</option>
                        </select>
                      </div>
                      <div class="float-left mr-1" style="width:120px">
                          <button type="button" class="btn btn-sm btn-default" @click="GetStudent(1)">Listele</button>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                      <div class="float-right">
                        <div class="input-group input-group-sm">
                          <select class="form-control" v-model="mainList.filter.searchTextType">
                            <option value="1">Ad Soyad</option>
                            <option value="3">E-Posta</option>
                            <option value="2">Telefon</option>
                          </select>
                          <input type="text" class="form-control">
                          <span class="input-group-append">
                            <button type="button" class="btn btn-default btn-flat" @click="OnListButtonForSearchBox(1)">Ara</button>
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <span v-if="mainList.filter.listType==null">Henüz bir liste seçmediniz! Yukarıda bulunan listeleme seçenekleri kullanabilirsiniz.</span>
                <span v-else-if="mainList.dataLoading"><i class="fas fa-solid fa-circle-notch fa-spin"></i> Yükleniyor. Lütfen bekleyiniz.</span>
                <span v-else>
                  <h5 class="float-left">{{mainList.listTitle}}</h5>
                  <span class="float-right">{{mainList.totalItems}} adet kayıt bulundu.</span>
                </span>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="row p-2 border-bottom" v-for="(item,index) in mainList.items" :key="index" >
                  <div class="col-sm-12 col-md-4">
                    <h5 class="float-left text-capitalize"><i class="nav-icon fas fa-sm" v-if="item.gender>0" :class="{'fa-mars text-primary':item.gender==1,'fa-venus text-danger':item.gender==2}"></i> {{item.name}} {{item.surname}}</h5>
                    <div class="clearfix"></div>
                    <span class="mr-1 text-sm" v-html="item.id"></span>
                    <div class="clearfix"></div>
                    <span class="badge mr-1" :class="{'badge-danger':item.studentStatus==0,'badge-success':item.studentStatus==1}" v-html="StudentStatus(item.studentStatus)"></span>
                    <span class="p-1 mr-1 pointer text-sm"><i class="nav-icon fas fa-phone fa-sm"></i> {{item.phoneNumber}}</span>
                    <a class="ml-2" v-bind:href="'https://wa.me/' + item.phoneNumber.replace('0090-','90')" target="_blank"><i class="fab fa-whatsapp"></i></a>&nbsp;|&nbsp;
                    <span class="p-1 pointer text-sm"><i class="nav-icon fas fa-at fa-sm"></i> {{item.eMail}}</span>
                    <div class="clearfix"></div>
                  </div>
                  
                  <div class="col-sm-12 col-md-3">
                    <div>
                      <span class="badge mr-2 float-left badge-danger" v-if="item.trainingFile.isDemo">Demo</span>
                      <span class="badge mr-2 float-left badge-info" v-if="item.trainingFile.isTryYourself">Kendini Dene</span>
                      <div class="clearfix"></div>
                      <h5 class="float-left">{{CalculateTimeByOffset(item.trainingFile.lessonTime)}} {{item.trainingFile.teacherName}}</h5>
                      <span class="badge ml-2 mt-1 float-left" :class="{'badge-success':item.trainingFile.status==1,'badge-default':item.trainingFile.status==2,'badge-warning':item.trainingFile.status>2}">{{TrainingFileStatus(item.trainingFile.status)}}</span>
                      
                      <div class="clearfix"></div>
                    </div>
                    <div class="text-sm pointer">
                        <i class="fas fa-phone-alt" v-if="item.trainingFile.contactType===1"></i> 
                        <i class="far fa-envelope" v-else-if="item.trainingFile.contactType===2"></i> 
                        <i class="fab fa-skype" v-else-if="item.trainingFile.contactType===3"></i> 
                        {{item.trainingFile.contactData}}
                    </div>
                    <div class="mt-2 text-sm" v-tooltip="'Eğitim Paketi'">
                      <span class="mr-2"><i class="far fa-calendar-alt"></i> {{item.trainingFile.month}} Ay (Haftada {{item.trainingFile.weekDay}} Gün)</span>
                      <span><i class="fas fa-stopwatch"></i> {{item.trainingFile.duration}} Dakika</span>
                    </div>                   
                  </div>
                  
                  <div class="col-sm-12 col-md-3">
                      <ul class="pagination pagination-month pagination-sm justify-content-center" v-tooltip="'Son 5 dersin durumu'">
                        <li class="page-item"><a class="page-link" href="javascript:;">«</a></li>
                        <li class="page-item" v-for="lesson in item.lastLessons" :key="lesson.date">
                            <a class="page-link" href="javascript:;">
                                <p class="page-month" v-if="lesson.status===0">-</p>
                                <p class="page-month text-danger" v-else-if="lesson.status===1"><i class="fas fa-times"></i></p>
                                <p class="page-month" v-else-if="lesson.status===2"><i class="fas fa-check"></i></p>
                                <p class="page-month text-warning" v-else-if="lesson.status===3"><i class="fas fa-redo-alt"></i></p>
                                <p class="page-month text-danger" v-else-if="lesson.status===4"><i class="fas fa-times"></i></p>
                                <p class="page-year text-muted">{{ScheduleDateFormat(lesson.date)}}</p>
                            </a>
                        </li>
                        <li class="page-item"><a class="page-link" href="javascript:;">»</a></li>
                      </ul>
                        <div class="mt-2">
                          <div class="row border border-1">
                            <div class="col-4 text-bold">Toplam:</div>
                            <div class="col-2">{{item.trainingFile.lessonTotalCount}}</div>
                            <div class="col-4 text-bold">Kalan:</div>
                            <div class="col-2">{{item.trainingFile.lessonWaitCount}}</div>
                          </div>
                          <div class="row border border-1">
                            <div class="col-4 text-bold text-success">İşlenen:</div>
                            <div class="col-2">{{item.trainingFile.lessonComplatedCount}}</div>
                            <div class="col-4 text-bold text-danger">İşlenmeyen:</div>
                            <div class="col-2">{{item.trainingFile.lessonTotalCount-(item.trainingFile.lessonComplatedCount+item.trainingFile.lessonWaitCount)}}</div>
                          </div>
                        </div>
                  </div>
                  <!-- <div class="col-sm-12 col-md-2">
                    <div class="role bg-info float-left text-center text-sm  rounded mb-1 mr-1 p-2" v-for="user in item.memberUsers.filter(x=>x.fullName.length>1)" :key="user"  v-tooltip="Role(user.role)" >{{user.fullName}}</div>
                  </div> -->
                  
                </div>
              </div>
              <!-- /.card-body -->
              <!-- /.card -->
              <div class="card-footer clearfix" v-show="mainList.totalPages>0">
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-show="(mainList.currentPage-1)>0"><button class="page-link" @click="GetStudent(currentPage-1)" :disabled="mainList.dataLoading">&laquo;</button></li>
                  <li v-for="page in mainList.totalPages" :key="page" class="page-item" :class="{'active':page==mainList.currentPage}"><button class="page-link" @click="GetStudent(page)" :disabled="mainList.dataLoading">{{page}}</button></li>
                  <li class="page-item" v-show="(mainList.currentPage+1)<=mainList.totalPages"><button class="page-link" @click="GetStudent(mainList.currentPage+1)" :disabled="mainList.dataLoading">&raquo;</button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->

</template>

<script>
import {mapActions} from "vuex"
import {GetStatusText,GetTrainingFileStatus} from "../../../helpers/enum-convert-helper";
import {CalculateTime,DateFormat} from "../../../helpers/date-convert-helper";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
export default {
  name: "Student",
  //components: {},
  data(){
    return{
      mainList:{
        currentPage:0,
        items:[],
        pageSize:0,
        totalItems:0,
        totalPages:0,
        filter:{
          listType:null,
          searchTextType:1,
          searchText:null,
        },
        listTitle:null,
        dataLoading:false,
      },
    }
  },
  computed:{
    
  },
  watch:{
    
  },
  methods: {
    ...mapActions([
        "MEMBER_STUDENTOPERATORSAVE","MEMBER_ACTIVESTUDENTLIST","MEMBER_SEARCHSTUDENTLIST","MEMBER_COMPLATEDSTUDENTLIST", "MEMBER_FREEZEEDUCATIONSTUDENTLIST","MEMBER_FINDTEACHERSTUDENTLIST","MEMBER_BREAKTIMESTUDENTLIST","MEMBER_INACTIVESTUDENTLIST"
      ]),
    GetStudent(page){
        switch (this.mainList.filter.listType) {
          case 'active':
            this.GetActiveStudent(page);
            break;
          case 'breaktime':
            this.GetBreakTimeStudent(page);
            break;
          case 'findteacher':
            this.GetFindTeacherStudent(page);
            break;
          case 'freezeeducation':
            this.GetFreezeEducationStudent(page);
            break;
          case 'inactive':
            this.GetInActiveStudent(page);
            break;
          case 'complated':
            this.GetComplatedStudent(page);
            break;
          case 'searchtext':
          this.GetSearchStudent(page,this.mainList.filter.searchTextType,this.mainList.filter.searchText);
            break;
          default:
            break;
        }
    },
    OnListButtonForSearchBox(page){
      this.mainList.filter.listType='searchtext';
      this.GetStudent(page);
    },

    GetActiveStudent(page) {
      this.mainList.dataLoading=true;
      this.mainList.currentPage=0;
      this.mainList.items=[];
      this.mainList.pageSize=0;
      this.mainList.totalItems=0;
      this.mainList.totalPages=0;
      this.mainList.listTitle='Aktif Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_ACTIVESTUDENTLIST({page})
      .then((res)=>{
        
            this.mainList.currentPage=res.data.currentPage;
            this.mainList.items = res.data.items;
            this.mainList.pageSize=res.data.pageSize;
            this.mainList.totalItems=res.data.totalItems;
            this.mainList.totalPages=res.data.totalPages;
            this.mainList.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.mainList.dataLoading=false;
        });
    },
    GetInActiveStudent(page) {
      this.mainList.dataLoading=true;
      this.mainList.currentPage=0;
      this.mainList.items=[];
      this.mainList.pageSize=0;
      this.mainList.totalItems=0;
      this.mainList.totalPages=0;
      this.mainList.listTitle='Pasif Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_INACTIVESTUDENTLIST({page})
      .then((res)=>{
            this.mainList.currentPage=res.data.currentPage;
            this.mainList.items = res.data.items;
            this.mainList.pageSize=res.data.pageSize;
            this.mainList.totalItems=res.data.totalItems;
            this.mainList.totalPages=res.data.totalPages;
            this.mainList.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    GetComplatedStudent(page) {
      this.mainList.dataLoading=true;
      this.mainList.currentPage=0;
      this.mainList.items=[];
      this.mainList.pageSize=0;
      this.mainList.totalItems=0;
      this.mainList.totalPages=0;
      this.mainList.listTitle='Eğitimi Tamamlayan Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_COMPLATEDSTUDENTLIST({page})
      .then((res)=>{
            this.mainList.currentPage=res.data.currentPage;
            this.mainList.items = res.data.items;
            this.mainList.pageSize=res.data.pageSize;
            this.mainList.totalItems=res.data.totalItems;
            this.mainList.totalPages=res.data.totalPages;
            this.mainList.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.mainList.dataLoading=false;
        });
    },
    GetBreakTimeStudent(page) {
      this.mainList.dataLoading=true;
      this.mainList.currentPage=0;
      this.mainList.items=[];
      this.mainList.pageSize=0;
      this.mainList.totalItems=0;
      this.mainList.totalPages=0;
      this.mainList.listTitle='Yedek Eğitmende Olan Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_BREAKTIMESTUDENTLIST({page})
      .then((res)=>{
            this.mainList.currentPage=res.data.currentPage;
            this.mainList.items = res.data.items;
            this.mainList.pageSize=res.data.pageSize;
            this.mainList.totalItems=res.data.totalItems;
            this.mainList.totalPages=res.data.totalPages;
            this.mainList.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.mainList.dataLoading=false;
        });
    },
    GetFindTeacherStudent(page) {
      this.mainList.dataLoading=true;
      this.mainList.currentPage=0;
      this.mainList.items=[];
      this.mainList.pageSize=0;
      this.mainList.totalItems=0;
      this.mainList.totalPages=0;
      this.mainList.listTitle='Eğitmen Arayan Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_FINDTEACHERSTUDENTLIST({page})
      .then((res)=>{
            this.mainList.currentPage=res.data.currentPage;
            this.mainList.items = res.data.items;
            this.mainList.pageSize=res.data.pageSize;
            this.mainList.totalItems=res.data.totalItems;
            this.mainList.totalPages=res.data.totalPages;
            this.mainList.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.mainList.dataLoading=false;
        });
    },
    GetFreezeEducationStudent(page) {
      this.mainList.dataLoading=true;
      this.mainList.currentPage=0;
      this.mainList.items=[];
      this.mainList.pageSize=0;
      this.mainList.totalItems=0;
      this.mainList.totalPages=0;
      this.mainList.listTitle='Ders Donduran Öğrenciler';
      if(!page) {  page=1; }
      
      this.MEMBER_FREEZEEDUCATIONSTUDENTLIST({page})
      .then((res)=>{
            this.mainList.currentPage=res.data.currentPage;
            this.mainList.items = res.data.items;
            this.mainList.pageSize=res.data.pageSize;
            this.mainList.totalItems=res.data.totalItems;
            this.mainList.totalPages=res.data.totalPages;
            this.mainList.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.mainList.dataLoading=false;
        });
    },
    GetSearchStudent(page,searchField,searchText) {
      this.mainList.dataLoading=true;
      this.mainList.currentPage=0;
      this.mainList.items=[];
      this.mainList.pageSize=0;
      this.mainList.totalItems=0;
      this.mainList.totalPages=0;
      this.mainList.listTitle='Öğrenci Arama';
      if(!page) {  page=1; }
      
      this.MEMBER_SEARCHSTUDENTLIST({page,searchField,searchText})
      .then((res)=>{
            this.mainList.currentPage=res.data.currentPage;
            this.mainList.items = res.data.items;
            this.mainList.pageSize=res.data.pageSize;
            this.mainList.totalItems=res.data.totalItems;
            this.mainList.totalPages=res.data.totalPages;
            this.mainList.dataLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
            this.dataLoading=false;
        });
    },
    
    StudentStatus(value){
      return GetStatusText(value);
    },
    CalculateTimeByOffset(value){
        return CalculateTime(value);
    },
    TrainingFileStatus(value){
      return GetTrainingFileStatus(value);
    },
    ScheduleDateFormat(value){
      return DateFormat(value,'dd/mm');
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
  },
   mounted: function() {
  }
};
</script>
<style scoped>
  .error-feedback { color: red;}
  .pointer:hover{cursor: pointer;}
</style>