<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Öğrenci Destek</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item active">Öğrenci Destek</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">        
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <button class="btn btn-default mr-1 float-left" :disabled="dataLoading">Açık Konular</button>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <div class="float-right">
                        <div class="input-group">
                          <select class="form-control" v-model="searchTextType" :disabled="dataLoading">
                            <option value="1" selected>Ad Soyad</option>
                            <option value="3">E-Posta</option>
                            <option value="2">Telefon</option>
                          </select>
                          <input type="text" class="form-control" v-model="searchText" :disabled="dataLoading">
                          <span class="input-group-append">
                            <button type="button" class="btn btn-default btn-flat" :disabled="dataLoading">Ara</button>
                          </span>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <span v-if="listType==null">Henüz bir liste seçmediniz! Yukarıda bulunan listeleme seçenekleri kullanabilirsiniz.</span>
                <span v-else-if="dataLoading">Yükleniyor. Lütfen bekleyiniz.</span>
                <span v-else>{{totalItems}} adet kayıt bulundu.</span>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <div class="row p-2 border-bottom" v-for="item in items" :key="item.id" >
                  <div class="col-sm-12 col-md-5">
                    <div class="btn-group float-left mr-2">
                      <button type="button" class="btn btn-default dropdown-toggle btn-sm" data-toggle="dropdown" :disabled="dataLoading">
                        <i class="fas fa-bars"></i>
                      </button>
                      <div class="dropdown-menu" role="menu">
                        <a class="dropdown-item" href="#">Cevap Yaz</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">Öğrenci Bilgileri</a>
                      </div>
                    </div>
                    <h4 class="float-left text-capitalize">Konu Başlığı</h4>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    Boş
                  </div>
                  <div class="col-sm-12 col-md-2">
                    Boş
                  </div>
                </div>
              </div>
              <!-- /.card-body -->
              <!-- /.card -->
              <div class="card-footer clearfix" v-show="totalPages>0">
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-show="(currentPage-1)>0"><button class="page-link" @click="GetMemberStudentList(currentPage-1)" :disabled="dataLoading">&laquo;</button></li>
                  <li v-for="page in totalPages" :key="page" class="page-item" :class="{'active':page==currentPage}"><button class="page-link" @click="GetMemberStudentList(page)" :disabled="dataLoading">{{page}}</button></li>
                  <li class="page-item" v-show="(currentPage+1)<=totalPages"><button class="page-link" @click="GetMemberStudentList(currentPage+1)" :disabled="dataLoading">&raquo;</button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
        
</template>

<script>
import {mapActions} from "vuex"
export default {
  name: "Ticket",
  data(){
    return{
      currentPage:0,
      items:[],
      pageSize:0,
      totalItems:0,
      totalPages:0,
      dataLoading:false,
      listType:null,
      searchTextType:1,
      searchText:null,
      modalSubmitLoading:false,
    }
  },
  computed: {
  },
  methods: {
    ...mapActions(["MEMBER_NOTELIST"]),
    
    ListButtonClick(type){
      this.listType = type;
      //this.GetMemberStudentList(1);
    },
    GetMemberStudentList(){
    },
  }
};
</script>
<style scoped>
  .error-feedback { color: red;}
  .pointer:hover{cursor: pointer;}
</style>