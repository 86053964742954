import { 
    GetUserById,
    GetPermissonsByUserId,
    SavePermissonsByUserId,
    GetTransactions
} from "../services/userprofile.service";

const userprofile_module = {
    state:{},
    getters:{},
    mutations:{},
    actions:{
        USERPROFILE_GETUSER : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetUserById(model.id)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        USERPROFILE_GETPERMISSIONS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetPermissonsByUserId(model.id)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        USERPROFILE_SAVEPERMISSIONS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                SavePermissonsByUserId(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },

        USERPROFILE_TRANSACTIONLIST : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetTransactions(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
    }
}

export default userprofile_module;