<template>
  <div class="modal fade" id="MemberTransactionModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Yapılan İşlemler</h5>
            <button type="button" class="close" @click="CloseMemberTransactionModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="overflow-auto mb-1" style="height:400px;">
                <div class="border-bottom p-1 mb-1" v-for="l in transactionList.items" :key="l">
                  <small>
                    <span class="text-bold"><em>{{DateFormat(l.date)}} | {{l.tansactionUser}}</em>&nbsp;&nbsp;<small class="badge badge-secondary">{{ l.command }}</small></span>
                    <p>{{l.description}}</p>
                  </small>
                </div>
              </div>
              <div v-show="transactionList.totalPages>0">
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-show="(transactionList.currentPage-1)>0"><button class="page-link" @click="GetTransactions(transactionList.currentPage-1)" :disabled="dataLoading">&laquo;</button></li>
                  <li v-for="page in transactionList.totalPages" :key="page" class="page-item" :class="{'active':page==transactionList.currentPage}"><button class="page-link" @click="GetTransactions(page)" :disabled="dataLoading">{{page}}</button></li>
                  <li class="page-item" v-show="(transactionList.currentPage+1)<=transactionList.totalPages"><button class="page-link" @click="GetTransactions(transactionList.currentPage+1)" :disabled="dataLoading">&raquo;</button></li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../helpers/error-message-helper";
import {DateFormat,CalculateDateTime} from "../helpers/date-convert-helper";
export default {
  name: 'MemberTransactionModal',
  components: {  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    MemberId:{
      type:String,
      default:null
    }
  },
  data() {
    return {
      page:1,
      dataLoading:false,
      transactionList:[]
    }
  },
  computed:{
    
  },
  methods:{
      ...mapActions(["MEMBER_TRANSACTIONLIST"]),
      IsShowErrorMessage(value){
        return IsShowErrorMessage(value);
      },
      DateFormat(value){
        return DateFormat(CalculateDateTime(value),'yyyy-mm-dd hh:MM:ss');
      },
      CloseMemberTransactionModal(){
        window.$("#MemberTransactionModal").modal('hide');
        this.$emit("CloseMemberTransactionModal", false);
      },
      GetTransactions(pageNumber) {
        if(this.MemberId==null)
          return;


        this.dataLoading=true;  
        let model ={
          memberId:this.MemberId,
          page:pageNumber
        };
        this.MEMBER_TRANSACTIONLIST(model)
        .then((res)=>{
            this.transactionList=res.data;
            this.dataLoading=false;
          }).catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.dataLoading=false;
          });
      },
  },
  watch: {
    Visible(value){
      if(value){
        window.$("#MemberTransactionModal").modal({backdrop: 'static',keyboard: false, show: true});
      }
    },
    MemberId(value){
      if(value==null){
        this.transactionList=[];
        this.page=1;
      }else{
        this.GetTransactions(1);
      }
    }
  },
  mounted: function() {}
}
</script>