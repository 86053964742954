<template>
  <body class="hold-transition login-page">
  <div class="login-box">
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <img src="../assets/logo-text.jpg" alt="Lengedu" style="width:250px;">
      </div>
      <div class="card-body">
        <p class="login-box-msg">Şifrenizi mi unuttunuz? Kullanıcı adınızı yazarak şifreniz e-posta adresinize gönderilecektir.</p>
        <Form @submit="handleForgotPassword" :validation-schema="schema">
          <div class="mb-3" >
            <div class="form-group mb-0">
              <Field name="username" type="text" class="form-control" />
            </div>
            <small><ErrorMessage name="username" class="error-feedback" /></small>
          </div>
          <div class="row mb-3">
            <div class="col-12 mb-2">
              <button class="btn btn-primary btn-block" :disabled="submitDisabled">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              <span class="ml-2" >Yeni Şifre Gönder</span>
            </button>
            </div>
            <div class="col-12">
              <router-link :to="{path:'login'}">Giriş</router-link>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  </body>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  name: "ForgotPassword",
  components: {
    Form,
    Field,
    ErrorMessage
   },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Kullanıcı adı giriniz!"),
    });

    return {
      loading: false,
      submitDisabled : false,
      schema
    }
  },
  computed: {
    ...mapGetters(["TOKEN"]),
    getToken(){
      return this.TOKEN;
    }
  },
  created() {
    if (this.getToken) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions(["FORGOT_PASSWORD"]),
    handleForgotPassword(form) {
      this.loading = true;
      this.submitDisabled = true;
      let username = form.username;
      this.FORGOT_PASSWORD({username})
      .then((res)=>{
          this.$toast.success(res.data.description,{
              position : 'top',
              duration:3000
          });
          this.loading = false;
      })
      .catch((error)=>{
          this.loading = false;
          this.$toast.error(error,{
            position : 'top',
            duration:3000
          });
          this.submitDisabled = false;
      });
    },
  },
};
</script>
<style scoped>
.error-feedback {
  color: red;
}
</style>