<template>
  <div class="modal fade" v-bind:id="ModalName" tabindex="2" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Hızlı Fatura Adresi Ekleme</h5>
            <button type="button" class="close" @click="CloseModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input type="text" v-model="title" class="form-control mb-2" placeholder="Başlık">
            <input type="text" v-model="taxNumber" class="form-control mb-2" placeholder="Vergi No / TC.Kimlik No" maxlength="11">
            <input type="text" v-model="taxOffice" class="form-control mb-2" placeholder="Vergi Dairesi" maxlength="100">
            <input type="text" v-model="address" class="form-control mb-2" placeholder="Adres">
            <input type="text" v-model="country" class="form-control mb-2" placeholder="Ülke">
            <input type="text" v-model="city" class="form-control mb-2" placeholder="Şehir">
            <input type="text" v-model="district" class="form-control mb-2" placeholder="İlçe">
            <input type="text" v-model="postCode" class="form-control mb-2" placeholder="Posta Kodu">

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="CreateAddress()" :disabled="submitLoading">
                <span v-show="submitLoading" class="spinner-border spinner-border-sm" ></span>
                <span class="ml-2" >Oluştur</span>
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../helpers/error-message-helper";
export default {
  name: 'FastBillingAddressModal',
  components: {  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    MemberId:{
      type:String,
      default:null
    },
    ModalName:{
      type:String,
      default:'FastBillingAddressModal'
    }
  },
  data() {
    return {
      submitLoading:false,
      title:'',
      taxNumber:'',
      taxOffice:'',
      address:'',
      country:'Türkiye',
      city:'',
      district:'',
      postCode:''
    }
  },
  computed:{
  },
  methods:{
      ...mapActions(["MEMBER_CREATEBILL"]),
      IsShowErrorMessage(value){
        return IsShowErrorMessage(value);
      },
      
      CloseModal(){
        window.$("#"+this.ModalName).modal('hide');
        this.$emit("CloseModal", false);
      },
      FormValidation(){
        if(this.title===''){
          this.$toast.error("Başlık giriniz.",{position : 'top',duration : 3000});
          return false;
        }

        if(this.taxNumber==='' || this.taxNumber.length<10){
          this.$toast.error("Vergi numarasını giriniz.[10 ya da 11 hane olmalı]",{position : 'top',duration : 3000});
          return false;
        }

        if(this.taxOffice===''){
          this.$toast.error("Vergi dairesini giriniz.",{position : 'top',duration : 3000});
          return false;
        }
        return true;
      },
      CreateAddress(){
        this.submitLoading=true;
        if(!this.FormValidation()){
          this.submitLoading=false;
          return;
        }

        let model = {
          memberId:this.MemberId,
          title: this.title,
          taxOffice: this.taxOffice,
          taxNumber: this.taxNumber,
          address: this.address,
          country: this.country,
          city: this.city,
          district: this.district,
          postCode: this.postCode,
        };
        this.MEMBER_CREATEBILL(model)
        .then(()=>{
            let message = "Fatura başarıyla kaydedildi.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
            });
            this.submitLoading=false;
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.submitLoading=false;
          });
      },
      init(){
        this.title='';
        this.taxOffice='';
        this.taxNumber='';
        this.address='';
        this.country='';
        this.city='';
        this.district='';
        this.postCode='';
      }
  },
  watch: {
    Visible(value){
      if(value){
        this.init();
        window.$("#"+this.ModalName).modal({backdrop: 'static',keyboard: false, show: true});
      }
    }
  },
  mounted: function() {
  }
  
}
</script>