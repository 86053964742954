import {service} from "../helpers/request"

function SendMail(model){
    return service({
        url:'/Notification/SendMail',
        method:'post',
        data:{
            subject:model.subject,
            content : model.content,
            to:model.to,
            memberId:model.memberId
        }
    })
}

function SendSms(model){
    return service({
        url:'/Notification/SendSms',
        method:'post',
        data:{
            content : model.content,
            phoneNumber:model.phoneNumber,
            memberId:model.memberId
        }
    })
}

function GetMailTemplatesForStudent(){
    return service({
        url:'/Notification/GetMailTemplatesForStudent',
        method:'post',
        data:{}
    })
}

function GetMailTemplatesForForm(){
    return service({
        url:'/Notification/GetMailTemplatesForForm',
        method:'post',
        data:{}
    })
}

function GetSmsTemplatesForStudent(){
    return service({
        url:'/Notification/GetSmsTemplatesForStudent',
        method:'post',
        data:{}
    })
}

function GetSmsTemplatesForForm(){
    return service({
        url:'/Notification/GetSmsTemplatesForForm',
        method:'post',
        data:{}
    })
}

export {SendMail,SendSms,GetMailTemplatesForForm,GetMailTemplatesForStudent,GetSmsTemplatesForStudent,GetSmsTemplatesForForm};
