import {store} from "../store/index";
import {monthsText} from "./const-data";

function DateFormat(value,format,useUTC=false)
{
    try {
        
        let date = new Date(value);
        let d = useUTC?date.getUTCDate():date.getDate();
        let m = (useUTC?date.getUTCMonth():date.getMonth())+1;
        let y = useUTC?date.getUTCFullYear():date.getFullYear();
        let h = useUTC?date.getUTCHours():date.getHours();
        let M = useUTC?date.getUTCMinutes():date.getMinutes();
        let s = useUTC?date.getUTCSeconds():date.getSeconds();
        
        d = d<10?'0'+d:d;
        m = m<10?'0'+m:m;
        h = h<10?'0'+h:h;
        M = M<10?'0'+M:M;
        s = s<10?'0'+s:s;

        switch (format) {
            case 'dd mm':
                return d+' '+ monthsText[parseInt(m,10)-1];
            case 'hh MM':
                return h+':'+M;
            case 'dd/mm':
                return d+'/'+m;
            case 'yyyy-mm-dd':
                return y+'-'+m+'-'+d;
            case 'yyyymmdd':
                return y+''+m+''+d;
            case 'dd.mm.yyyy':
                return d+'.'+m+'.'+y;
            case 'yyyy-mm-dd hh:MM:ss':
                return y+'-'+m+'-'+d+' '+h+':'+M+':'+s;
            case 'dd.mm.yyyy hh:MM:ss':
                return d+'.'+m+'.'+y+' '+h+':'+M+':'+s;
            default:
                return y+'-'+m+'-'+d+' '+h+':'+M+':'+s;
        }
    } catch {
        return value;
    }
}

function CalculateTime(time){
    var offsetArr = store.getters.TIMEOFFSET.split(':');
	var offsetHour = offsetArr[0];
    var offsetMinute = offsetArr[1];
    var datetime = new Date('2020-01-10 '+time+':00');
    var timeHour = datetime.getHours();
    var timeMinute = datetime.getMinutes();
    
    datetime = new Date(datetime.setHours(parseInt(timeHour)+parseInt(offsetHour)));
    datetime = new Date(datetime.setMinutes(parseInt(timeMinute)+parseInt(offsetMinute)));
    
    var realHour = datetime.getHours();
    var realMinute = datetime.getMinutes();

    realHour = realHour<10?'0'+realHour:realHour;
    realMinute = realMinute<10?'0'+realMinute:realMinute;

    return realHour +':'+realMinute;
}

function CalculateDateTime(dateTime){
    var offsetArr = store.getters.TIMEOFFSET.split(':');
	var offsetHour = offsetArr[0];
    var offsetMinute = offsetArr[1];

    var date = new Date(dateTime);
    var timeHour = date.getHours();
    var timeMinute = date.getMinutes();
    
    date = new Date(date.setHours(parseInt(timeHour)+parseInt(offsetHour)));
    date = new Date(date.setMinutes(parseInt(timeMinute)+parseInt(offsetMinute)));
    return date;
}

function AddMinute(time,duration){
    if(!time)
        return time;
        
    var timeHour = time.split(':')[0];
    var timeMinute = time.split(':')[1];
    var datetime = new Date('2020-01-10T'+timeHour+':'+timeMinute+':00+0300');
    var newDate = new Date(datetime.setMinutes(datetime.getMinutes()+duration));
    var newTimeHour = newDate.getHours();
    var newTimeMinute = newDate.getMinutes();
    newTimeHour = newTimeHour<10?'0'+newTimeHour:newTimeHour;
    newTimeMinute = newTimeMinute<10?'0'+newTimeMinute:newTimeMinute;
    return newTimeHour +':'+newTimeMinute;
  }

function DateTimeDiff(date1,date2,interval) {
    var second=1000, minute=second*60, hour=minute*60, day=hour*24, week=day*7;
    date1 = new Date(date1);
    date2 = new Date(date2);
    var timediff = date2 - date1;
    if (isNaN(timediff)) return NaN;
    switch (interval) {
        case "years": return date2.getFullYear() - date1.getFullYear();
        case "months": return (
            ( date2.getFullYear() * 12 + date2.getMonth() )
            -
            ( date1.getFullYear() * 12 + date1.getMonth() )
        );
        case "weeks"  : return Math.floor(timediff / week);
        case "days"   : return Math.floor(timediff / day); 
        case "hours"  : return Math.floor(timediff / hour); 
        case "minutes": return Math.floor(timediff / minute);
        case "seconds": return Math.floor(timediff / second);
        default: return undefined;
    }
}
export {DateFormat, CalculateTime, CalculateDateTime,AddMinute,DateTimeDiff}