const taskCategories = [
  {value:0,label:'Özel hatırlatma / görev'},
  {value:1,label:'Skype hatırlatma'},
  {value:2,label:'İlk ders memnuniyeti ve ekran tanıtımı'},
  {value:3,label:'Paket bitiminde tekrar kayıt'},
  {value:4,label:'Ders dondruma sonrası başlangıç'},
  {value:10,label:'Referans araması'},
  {value:15,label:'Paket yükseltme'},
  {value:16,label:'Paket yükseltme (5. Ders)'},
  {value:17,label:'Paket yükseltme (10. Ders)'},
  {value:20,label:'Eğitime Başlayacak Kontrol'},
  {value:21,label:'İlk Ders'},
  {value:22,label:'Memnuniyet'},
  {value:23,label:'Yorum'},
  {value:24,label:'Devamsızlık'},
  {value:25,label:'Paket Satışı (3. Ders)'},
  {value:26,label:'Paket Satışı (5. Ders)'},
  {value:30,label:'Paket Uzatma (5 Ders Kaldı)'},
  {value:31,label:'Paket Uzatma (10 Ders Kaldı)'},
  {value:32,label:'Paket Uzatma (1 Ders Kaldı)'},
  {value:33,label:'Paket Uzatma (4 Ayda 1)'},
]

const reasonForCancellations = [
  {value:"000", label:"Diğer"},
  {value:"001", label:"Şuan yoğun ileride düşünüyor."},
  {value:"002", label:"İlk görüşmeyi yaptık. Karar alırsa kendisi geri dönecek."},
  {value:"003", label:"Sürekli aranmak istemediği için iptal. Düşünürse kendisi dönüş yapacak."},
  {value:"004", label:"Ücreti pahalı buldu."},
  {value:"005", label:"Kullanılmayan numara."},
  {value:"006", label:"4-farklı gün 4-farklı saatte arandı ulaşılamadı."},
  {value:"007", label:"Maddi açıdan daha sonra müsait olacak."},
  {value:"008", label:"İngilizce eğitim düşünmüyor."},
  {value:"009", label:"Kesinlikle aranmak istemiyor. Kara liste"},
  {value:"010", label:"Kampanya döneminde aranacak."},
  {value:"011", label:"Farklı bir kurstan eğitim alıyor."},
  {value:"012", label:"Kampanya döneminde aranacak."},
  {value:"013", label:"Ben yapmadım diyor, farklı kişi."},
  {value:"014", label:"Kendini dene önerilebilir."},
]

const trainingFileStatuses = [
  {value:"1", label:"Aktif"},
  {value:"2", label:"Tamamlandı"},
  {value:"3", label:"Donduruldu"},
  {value:"4", label:"Uzun Süre Katılmadı"},
  {value:"5", label:"Eğitmen Bulunacak"}
]

const monthsText = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
export {taskCategories,monthsText,reasonForCancellations,trainingFileStatuses}