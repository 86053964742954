<template>
  <!-- Main content -->
  <div class="content">
    <!-- <div class="container-fluid">
      <small>Son Güncelleme : 22/01/2022 19:00:00</small>
      <div class="row">
        <div class="col-lg-3 col-6">
          <div class="small-box bg-success">
            <div class="inner">
              <h3>3568</h3>
              <p>Aktif Form Adedi</p>
            </div>
            <div class="icon">
              <i class="fas fa-user"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-6">
          <div class="small-box bg-danger">
            <div class="inner">
              <h3>44</h3>
              <p>İpal Form Adedi</p>
            </div>
            <div class="icon">
              <i class="fas fa-user"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-6">
          <div class="small-box bg-success">
            <div class="inner">
              <h3>53</h3>
              <p>Aktif Öğrenci Adedi</p>
            </div>
            <div class="icon">
              <i class="fas fa-graduation-cap"></i>
            </div>
          </div>
        </div>
        
        <div class="col-lg-3 col-6">
          <div class="small-box bg-danger">
            <div class="inner">
              <h3>65</h3>
              <p>Pasif Öğrenci Adedi</p>
            </div>
            <div class="icon">
              <i class="fas fa-graduation-cap"></i>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <div class="card">
            <div class="card-header">
              <h5 class="m-0">Dağıtım Kuyruğu Listesi</h5>
            </div>
            <div class="card-body p-1">
              <div v-if="loading" class="text-center"><i class="fas fa-solid fa-circle-notch fa-spin"></i> Yükleniyor.
                Lütfen bekleyiniz.</div>
              <div v-else>
                <table class="table table-sm table-borderless">
                  <tr v-for="q in queueList" :key="q" class="border-bottom">
                    <td>
                      <div>{{ q.name }} {{ q.surname }} - {{ q.userType }}</div>
                      <div><small>{{ q.distrubuteTypeText }}</small></div>
                    </td>
                    <td width="50">
                      <a href="javascript:;" class="btn btn-sm btn-danger"
                        @click="UserDoInactiveDistrubute(q.distrubuteType, q.userId)"
                        :disabled="IsDistrubuteLoading">Çıkart</a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content -->
</template>

<script>
  import {mapActions} from "vuex"
  import permissionMixins from "../../../mixins/permissons-mixin";
  export default {
    name: "AdminDashboard",
    data() {
      return {
        loading: false,
        IsDistrubuteLoading:false,
        queueList:[]
      };
    },
    computed:{},
    methods: {
      ...mapActions(["TEACHER_GETUSERINQUEUE","USER_INACTIVEDISTRUBUTEFORBASEOPERATOR","USER_INACTIVEDISTRUBUTEFORBASESALE","USER_INACTIVEDISTRUBUTEFORBASETESTSALE"]),
      GetQueueList() {
        this.loading = true;
        this.TEACHER_GETUSERINQUEUE()
        .then((res)=>{
          this.queueList = res.data;
          this.loading = false;
        })
        .catch((error)=>{
            this.loading = false;
            this.$toast.error(error,{
              position : 'top',
              duration:3000
            });
        })
      },
      UserDoInactiveDistrubute(distrubuteType,userId){
          this.IsDistrubuteLoading = true;

          if(!confirm('Kullanıcıyı dağıtım kuyruğundan çıkartmak istiyor musunuz?')){
            this.IsDistrubuteLoading = false;
            return;
          } 
          if(distrubuteType==1){
            this.USER_INACTIVEDISTRUBUTEFORBASESALE({userId})
            .then((res)=>{
              this.IsDistrubuteLoading = false;
              this.$toast.success(res.data.description,{
                  position : 'top',
                  duration:3000
              });
              this.GetQueueList();
            })
            .catch((error)=>{
                this.IsDistrubuteLoading = false;
                this.$toast.error(error,{
                  position : 'top',
                  duration:3000
                });
            });
          }
          else if(distrubuteType==2){
            this.USER_INACTIVEDISTRUBUTEFORBASETESTSALE({userId})
            .then((res)=>{
              this.IsDistrubuteLoading = false;
              this.$toast.success(res.data.description,{
                  position : 'top',
                  duration:3000
              });
              this.GetQueueList();
            })
            .catch((error)=>{
                this.IsDistrubuteLoading = false;
                this.$toast.error(error,{
                  position : 'top',
                  duration:3000
                });
            });
          }else if(distrubuteType==10){
            this.USER_INACTIVEDISTRUBUTEFORBASEOPERATOR({userId})
            .then((res)=>{
              this.IsDistrubuteLoading = false;
              this.$toast.success(res.data.description,{
                  position : 'top',
                  duration:3000
                });
              this.GetQueueList();
            })
            .catch((error)=>{
                this.IsDistrubuteLoading = false;
                this.$toast.error(error,{
                  position : 'top',
                  duration:3000
                });
            });
          }
      }
    },
    created() {
      if (this.getToken) {
        this.$router.push("/");
    }
  },
  mixins:[permissionMixins],
  mounted: function() {
    this.GetQueueList();
  }
};
</script>