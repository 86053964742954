import { SendMail,SendSms,GetMailTemplatesForForm,GetMailTemplatesForStudent,GetSmsTemplatesForForm,GetSmsTemplatesForStudent} from "../services/notification.service";

const notification_module = {
    state:{},
    getters:{},
    mutations:{},
    actions:{
        SEND_MAIL : (context,model) =>{
            return new Promise((resolve,reject)=>{
                SendMail(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        SEND_SMS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                SendSms(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        GETMAILTEMPLATES_FORSTUDENT : () =>{
            return new Promise((resolve,reject)=>{
                GetMailTemplatesForStudent()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        GETMAILTEMPLATES_FORFORM : () =>{
            return new Promise((resolve,reject)=>{
                GetMailTemplatesForForm()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        GETSMSTEMPLATES_FORSTUDENT : () =>{
            return new Promise((resolve,reject)=>{
                GetSmsTemplatesForStudent()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        GETSMSTEMPLATES_FORFORM : () =>{
            return new Promise((resolve,reject)=>{
                GetSmsTemplatesForForm()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
    }
}

export default notification_module;