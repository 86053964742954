<template>
   <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Çalışma Saatleri</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->
    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row" v-if="listLoading">
                  <div class="col-sm-12 col-md-12 col-lg-12 text-center">Lütfen bekleyin...</div>
                </div>
                <div class="row" v-else-if="timeRange.length==0">
                  <div class="col-sm-12 col-md-12 col-lg-12 text-center">Çalışma saatileri alınamadı.</div>
                </div>
                <div v-else>
                  <p>Çalışmak istediğiniz saat aralıklarını seçiniz.</p>
                  <div class="row mb-3">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="form-check">
                        <input type="checkbox" id="AllHours" class="form-check-input" ref="AllHours" @click="AllHourSelect()" >
                        <label class="form-check-label" for="AllHours">Tüm saat aralıklarını seç</label>
                      </div>
                    </div>
                  </div>
                  <div class="row border-bottom mb-3" v-for="t in timeRange" :key="t">
                    <div class="col-sm-12 col-md-1 col-lg-1 bg-gray">
                      <div class="form-check d-inline">
                        <input type="checkbox" v-bind:id="t.key" class="form-check-input" v-bind:ref="'h'+t.key" @click="HourSelect(t.key)" >
                        <label class="form-check-label text-bold" v-bind:for="t.key" v-html="t.time"></label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-11 col-lg-11">
                      <div class="row">
                        <div class="col-sm-12 col-md-2 col-lg-2" v-for="m in t.minutes" :key="m">
                          <div class="form-check">
                            <input type="checkbox" v-bind:id="m.key" :checked="m.isAvailable" :disabled="m.isLock" v-bind:ref="'m'+m.key" @click="MinuteSelect(t.key,m.key)" class="form-check-input" >
                            <label class="form-check-label" v-bind:for="m.key" v-html="m.title"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="button" class="btn btn-primary float-right" :disabled="submitLoading" @click="SaveAvailableHours()">
                    <span v-show="submitLoading" class="spinner-border spinner-border-sm" ></span>
                    <span class="md-2" >Kaydet</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import { mapActions } from 'vuex';
import {CalculateTime} from "../../../helpers/date-convert-helper";
export default {
  name: "AvailableHours",
  components: {
  },
  data(){
    return {
      submitLoading:false,
      listLoading:true,
      enableHours:['2100','0400','0500','0600','0700','0800','0900','1000','1100','1200','1300','1400','1500','1600','1700','1800','1900','2000'],
      timeRange : [],
    }
  },
  computed:{
  },
  methods:{
    ...mapActions(["TEACHER_GETAVAILABLEHOURS","TEACHER_SAVEAVAILABLEHOURS"]),
    CalculateTimeByOffset(value){
        return CalculateTime(value);
    },
    FormatTime(value){
      if(value.length!=8)
        return '';
      
      let startTime = this.CalculateTimeByOffset(value.substring(0,4).substring(0,2)+':'+value.substring(0,4).substring(2,4));
      let finishTime = this.CalculateTimeByOffset(value.substring(4,8).substring(0,2)+':'+value.substring(4,8).substring(2,4));
      return startTime + '-' + finishTime;
    },
    AllHourSelect(){
        let status = this.$refs.AllHours.checked;
        this.timeRange.forEach(h => {
          h.minutes.forEach(m=>{
            if(!m.isLock){
              m.isAvailable=status
            }
          });
        });
    },
    HourSelect(value){
      let status = this.$refs['h'+value].checked;
      let selectedHour = this.timeRange.find(x=>x.key===value);
      selectedHour.minutes.forEach(m => {
        if(!m.isLock){
          m.isAvailable=status
        }
      });
    },
    MinuteSelect(hourValue,minuteValue){
      let status = this.$refs['m'+minuteValue].checked;
      let selectedHour = this.timeRange.find(x=>x.key===hourValue);
      let selectMinute = selectedHour.minutes.find(m => m.key===minuteValue);
      selectMinute.isAvailable = status;
    },
    SaveAvailableHours(){
      let mainThis = this;
      mainThis.submitLoading=true;
      if(!confirm('Çalışma saatleri kaydedilsin mi?')){
        mainThis.submitLoading=false;
        return;
      }

      let model = [];
      mainThis.timeRange.forEach(t => {
        t.minutes.forEach(m=>{
          model.push({TimeKey:m.key,IsAvailable:m.isAvailable});
        });
      });
      mainThis.TEACHER_SAVEAVAILABLEHOURS(model)
      .then((res)=>{
        mainThis.$toast.success(res.data.description,{
              position : 'top',
              duration:3000
          });
          mainThis.submitLoading=false;
        }).catch(error=>{
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
    GetAvailableHours(){
      this.listLoading=true;
      this.TEACHER_GETAVAILABLEHOURS()
      .then((res)=>{
          let time = res.data;
          let format = time.map((x)=>({key:x.timeKey,title:this.FormatTime(x.timeKey),isAvailable:x.isAvailable,isLock:x.isLock}));
          for(var i=0;i<this.enableHours.length;i++){
            this.timeRange.push({time:this.CalculateTimeByOffset(this.enableHours[i].substring(0,2)+':'+this.enableHours[i].substring(2,4)),key:this.enableHours[i],minutes:format.filter(x=>x.key.substring(0,2)===this.enableHours[i].substring(0,2))});
          }
          this.listLoading=false;
        }).catch(error=>{
            this.listLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
        });
    },
  },
  mounted:function(){
      this.GetAvailableHours();
  }
};
</script>
