<template>
  <div style="min-height: 500px;" class="tab-pane fade p-2" :class="showTab">
    <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
    <div v-else>
      <div v-if="transactionList.length==0" class="text-center mt-5">Herhangi bir kayıt bulunamadı.</div>
      <div class="overflow-auto mb-1" style="height:460px;">
        <div class="border-bottom p-1 mb-1" v-for="l in transactionList.items" :key="l">
          <small>
            <span class="text-bold"><em>{{DateFormat(l.date)}} | {{l.tansactionUser}}</em>&nbsp;&nbsp;<small class="badge badge-secondary">{{ l.command }}</small></span>
            <p>{{l.description}}</p>
          </small>
        </div>
      </div>
      <div v-show="transactionList.totalPages>0">
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item" v-show="(transactionList.currentPage-1)>0"><button class="page-link" @click="GetInitData(transactionList.currentPage-1)" :disabled="isLoading">&laquo;</button></li>
          <li v-for="page in transactionList.totalPages" :key="page" class="page-item" :class="{'active':page==transactionList.currentPage}"><button class="page-link" @click="GetInitData(page)" :disabled="isLoading">{{page}}</button></li>
          <li class="page-item" v-show="(transactionList.currentPage+1)<=transactionList.totalPages"><button class="page-link" @click="GetInitData(transactionList.currentPage+1)" :disabled="isLoading">&raquo;</button></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
import {DateFormat,CalculateDateTime} from "../../helpers/date-convert-helper";
export default {
  name: 'TabTransaction',
  components: { 
    },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      transactionList:[],
      isLoading:false,
    }
  },
  computed:{
    
  },
  methods:{
    ...mapActions(["MEMBER_TRANSACTIONLIST"]),
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    DateFormat(value){
      return DateFormat(CalculateDateTime(value),'dd.mm.yyyy hh:MM:ss');
    },
    GetInitData(page){
      this.isLoading=true;
      this.MEMBER_TRANSACTIONLIST({memberId:this.MemberId,page:page})
        .then((res)=>{
          this.isLoading=false;
          this.transactionList = res.data;
        })
        .catch(error=>{
            this.isLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
          });
    },
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData(1);
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  }
  
}
</script>