<template>
  <footer class="main-footer">
    <!-- To the right -->
    <!-- <div class="float-right d-none d-sm-inline">
      Anything you want
    </div> -->
    <!-- Default to the left -->
    <strong>Copyright &copy; 2021-{{GetYear()}} <a href="https://lengedu.com">Lengedu</a>.</strong> All rights reserved.
  </footer>
</template>

<script>
export default {
  name: "TeacherPanelFooter",
  methods:{
    GetYear(){
        return new Date().getFullYear();
    }
  }
};
</script>