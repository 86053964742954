<template>
  <div class="modal fade" v-bind:id="ModalName" tabindex="1" role="dialog">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-html="taskModalTitle"></h5>
            <button type="button" class="close" @click="CloseTaskModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p v-html="taskModalContent"></p>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-7">
                <label>Yapılacak işlem</label>
                <select class="form-control" v-model="proccessType" :disabled="isSubmitLoading">
                  <option value="1">Görevi Kapat</option>
                  <option value="0">Görevi Ertele</option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-5" v-if="taskDateVisible">
                <label>Tarih</label>
                <v-date-picker v-model="taskDate" mode="datetime" :min-date="new Date()" is24hr>
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                      </div>
                      <input
                          :value="inputValue"
                          v-on="inputEvents"
                          :disabled="isSubmitLoading"
                          class="form-control"
                        />
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="row" v-if="taskDateVisible">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="form-check mt-2">
                  <input type="checkbox" class="form-check-input" v-bind:id="'isSetReminderForTransaction'" v-model="isSetReminder">
                  <label class="form-check-label" v-bind:for="'isSetReminderForTransaction'">Hatırlatma alarmı kur.</label>
                </div>
              </div>
            </div>
            <div class="row" v-if="taskNoteVisible">
              <div class="col-12">
                <label>Not</label>
                <input type="text" class="form-control" v-model="taskContent" maxlength="500">
              </div>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button class="btn btn-primary" @click="SaveTransaction()" :disabled="isSubmitLoading">
                <span v-show="isSubmitLoading" class="spinner-border spinner-border-sm" ></span>
                <span class="ml-2" >Kaydet</span>
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {GetTaskCategoryText} from "../helpers/enum-convert-helper.js";
import {IsShowErrorMessage} from "../helpers/error-message-helper";
import {DateFormat} from "../helpers/date-convert-helper";
export default {
  name: 'TaskTransactionModal',
  components: {  },
  props: {
    Visible:{
      type:Boolean,
      default:false
    },
    TaskId:{
      type:Number
    },
    Category:{
      type:Number,
      default : 0
    },
    Content:{
      type:String,
      default : null
    },
    Member:{
      type:String,
      default : null
    },
    ModalName:{
      type:String,
      default:'TaskTransactionModal'
    }
  },
  data() {
    return {
      isSetReminder:false,
      proccessType:1,
      taskDate:new Date(),
      taskContent:'',
      isSubmitLoading:false
    }
  },
  computed:{
    taskDateVisible:function(){
      return this.proccessType==0;
    },
    taskModalTitle:function(){
      return this.TaskCategory(this.Category);
    },
    taskModalContent:function(){
      let content=''
      if(this.Category!='')
        content += '<div>'+(this.Member!=null?this.Member +' için ':'')+ this.TaskCategory(this.Category)+'</div>';
      if(this.Content!='')
        content +='<div>'+this.Content+'</div>'

      return content;
    },
    taskNoteVisible:function(){
      return this.Member!=null;
    },
  },
  methods:{
    ...mapActions(["TASK_TRANSACTION"]),
    TaskCategory(value){
      return GetTaskCategoryText(value);
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    TaskDateFormatForModal(value){
      return DateFormat(value,'yyyy-mm-dd hh:MM:ss');
    },
    CloseTaskModal(){
        window.$("#"+this.ModalName).modal('hide');
        this.$emit("CloseTaskModal", false);
      },
    SaveTransaction(){
        this.isSubmitLoading=true;
        if(!confirm("Hatırlatma / Görev için işlem yapmak istiyor musunuz?")){
          this.isSubmitLoading=false;
          return;
        }

        this.TASK_TRANSACTION({
          id:this.TaskId,
          taskDate:this.TaskDateFormatForModal(this.taskDate).toString(),
          isComplated:this.proccessType==1,
          isSetReminder:this.isSetReminder,
          taskContent:this.taskContent
          })
        .then(()=>{
            let message = "İşlem başarıyla gerçekleşti.";
            this.$toast.success(message,{
                  position : 'top',
                  duration : 3000
            });
            this.isSubmitLoading=false;
        })
        .catch(error=>{
              if(this.IsShowErrorMessage(error)){
                this.$toast.error(error,{
                    position : 'top',
                    duration : 3000
                });
              }
              this.isSubmitLoading=false;
          });
    }
  },
  watch: {
    Visible(value){
      if(value){
        this.isSetReminder=false;
        this.proccessType=1;
        this.taskDate=new Date();
        this.taskContent='';
        window.$("#"+this.ModalName).modal({backdrop: 'static',keyboard: false, show: true});
      }
    }
  },
  mounted: function() {

  }
  
}
</script>