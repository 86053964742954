<template>
  <div style="min-height: 500px;" class="tab-pane fade p-2" :class="showTab">
    <div class="text-center mt-5" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
    <div v-else>
      <div v-if="notes.length==0" class="text-center mt-5">Herhangi bir not bulunamadı.</div>
      <div class="overflow-auto mb-1" style="height:440px;">
        <div class="border-bottom p-1 mb-1" v-for="n in notes" :key="n.id">
            
            <p v-if="n.constContent!=''">{{n.constContent}}</p>
            <p>{{n.description}}</p>
            <div class="text-bold text-right text-sm text-secondary"><em>{{n.creationUserFullName}} | {{DateFormat(CalculateDateTimeByOffset(n.creationDate))}}</em></div>
        </div>
      </div>
      <div v-show="totalPages>0">
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item" v-show="(currentPage-1)>0"><button class="page-link" @click="GetInitData(currentPage-1)" :disabled="isLoading">&laquo;</button></li>
          <li v-for="page in totalPages" :key="page" class="page-item" :class="{'active':page==currentPage}"><button class="page-link" @click="GetInitData(page)" :disabled="isLoading">{{page}}</button></li>
          <li class="page-item" v-show="(currentPage+1)<=totalPages"><button class="page-link" @click="GetInitData(currentPage+1)" :disabled="isLoading">&raquo;</button></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped>
  
</style>
<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../helpers/error-message-helper";
import {DateFormat,CalculateDateTime} from "../../helpers/date-convert-helper";
export default {
  name: 'TabNote',
  components: { 
    },
  props: {
    MemberId:{
      type:String,
      default:null
    },
    Visible:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      showTab:'',
      notes:[],
      isLoading:false,
      currentPage:0,
      pageSize:0,
      totalItems:0,
      totalPages:0,
    }
  },
  computed:{
    
  },
  methods:{
    ...mapActions(["MEMBER_NOTELIST"]),
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    DateFormat(value){
      return DateFormat(value,'dd.mm.yyyy hh:MM:ss');
    },
    GetInitData(page){
      this.isLoading=true;
      this.MEMBER_NOTELIST({memberId:this.MemberId,page:page})
        .then((res)=>{
          this.isLoading=false;
          this.currentPage=res.data.currentPage;
          this.notes = res.data.items;
          this.pageSize=res.data.pageSize;
          this.totalItems=res.data.totalItems;
          this.totalPages=res.data.totalPages;
        })
        .catch(error=>{
            this.isLoading=false;
            if(this.IsShowErrorMessage(error)){
              this.$toast.error(error,{
                  position : 'top',
                  duration : 3000
              });
            }
          });
    },
  },
  watch: {
    Visible(value){
      if(value && this.MemberId){
        this.showTab='show active';
        this.GetInitData(1);
      }else{
        this.showTab='';
      }
    }
  },
  mounted: function() {

  }
  
}
</script>