import { 
    TrainingPackageList,
    TrainingLessonTeacherTimeList,
    TrainingCreate,
    GetBookWithLevel,
    GetBookWithSubject,
    GetScheduleForPanel,
    ChangeBookInScheduleForPanel,
    GetLessonInScheduleForPanel,
    AddOrRemoveLessonInScheduleForPanel,
    ChangeTeacherTimeForPanel,
    UpdateSkypeAddressForPanel,
    UpdateRightChangeTeacherForPanel,
    UpdateRightPostponeForPanel,
    UpdateRightMissedForPanel,
    UpdateRightFreezeLessonForPanel,
    CreateDemoLessonForPanel,
    GetLessonForPanel,
    GetLessonByDate,
    SaveLessonByDate,
    FreezeTrainingFileForPanel,
    LessonSlideForPanel,
    UpdateStartDateForPanel,
    ChangeTrainingFileStatusForPanel
 } from "../services/training.service";

const training_module = {
    state:{},
    getters:{},
    mutations:{},
    actions:{
        TRAINING_PACKAGELIST : () =>{
            return new Promise((resolve,reject)=>{
                TrainingPackageList()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_TEACHERTIMELIST : () =>{
            return new Promise((resolve,reject)=>{
                TrainingLessonTeacherTimeList()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_GETBOOKWITHLEVEL : () =>{
            return new Promise((resolve,reject)=>{
                GetBookWithLevel()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_GETBOOKWITHSUBJECT : () =>{
            return new Promise((resolve,reject)=>{
                GetBookWithSubject()
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_CREATE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                TrainingCreate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_GETSCHEDULEFORPANEL : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetScheduleForPanel(model.memberId)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_CHANGEBOOKINSCHEDULEFORPANEL : (context,model) =>{
            return new Promise((resolve,reject)=>{
                ChangeBookInScheduleForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_GETLESSONINSCHEDULEFORPANEL : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetLessonInScheduleForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_ADDORREMOVELESSONINSCHEDULEFORPANEL : (context,model) =>{
            return new Promise((resolve,reject)=>{
                AddOrRemoveLessonInScheduleForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_CHANGETEACHERTIMEFORPANEL : (context,model) =>{
            return new Promise((resolve,reject)=>{
                ChangeTeacherTimeForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_UPDATESKYPEADDRESSSAVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                UpdateSkypeAddressForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },

        TRAINING_UPDATERIGHTCHANGETEACHERSAVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                UpdateRightChangeTeacherForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_UPDATERIGHTPOSTPONESAVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                UpdateRightPostponeForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_UPDATERIGHTMISSEDSAVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                UpdateRightMissedForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_UPDATERIGHTFREEZELESSONSAVE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                UpdateRightFreezeLessonForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_CREATEDEMOLESSON : (context,model) =>{
            return new Promise((resolve,reject)=>{
                CreateDemoLessonForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_GETLESSON : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetLessonForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_GETLESSONBYDATE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                GetLessonByDate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_SAVELESSONBYDATE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                SaveLessonByDate(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_FREEZETRAININGFILE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                FreezeTrainingFileForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },
        TRAINING_LESSONSLIDE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                LessonSlideForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },

        TRAINING_UPDATESTARTDATE : (context,model) =>{
            return new Promise((resolve,reject)=>{
                UpdateStartDateForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },

        TRAINING_CHANGETRAININGFILESTATUS : (context,model) =>{
            return new Promise((resolve,reject)=>{
                ChangeTrainingFileStatusForPanel(model)
                .then((res)=>{
                    resolve(res);
                })
                .catch(error=>{
                    let message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    reject(message);
                });
            })
        },

    }
}

export default training_module;