<template>
  <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Ödeme Raporu</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{path:'/'}">Anasayfa</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{path:'/panel/reports'}">Raprolar</router-link>
              </li>
              <li class="breadcrumb-item active">Ödeme Raporu</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">     
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 col-md-7 col-lg-8">
                    <div class="float-left">
                        <v-date-picker v-model="rangeDate" mode="date" is-range>
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="input-group input-group-sm">      
                                <input
                                  :value="inputValue.start"
                                  v-on="inputEvents.start"
                                  :disabled="isDataLoading"
                                  class="form-control form-control-sm" 
                                  style="width:80px"
                                />
                                <input
                                  :value="inputValue.end"
                                  v-on="inputEvents.end"
                                  :disabled="isDataLoading"
                                  class="form-control form-control-sm"
                                  style="width:80px"
                                />
                                <span class="input-group-append">
                                  <button type="button" class="btn btn-sm btn-default btn-flat" @click="GetReport()" :disabled="isDataLoading">Listele</button>
                                </span>
                            </div>    
                          </template>
                        </v-date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-3">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>{{ totalPayment }}</h3>
                    <p>Toplam</p>
                  </div>
                  <a href="javascript:;" @click="SetReportFilter(1)" class="small-box-footer" style="z-index: auto;">Listele <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>

              <div class="col-lg-3 col-3">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>{{ccValidPayment}} <span class="h5">( {{ ccValidPaymentPrice.toFixed(2) }}₺ )</span></h3>
                    <p>Kredi Kartı Başarılı</p>
                  </div>
                  <a href="javascript:;" @click="SetReportFilter(2)" class="small-box-footer" style="z-index: auto;">Listele <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>

              <div class="col-lg-3 col-3">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>{{ccInvalidPayment}}</h3>
                    <p>Kredi Kartı Başarısız</p>
                  </div>
                  <a href="javascript:;" @click="SetReportFilter(3)" class="small-box-footer" style="z-index: auto;">Listele <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>

              <div class="col-lg-3 col-3">
                <div class="small-box bg-warning">
                  <div class="inner">
                    <h3>{{moneyTransfer}}</h3>
                    <p>Havale</p>
                  </div>
                  <a href="javascript:;" @click="SetReportFilter(4)" class="small-box-footer" style="z-index: auto;">Listele <i class="fas fa-arrow-circle-right"></i></a>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body table-responsive p-0 overflow-scroll" style="height:500px;">
                <table class="table">
                  <thead>
                  <tr>
                    <th class="text-center" style="width: 200px;">Tarih</th>
                    <th style="width: 200px;" class="text-center">Ödeme Durumu</th>
                    <th style="width: 200px;">Ödeme Tipi</th>
                    <th style="width: 200px;" class="text-center">Tutar</th>
                    <th style="width: 200px;" class="text-center">Taksit Adedi</th>
                    <th>Form Bilgileri</th>
                  </tr>
                  </thead>
                  <tbody v-if="reportFilter.length>0">
                    <tr v-for="m in reportFilter" :key="m">
                      <td class="text-center">{{ ReportViewDateFormat(m.creationDate) }}</td>
                      <td class="text-center"><div class="badge" :class="{'badge-danger':!m.isSuccess,'badge-success':m.isSuccess}">{{ConvertStatus(m.isSuccess)}}</div></td>
                      <td>{{ ConvertPaymentType(m.paymentType) }}</td>
                      <td class="text-center h4">{{ m.amount.toFixed(2) }} ₺</td>
                      <td class="text-center">{{ m.paymentType==1?m.installment==1?'Tek Çekim':m.installment+' taksit':'' }}</td>
                      <td>
                        <a href="javascript:;" @click="OpenCloseMemberProfileModal(true,m.memberId)" class="h6">{{m.name }} {{m.surname }}</a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="isDataLoading">
                    <tr>
                      <td colspan="6" class="text-center">Lütfen bekleyin.</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6" class="text-center">Kayıt bulunamadı</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->

    <MemberProfileModal :Visible="memberProfileModalVisible" :MemberId="memberProfileModalMemberId" @CloseMemberProfileModal="OpenCloseMemberProfileModal($event,null)"></MemberProfileModal>
</template>

<script>
import {mapActions} from "vuex";
import {IsShowErrorMessage} from "../../../helpers/error-message-helper";
import {GetPaymentTypeText} from "../../../helpers/enum-convert-helper";
import {DateFormat,CalculateDateTime} from "../../../helpers/date-convert-helper";
import MemberProfileModal from '../../../components/MemberProfile/MemberProfileModal.vue';
export default {
  name: "PaymentReport",
  components: {
    MemberProfileModal
  },
  data(){
    return{
      rangeDate:{
        start: new Date(),
        end: new Date()
      },
      isDataLoadingForUser:false,
      isDataLoading:false,
      reportList:[],
      reportFilter:[],
      memberProfileModalVisible:false,
      memberProfileModalMemberId:null,
    }
  },
  computed:{
    ccInvalidPayment:function(){
      let findData = this.reportList.filter(x=>x.paymentType==1 && !x.isSuccess); 
      if(findData){
        return findData.length;
      }
      return 0;
    },
    ccValidPayment:function(){
      let findData = this.reportList.filter(x=>x.paymentType==1 && x.isSuccess); 
      if(findData){
        return findData.length;
      }
      return 0;
    },
    ccValidPaymentPrice:function(){
      let findData = this.reportList.filter(x=>x.paymentType==1 && x.isSuccess); 
      if(findData){
        let total=0;
        findData.forEach(p => {
          total +=p.amount;
        });
        return total;
      }
      return 0;
    },
    moneyTransfer:function(){
      let findData = this.reportList.filter(x=>x.paymentType==2); 
      if(findData){
        return findData.length;
      }
      return 0;
    },
    totalPayment:function(){
      let findData = this.reportList; 
      if(findData){
        return findData.length;
      }
      return 0;
    },
  },
  watch:{
  },
  methods: {
    ...mapActions(["PAYMENTREPORT"]),
    
    ConvertStatus(value){
      return value?'Başarılı':'Başarısız';
    },
    ConvertPaymentType(value){
      return GetPaymentTypeText(value);
    },
    CalculateDateTimeByOffset(value){
        return CalculateDateTime(value);
    },
    OpenCloseMemberProfileModal(value,memberId){
      this.memberProfileModalVisible=value;
      this.memberProfileModalMemberId=memberId;
    },
    IsShowErrorMessage(value){
      return IsShowErrorMessage(value);
    },
    ReportDateFormat(value){
      return DateFormat(value,'yyyy-mm-dd');
    },
    ReportViewDateFormat(value){
      return DateFormat(this.CalculateDateTimeByOffset(value),'dd.mm.yyyy hh:dd:ss');
    },
    SetReportFilter(value){
      let findData;
      switch (value) {
        case 1:
          findData = this.reportList; 
          break;
        case 2:
          findData = this.reportList.filter(x=>x.paymentType==1 && x.isSuccess); 
          break;
        case 3:
          findData = this.reportList.filter(x=>x.paymentType==1 && !x.isSuccess); 
          break;
        case 4:
          findData = this.reportList.filter(x=>x.paymentType==2); 
          break;
        default:
          break;
      }
      this.reportFilter = findData;
    },
    GetReport(){
      let mainThis = this;
      mainThis.isDataLoading = true;
      mainThis.reportList = [];
      mainThis.reportFilter = [];

      mainThis.PAYMENTREPORT({
        userId:mainThis.selectedUser,
        beginDate:mainThis.ReportDateFormat(mainThis.rangeDate.start),
        endDate:mainThis.ReportDateFormat(mainThis.rangeDate.end)
      })
      .then((res)=>{
          mainThis.reportList = res.data;
          mainThis.reportFilter = mainThis.reportList
          mainThis.isDataLoading = false;
        }).catch(error=>{
          mainThis.isDataLoading = false;
           if(this.IsShowErrorMessage(error)){
             this.$toast.error(error,{
                 position : 'top',
                 duration : 3000
             });
           }
        });
    },
  },
  mounted: function() {
  }
};
</script>
<style scoped>
</style>